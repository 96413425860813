var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
angular.module("ramperv3").controller("MonsterPerfilController", [
    "$rootScope",
    "$scope",
    "$filter",
    "MonsterToasterService",
    "MonsterPerfilService",
    function ($rootScope, $scope, $filter, MonsterToasterService, MonsterPerfilService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.mostrarMenu = true;
        $rootScope.loaderAtivo = false;
        $rootScope.monsterShowLoading = $rootScope.Login.email ? false : true;
        setTimeout(function () {
            $rootScope.monsterShowLoading = false;
            $rootScope.$apply();
        }, $rootScope.Login.email ? 0 : 2000);
        /**
         * Estado inicial da página $scope
         */
        $scope.loginPorSso = $rootScope.Login.sso ? false : true;
        $scope.abrirMenuSeguranca = true;
        $scope.bucket = $rootScope.bucket;
        setTimeout(function () {
            $scope.isAdmin = $rootScope.Login.tipo == "A" ? true : false;
            carregarFotoDoPerfil();
            carregarDadosDoPerfil();
        }, $rootScope.Login.email ? 0 : 1000);
        /**
         * Deve atualizar os dados do perfil do usuário
         */
        $scope.onUpdatePerfil = function () {
            var _a = $scope.perfil, nome = _a.nome, sobrenome = _a.sobrenome, empresa = _a.empresa, email = _a.email, alterarEmail = _a.alterarEmail, recebeNotificacoes = _a.recebeNotificacoes;
            var perfil = {
                nome: nome,
                sobrenome: sobrenome,
                empresa: empresa,
                email: email,
                alterarEmail: alterarEmail,
                recebeNotificacoes: recebeNotificacoes,
            };
            $rootScope.monsterShowLoading = true;
            MonsterPerfilService.updatePerfil(perfil).then(function () {
                MonsterToasterService.showSuccessToaster("", "Altera\u00E7\u00F5es salvas com sucesso!", 6000, 350);
                $rootScope.DadosCadastrais.nome = nome;
                $rootScope.DadosCadastrais.sobrenome = sobrenome;
                $rootScope.DadosCadastrais.empresa = empresa;
                $rootScope.nomeUsuarioLogado = "".concat(nome, " ").concat(sobrenome);
                $rootScope.empresaLogado = empresa;
                $rootScope.Login.nome = nome;
                $rootScope.Login.sobrenome = sobrenome;
                $rootScope.Login.time.empresa = empresa;
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Houve um erro ao salvar as altera\u00E7\u00F5es. Tente novamente.", 6000, 350);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve restar o formulário;
         */
        $scope.onLimpar = function () {
            carregarDadosDoPerfil();
        };
        /**
         * Deve abrir e fechar o menu de segurança
         */
        $scope.toggleMenuSeguranca = function () {
            $scope.abrirMenuSeguranca = !$scope.abrirMenuSeguranca;
        };
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            MonsterToasterService.removeToaster();
        });
        onPerfilFoto = function () {
            return __awaiter(this, void 0, void 0, function () {
                var elemento, arquivo, formato, tamanho, nome, data, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            elemento = document.getElementById("perfil-foto");
                            arquivo = elemento.files[0];
                            formato = arquivo.name.split(".").pop().toLowerCase();
                            tamanho = arquivo.size;
                            nome = "profile-picture";
                            if (formato != "png" && formato != "jpg" && formato != "jpeg") {
                                MonsterToasterService.showErrorToaster("", "Formato de imagem inv\u00E1lido. Somente formatos 'png', 'jpg' e 'jpeg' s\u00E3o aceitos.", 6000, 600);
                                return [2 /*return*/];
                            }
                            if (tamanho >= 1e6) {
                                MonsterToasterService.showErrorToaster("", "Tamanho de imagem excedido. A imagem deve ser inferior a 1MB.", 6000, 600);
                                return [2 /*return*/];
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 5, , 6]);
                            $rootScope.monsterShowLoading = true;
                            return [4 /*yield*/, MonsterPerfilService.salvarArquivo(nome, arquivo, formato)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, MonsterPerfilService.salvarNomeArquivo(nome, formato)];
                        case 3:
                            _a.sent();
                            $rootScope.Login.fotoPerfil = "".concat(nome, ".").concat(formato);
                            return [4 /*yield*/, MonsterPerfilService.obterFotoPerfil()];
                        case 4:
                            data = (_a.sent()).data;
                            $rootScope.fotoPerfilUrl = data;
                            $scope.foto = data;
                            MonsterToasterService.showSuccessToaster("", "Altera\u00E7\u00F5es salvas com sucesso!", 6000, 350);
                            $rootScope.monsterShowLoading = false;
                            return [3 /*break*/, 6];
                        case 5:
                            error_1 = _a.sent();
                            $rootScope.monsterShowLoading = false;
                            MonsterToasterService.showErrorToaster("", "Houve um erro ao salvar nova foto de perfil. Tente novamente.", 6000, 600);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        };
        /**
         * Deve carregar a imagem de perfil do usuário
         */
        function carregarFotoDoPerfil() {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, MonsterPerfilService.obterFotoPerfil()];
                        case 1:
                            data = (_a.sent()).data;
                            $scope.foto = data;
                            return [2 /*return*/];
                    }
                });
            });
        }
        /**
         * Deve carregar os dados do perfil do usuário
         */
        function carregarDadosDoPerfil() {
            $scope.perfil = {
                nome: $rootScope.Login.nome,
                sobrenome: $rootScope.Login.sobrenome,
                empresa: $rootScope.Login.time.empresa,
                email: $rootScope.Login.email,
                contrato: $filter("date")(new Date($rootScope.Login.dataContratacao), "dd/MM/yyyy"),
                credito: $filter("date")(new Date($rootScope.Login.dataProximaRenovacao), "dd/MM/yyyy"),
                tipo: $rootScope.Login.tipo == "V"
                    ? "Visão"
                    : $rootScope.Login.tipo == "A"
                        ? "Administrador"
                        : "Usuário",
                recebeNotificacoes: $rootScope.DadosCadastrais.recebeNotificacoes == null
                    ? true
                    : $rootScope.DadosCadastrais.recebeNotificacoes,
                alterarEmail: false,
            };
        }
    },
]);
