angular.module("ramperv3").component("sidebarChatboxAgendaInteligente", {
    templateUrl: "views/sidebarChatboxAgendaInteligente.html",
    controller: [
        "$rootScope",
        "$scope",
        function ($rootScope, $scope) {
            $ctrl = this;
            $scope.possuiAgendaContratada = false;
            $scope.possuiAgendaConfigurada = null;
            var generateUrlAgendaMessage = function () {
                if (!$scope.possuiAgendaConfigurada) {
                    return "";
                }
                return ("Este é o link da minha agenda " +
                    $scope.possuiAgendaConfigurada.full_scheduling_url);
            };
            $scope.setUrlAgenda = function () {
                var message = generateUrlAgendaMessage();
                $rootScope.inbox.messageField.setText(message);
            };
            $scope.close = function () {
                $ctrl.close();
            };
            $ctrl.$onChanges = function (changes) {
                if (changes.possuiAgendaContratada) {
                    $scope.possuiAgendaContratada =
                        changes.possuiAgendaContratada.currentValue;
                }
                if (changes.possuiAgendaConfigurada) {
                    $scope.possuiAgendaConfigurada =
                        changes.possuiAgendaConfigurada.currentValue;
                }
            };
        },
    ],
    bindings: {
        possuiAgendaConfigurada: "<",
        possuiAgendaContratada: "<",
        close: "&",
    },
});
