"use strict";
angular.module("ramperv3").service("JanelaDeLigacaoService", [
    "$rootScope",
    "$http",
    "$q",
    function ($rootScope, $http, $q) {
        this.buscarParametros = function () {
            return $q(function (resolve, reject) {
                $http({
                    method: "GET",
                    url: "/usuarios/".concat($rootScope.DadosCadastrais.objectIdLogin, "/janela-de-ligacao/parametros"),
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
        this.atualizarParametros = function (data) {
            return $q(function (resolve, reject) {
                $http({
                    method: "PUT",
                    url: "/usuarios/".concat($rootScope.DadosCadastrais.objectIdLogin, "/janela-de-ligacao/parametros"),
                    data: data,
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
        this.removerParametros = function () {
            return $q(function (resolve, reject) {
                $http({
                    method: "DELETE",
                    url: "/usuarios/".concat($rootScope.DadosCadastrais.objectIdLogin, "/janela-de-ligacao/parametros"),
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
        this.buscarOportunidades = function (tipoDaChave, abriuEmail, periodo, janelaAtencao) {
            return $q(function (resolve, reject) {
                $http({
                    method: "GET",
                    url: "/conexao/lista-de-oportunidades",
                    params: {
                        tipoDaChave: tipoDaChave,
                        abriuEmail: abriuEmail,
                        periodo: periodo,
                        janelaAtencao: janelaAtencao,
                    },
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
    },
]);
