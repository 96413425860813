// COMPONENTES EVENTS
// eClick
angular.module("ramperv3").directive("onEclick", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.on("eClick", function (e) {
                scope.$apply(function (f) {
                    scope.$eval(attrs.onEclick, { $event: e });
                });
            });
        },
    };
});
// eClick (Submit)
angular.module("ramperv3").directive("msButton", [
    "$document",
    function ($document) {
        return {
            restrict: "E",
            link: function (scope, element, attrs) {
                if (attrs.type === "submit") {
                    element.on("eClick", function () {
                        var form = element.prop("form")
                            ? $document.find("form#".concat(element.prop("form")))
                            : element.closest("form");
                        if (form.length > 0) {
                            angular.forEach(form.controller("form").$$controls, function (control) {
                                if (control.$invalid) {
                                    var fieldElement = angular.element(control.$$element);
                                    fieldElement.attr("dirty", "true");
                                    return;
                                }
                            });
                            if (form.controller("form").$valid) {
                                form.triggerHandler("submit");
                            }
                        }
                    });
                }
            },
        };
    },
]);
// eChange
angular.module("ramperv3").directive("onEchange", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.on("eChange", function (e) {
                scope.$apply(function () {
                    scope.$eval(attrs.onEchange, { $event: e });
                });
            });
        },
    };
});
// eClose
angular.module("ramperv3").directive("onEclose", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.on("eClose", function (e) {
                scope.$apply(function () {
                    scope.$eval(attrs.onEclose, { value: e });
                });
            });
        },
    };
});
// eChange (ng-model)
angular.module("ramperv3").directive("msInput", function () {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$render = function () {
                element.val(ngModelCtrl.$viewValue || "");
            };
            element.on("eInput", function () {
                scope.$apply(function () {
                    ngModelCtrl.$setViewValue(element.val());
                });
            });
        },
    };
});
angular.module("ramperv3").directive("msDatepicker", function () {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$render = function () {
                element.prop("startDate", ngModelCtrl.$viewValue || null);
            };
            element.on("eInput", function () {
                scope.$apply(function () {
                    ngModelCtrl.$setViewValue(element.prop("startDate"));
                });
            });
        },
    };
});
angular.module("ramperv3").directive("msSelect", function () {
    return {
        require: "^?ngModel",
        link: function (scope, element, attrs, ngModelCtrl) {
            if (ngModelCtrl) {
                ngModelCtrl.$render = function () {
                    element.prop("selected", ngModelCtrl.$viewValue || null);
                };
                element.on("eInput", function () {
                    scope.$apply(function () {
                        ngModelCtrl.$setViewValue(element.prop("selected"));
                    });
                });
            }
        },
    };
});
angular.module("ramperv3").directive("msCheckbox", function () {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$render = function () {
                element.prop("checked", ngModelCtrl.$viewValue || false);
            };
            element.on("eChange", function () {
                scope.$apply(function () {
                    ngModelCtrl.$setViewValue(element.prop("checked"));
                });
            });
        },
    };
});
angular.module("ramperv3").directive("msToggle", function () {
    return {
        require: "ngModel",
        link: function (scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$render = function () {
                element.prop("checked", ngModelCtrl.$viewValue || false);
            };
            element.on("eChange", function () {
                scope.$apply(function () {
                    ngModelCtrl.$setViewValue(element.prop("checked"));
                });
            });
        },
    };
});
// eInput
angular.module("ramperv3").directive("onEinput", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.on("eInput", function (e) {
                scope.$apply(function () {
                    scope.$eval(attrs.onEinput, { value: e });
                });
            });
        },
    };
});
// eFocus
angular.module("ramperv3").directive("onEfocus", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.on("eFocus", function (e) {
                scope.$apply(function () {
                    scope.$eval(attrs.onEfocus, { value: e });
                });
            });
        },
    };
});
// eBlur
angular.module("ramperv3").directive("onEblur", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            element.on("eBlur", function (e) {
                scope.$apply(function () {
                    scope.$eval(attrs.onEblur, { value: e });
                });
            });
        },
    };
});
