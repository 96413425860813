angular.module("ramperv3").service("MonsterEsqueciMinhaSenhaService", [
    "$http",
    function ($http) {
        /**
         * Deve consumir a api para realizar o envio do email de recuperação de senha
         * @param email email do usuário
         */
        function esqueciMinhaSenha(email) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": "0881d3992f",
            };
            return $http({
                method: "GET",
                url: "/login/esqueciMinhaSenha/",
                headers: headers,
                params: {
                    email: email,
                },
            }).then(function () {
                return;
            });
        }
        return { esqueciMinhaSenha: esqueciMinhaSenha };
    },
]);
