angular.module("ramperv3").controller("AtivarSenhaController", [
    "$rootScope",
    "$scope",
    "$http",
    "$location",
    function ($rootScope, $scope, $http, $location) {
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $rootScope.mostrarMenu = false;
        $rootScope.loaderAtivo = false;
        var qtdErros;
        $("#txtSenha").removeClass("inptComErro");
        $("#txtSenhaConfirmacao").removeClass("inptComErro");
        $("#dvMensagemSenha").hide();
        $("#dvBreakSenha").hide();
        $("#dvMensagemConfirmacaoSenha").hide();
        $("#dvMensagemConfirmacaoSenhaBranco").hide();
        $("#dvMensagemUsuarioInvalido").hide();
        $scope.retornarAleatorio = function () {
            var bgArray = [
                "login.png",
                "login02.png",
                "login03.png",
                "login04.png",
                "login05.png",
            ];
            var bg = bgArray[Math.floor(Math.random() * bgArray.length)];
            var path = "".concat($rootScope.bucket, "/");
            $("#bg").css("background-image", "url(" + path + bg + ")");
        };
        $scope.voltarLogin = function () {
            $location.path("/v2/login");
        };
        $scope.mostrarSenha = function (pElement) {
            var x = document.getElementById(pElement);
            if (x.type === "password") {
                x.type = "text";
            }
            else {
                x.type = "password";
            }
        };
        $scope.ativarSenha = function () {
            qtdErros = 0;
            var objectId = document.URL.split("ativarSenha/")[1];
            if (!validatePassword($scope.senhaNova)) {
                $("#txtSenha").addClass("inptComErro");
                $("#dvMensagemSenha").fadeIn();
                $("#dvBreakSenha").fadeIn();
                qtdErros++;
            }
            if ($scope.senhaConfirmacao == null ||
                $scope.senhaConfirmacao == "" ||
                !$scope.senhaConfirmacao) {
                $("#txtSenhaConfirmacao").addClass("inptComErro");
                $("#dvMensagemConfirmacaoSenhaBranco").fadeIn();
                qtdErros++;
            }
            if ($scope.senhaNova != $scope.senhaConfirmacao)
                qtdErros++;
            if (qtdErros == 0) {
                if (document.URL.split("ativarSenha/")[1] &&
                    document.URL.split("ativarSenha/")[1] != "") {
                    $http({
                        method: "POST",
                        url: "/login/atualizarLogin/novoUsuario",
                        headers: {
                            "ramp3r-auth": "ramp3r-authorization",
                            "ramp3r-l": "0881d3992f",
                        },
                        data: JSON.stringify({
                            objectIdLogin: objectId,
                            senha: $scope.senhaNova,
                        }),
                    }).then(function (rAtualizarSenha) {
                        if (rAtualizarSenha.data == "Erro") {
                            $("#dvMensagemUsuarioInvalido").fadeIn();
                        }
                        else
                            $scope.voltarLogin();
                    });
                }
                else {
                    $("#dvMensagemUsuarioInvalido").fadeIn();
                }
            }
        };
        $scope.atualizarAtivarSenha = function (pDados) {
            if (pDados == "senha") {
                $("#txtSenha").removeClass("inptComErro");
                $("#dvMensagemSenha").fadeOut();
                $("#dvBreakSenha").fadeOut();
            }
            if (pDados == "confirmacao") {
                $("#txtSenhaConfirmacao").removeClass("inptComErro");
                $("#dvMensagemConfirmacaoSenhaBranco").fadeOut();
                $("#dvMensagemConfirmacaoSenha").fadeOut();
                setTimeout(function () {
                    $scope.validarRedigitacao();
                }, 1000);
            }
        };
        $scope.validarRedigitacao = function () {
            var primeira = $scope.senhaNova;
            var segunda = $scope.senhaConfirmacao;
            if (segunda != "") {
                if (primeira != segunda) {
                    $("#dvMensagemConfirmacaoSenha").fadeIn();
                    $("#txtSenhaConfirmacao").addClass("inptComErro");
                }
            }
        };
        $scope.mostrarSenha = function (pSenha) {
            if (pSenha == "nova") {
                var x = document.getElementById("idSenhaNova");
                if (x.type === "password") {
                    x.type = "text";
                }
                else {
                    x.type = "password";
                }
            }
            if (pSenha == "confirmacao") {
                var x = document.getElementById("idSenhaConfirmacao");
                if (x.type === "password") {
                    x.type = "text";
                }
                else {
                    x.type = "password";
                }
            }
        };
    },
]);
