angular.module("ramperv3").service("MonsterCapturasService", [
    "$rootScope",
    "$http",
    function ($rootScope, $http) {
        /**
         * Deve consumir a api para realizar o login no capturas
         */
        function login() {
            return $http({ method: "GET", url: "/capturas/login" });
        }
        /**
         * Deve consumir a api para obter os créditos disponíveis
         */
        function obterCredito() {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            return $http({
                method: "GET",
                url: "/credito/consultarCreditos",
                headers: headers,
            });
        }
        /**
         * Deve consumir a api para buscar por segmentações do usuário
         * @returns Uma lista de segmentações
         */
        function listarSegmentacoes() {
            var objectId = $rootScope.DadosCadastrais.objectIdLogin;
            var tipoDaChave = $rootScope.permissoes.tipoUsuario;
            return $http({
                method: "GET",
                url: "/segmentacoes/consultarSegmentacoesUsuario?objectId=".concat(objectId, "&tipoDaChave=").concat(tipoDaChave),
            });
        }
        /**
         * Deve consumir a api para cadastrar uma nova segmentação
         * @returns Uma lista de segmentações
         */
        function criarSegmentacao(nome) {
            var objectIdLogin = $rootScope.DadosCadastrais.objectIdLogin;
            return $http({
                method: "POST",
                url: "/segmentacoes/salvarSegmentacao?objectIdLogin=".concat(objectIdLogin, "&nome=").concat(nome, "&origem=D"),
            });
        }
        /**
         * Deve consumir a api para solicitar crédito
         */
        function solicitarCredito() {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            var data = {
                usuario: "".concat($rootScope.Login.nome, " ").concat($rootScope.Login.sobrenome, " (").concat($rootScope.Login.email, ")"),
                empresa: $rootScope.empresaUsuario,
                responsavel: $rootScope.CsResponsavel,
            };
            return $http({
                method: "POST",
                url: "/capturas/solicitar-credito",
                headers: headers,
                data: data,
            });
        }
        /**
         * Deve consumir a api para solicitar crédito
         */
        function solicitarUpsell() {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            var data = {
                usuario: "".concat($rootScope.Login.nome, " ").concat($rootScope.Login.sobrenome, " (").concat($rootScope.Login.email, ")"),
                empresa: $rootScope.empresaUsuario,
                responsavel: $rootScope.CsResponsavel,
            };
            return $http({
                method: "POST",
                url: "/capturas/solicitar-upsell",
                headers: headers,
                data: data,
            });
        }
        return {
            login: login,
            obterCredito: obterCredito,
            listarSegmentacoes: listarSegmentacoes,
            criarSegmentacao: criarSegmentacao,
            solicitarCredito: solicitarCredito,
            solicitarUpsell: solicitarUpsell,
        };
    },
]);
