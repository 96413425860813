angular
    .module("ramperv3", ["ngRoute", "ngResource", "ramperv3.serviceWorker"])
    .config([
    "$routeProvider",
    "$locationProvider",
    function ($routeProvider, $locationProvider) {
        $locationProvider.hashPrefix("");
        $routeProvider.when("/inicio", {
            templateUrl: "views/inicio.html",
            controller: "InicioController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.authenticate();
                },
            },
        });
        $routeProvider.when("/analisar/time", {
            templateUrl: "views/time.html",
            controller: "TimeController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("times");
                },
            },
        });
        $routeProvider.when("/creditos-pagamentos", {
            templateUrl: "views/creditosPagamentos.html",
            controller: "CreditosPagamentosController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("creditoPagamentos");
                },
            },
        });
        $routeProvider.when("/v2/login", {
            templateUrl: "views/monsterLogin.html",
            controller: "MonsterLoginController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.authenticateLogin();
                },
            },
        });
        $routeProvider.when("/v2/dupla-autenticacao", {
            templateUrl: "views/monsterDuplaAutenticacao.html",
            controller: "MonsterDuplaAutenticacaoController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.authenticateDuplaAutenticacao();
                },
            },
        });
        $routeProvider.when("/sso", {
            templateUrl: "views/sso.html",
            controller: "SsoController",
        });
        $routeProvider.when("/v2/esqueci-minha-senha", {
            templateUrl: "views/monsterEsqueciMinhaSenha.html",
            controller: "MonsterEsqueciMinhaSenhaController",
        });
        $routeProvider.when("/v2/alterar-minha-senha/:objectIdLogin", {
            templateUrl: "views/monsterAlterarMinhaSenha.html",
            controller: "MonsterAlterarMinhaSenhaController",
        });
        $routeProvider.when("/renovacaoSenha/:objectIdLogin", {
            templateUrl: "views/renovacaoSenha.html",
            controller: "AlterarMinhaSenhaController",
        });
        $routeProvider.when("/ativarSenha", {
            templateUrl: "views/ativarSenha.html",
            controller: "AtivarSenhaController",
        });
        $routeProvider.when("/abordar/templates-de-email", {
            templateUrl: "views/templates.html",
            controller: "TemplatesController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("templates");
                },
            },
        });
        $routeProvider.when("/abordar/respostas", {
            templateUrl: "views/respostas.html",
            controller: "RespostasController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("respostas");
                },
            },
        });
        $routeProvider.when("/prospectar/lista-de-contatos", {
            templateUrl: "views/contatos.html",
            controller: "ContatosController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("contatos");
                },
            },
        });
        $routeProvider.when("/prospectar/segmentacao-de-contatos", {
            templateUrl: "views/segmentacoes.html",
            controller: "SegmentacoesController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("segmentacoes");
                },
            },
        });
        $routeProvider.when("/prospectar/descobrir-contatos", {
            templateUrl: "views/descobrirContatos.html",
            controller: "DescobrirContatosController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("descobrirContatos");
                },
            },
        });
        $routeProvider.when("/v2/perfil", {
            templateUrl: "views/monsterPerfil.html",
            controller: "MonsterPerfilController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.authenticate();
                },
            },
        });
        $routeProvider.when("/v2/seguranca/mfa", {
            templateUrl: "views/monsterMfa.html",
            controller: "MonsterMfaController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.authenticate();
                },
            },
        });
        $routeProvider.when("/v2/seguranca/redefinir-senha", {
            templateUrl: "views/monsterRedefinirSenha.html",
            controller: "MonsterRedefinirSenhaController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.authenticate();
                },
            },
        });
        $routeProvider.when("/abordar/cadencias-de-envios", {
            templateUrl: "views/cadencias.html",
            controller: "CadenciasController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("cadencias");
                },
            },
        });
        $routeProvider.when("/integracoes", {
            templateUrl: "views/integracoes.html",
            controller: "IntegracoesController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("integracoes");
                },
            },
        });
        $routeProvider.when("/novaConta", {
            templateUrl: "views/novasContas.html",
            controller: "NovasContasController",
        });
        $routeProvider.when("/conectar", {
            templateUrl: "views/conexao.html",
            controller: "ConexaoController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("ligacoes");
                },
            },
        });
        $routeProvider.when("/ativarSenha/:objectIdLogin", {
            templateUrl: "views/ativarSenha.html",
            controller: "AtivarSenhaController",
        });
        $routeProvider.when("/relatorio/analise-de-produtividade", {
            templateUrl: "views/relatorio.html",
            controller: "RelatorioController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("relatorios");
                },
            },
        });
        $routeProvider.when("/relatorio/analise-comparativa", {
            templateUrl: "views/analiseComparativa.html",
            controller: "AnaliseComparativaController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.authenticate();
                },
            },
        });
        $routeProvider.when("/chat", {
            templateUrl: "views/chat.html",
            controller: "ChatController",
            resolve: {
                auth: function (AuthService) {
                    return AuthService.canAccess("chat");
                },
            },
        });
        $routeProvider.when("/chat/:objectIdDisparoEmail", {
            templateUrl: "views/chatFromContato.html",
            controller: "ChatFromContatoController",
        });
        $routeProvider.when("/chat/:origemLink/:objectIdLink", {
            templateUrl: "views/chatFormulario.html",
            controller: "ChatFormularioController",
        });
        $routeProvider.otherwise({ redirectTo: "/inicio" });
    },
])
    .run([
    "$rootScope",
    "LoginService",
    "$location",
    "BUCKET_IMAGES_BASE_URL",
    "BUCKET_USER_PHOTOS_BASE_URL",
    "IFRAME_CAPTURAS_BASE_URL",
    "AMBIENTE",
    function ($rootScope, LoginService, $location, BUCKET_IMAGES_BASE_URL, BUCKET_USER_PHOTOS_BASE_URL, IFRAME_CAPTURAS_BASE_URL, AMBIENTE) {
        $rootScope.bucket = BUCKET_IMAGES_BASE_URL;
        $rootScope.bucketUserPhotos = BUCKET_USER_PHOTOS_BASE_URL;
        $rootScope.capturasBaseUrl = IFRAME_CAPTURAS_BASE_URL;
        $rootScope.ambiente = AMBIENTE;
        $rootScope.$on("$routeChangeSuccess", function (event, current, previous) {
            $rootScope.titulo = "Ramper";
            $rootScope.titulo_login =
                "Faça o seu login no Ramper para automatizar a prospecção";
        });
        $rootScope.$on("$routeChangeError", function (event, current, previous, rejection) {
            if (rejection === "Not Authenticated") {
                return LoginService.logout();
            }
            if (rejection === "Not Allowed") {
                $location.path("/inicio");
            }
        });
    },
])
    .factory("AuthService", [
    "$location",
    "$rootScope",
    "LoginService",
    "PermissionamentoService",
    function ($location, $rootScope, LoginService, PermissionamentoService) {
        return {
            authenticateLogin: function () {
                LoginService.isAuthenticated().then(function () {
                    $location.path("/inicio");
                });
            },
            authenticateDuplaAutenticacao: function () {
                if ($rootScope.responseLogin) {
                    return true;
                }
                $location.path("/v2/login");
            },
            authenticate: function () {
                return LoginService.isAuthenticated().then(function () {
                    var permissionsSetted = Object.entries($rootScope.permissoes || {}).length > 0;
                    if (!permissionsSetted) {
                        return PermissionamentoService.fetchPermissionamento().then(function () {
                            return true;
                        });
                    }
                    else {
                        return true;
                    }
                });
            },
            canAccess: function (property) {
                return this.authenticate().then(function (res) {
                    return PermissionamentoService.allowAccessToScreen(property);
                });
            },
        };
    },
])
    .factory("EventSourceFactory", [
    function () {
        var eventSource = new EventSource("/v2/event-source-register");
        return {
            addEventListener: function (eventName, callback) {
                eventSource.addEventListener(eventName, callback);
            },
            removeEventListener: function (eventName, callback) {
                eventSource.removeEventListener(eventName, callback);
            },
        };
    },
]);
