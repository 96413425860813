angular.module("ramperv3").controller("CreditosPagamentosController", [
    "$rootScope",
    "$scope",
    "$resource",
    "$http",
    "$locale",
    function ($rootScope, $scope, $resource, $http, $locale) {
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $locale.NUMBER_FORMATS.GROUP_SEP = ".";
        $rootScope.mostrarMenu = true;
        $scope.$watch("Login.dataProximaRenovacao", function (value) {
            if (value) {
                $scope.dataProximaRenovacao = formatarData(value);
            }
        });
        var ConsultaBackEnd = $resource("/pagamentos/consultarDetalheHistorico/:arrayIds/:tipoDaConsulta/:dataInicial/:dataFinal");
        $scope.consultarDetalheHistorico = function () {
            ConsultaBackEnd.query({
                arrayIds: "59a4326c3b976f0ef384e54b",
                tipoDaConsulta: "M",
                dataInicial: new Date("2010", "08", "31"),
                dataFinal: new Date("2017", "10", "31"),
            }, function (rConsulta) {
                $scope.showBrowserPagamentos = rConsulta;
            });
        };
        $scope.consultarFaturas = function () {
            $scope.mostrarMensagemIntegrarFinanceiro = true;
            $rootScope.loaderAtivo = true;
            $("#dvBlur").addClass("blur");
            $scope.listaFaturas = [];
            $http({
                method: "GET",
                url: "/credito/consultarFaturas",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            })
                .then(function (rConsultarFaturas) {
                $scope.mostrarMensagemIntegrarFinanceiro = false;
                $scope.listaFaturas = rConsultarFaturas.data;
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
            })
                .catch(function () {
                $scope.mostrarMensagemIntegrarFinanceiro = true;
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
            });
        };
        $scope.consultarCreditos = function () {
            $scope.creditos = [];
            $http({
                method: "GET",
                url: "/credito/consultarCreditos",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarCreditos) {
                $scope.creditos = rConsultarCreditos.data[0];
            });
        };
        $rootScope.fPercentual = function (valorTotal, valorGasto) {
            var _perc = "100%";
            if (valorGasto && valorGasto > 0)
                _perc = ((valorGasto * 100) / valorTotal).toString() + "%";
            else
                _perc = 0;
            return _perc;
        };
        $scope.consultarFaturas();
        $scope.consultarCreditos();
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
        $scope.gerarBoleto = function (dataVencimento) {
            $rootScope.loaderAtivo = true;
            $("#dvBlur").addClass("blur");
            var mes = dataVencimento.split("/")[1];
            $http({
                method: "GET",
                url: "credito/gerar-boletos/" + mes,
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            })
                .then(function (response) {
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
                window.open(response.data.message);
            })
                .catch(function () {
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
            });
        };
    },
]);
