controlarWorkflow = function (pZerar, pPasso, pAtivoInvativo) {
    if (pZerar) {
        $("#dvInternaPassoUm").hide();
        $("#dvInternaPassoDois").hide();
        $("#dvInternaPassoTres").hide();
        $("#dvInternaPassoQuatro").hide();
        $("#parteUm").removeClass("linhaWFAtiva");
        $("#passoUm").removeClass("linhaWFAtiva");
        $("#parteDois").removeClass("linhaWFAtiva");
        $("#passoDois").removeClass("linhaWFAtiva");
        $("#parteTres").removeClass("linhaWFAtiva");
        $("#passoTres").removeClass("linhaWFAtiva");
        $("#parteQuatro").removeClass("linhaWFAtiva");
        $("#parteUm").addClass("linhaWFInativa");
        $("#passoUm").addClass("linhaWFInativa");
        $("#parteDois").addClass("linhaWFInativa");
        $("#passoDois").addClass("linhaWFInativa");
        $("#parteTres").addClass("linhaWFInativa");
        $("#passoTres").addClass("linhaWFInativa");
        $("#parteQuatro").addClass("linhaWFInativa");
        $("#dvInternaPassoUm").show();
    }
    else {
        var _remover = "";
        var _adicionar = "";
        var _objectParte = "#parte" + pPasso;
        var _objectPasso = "#passo" + pPasso;
        if (pAtivoInvativo == "A") {
            _remover = "linhaWFInativa";
            _adicionar = "linhaWFAtiva";
            $("#dvInternaPasso" + pPasso).hide();
            if (pPasso == "Um")
                $("#dvInternaPassoDois").show();
            else if (pPasso == "Dois")
                $("#dvInternaPassoTres").show();
            else if (pPasso == "Tres")
                $("#dvInternaPassoQuatro").show();
        }
        else {
            if (pPasso == "Tres")
                $("#dvInternaPassoQuatro").hide();
            else if (pPasso == "Dois")
                $("#dvInternaPassoTres").hide();
            else if (pPasso == "Um")
                $("#dvInternaPassoDois").hide();
            $("#dvInternaPasso" + pPasso).show();
            _remover = "linhaWFAtiva";
            _adicionar = "linhaWFInativa";
        }
        $(_objectParte).removeClass(_remover);
        $(_objectParte).addClass(_adicionar);
        $(_objectPasso).removeClass(_remover);
        $(_objectPasso).addClass(_adicionar);
        if (pPasso == "Tres" && pAtivoInvativo == "A") {
            $("#parteQuatro").removeClass("linhaWFInativa");
            $("#parteQuatro").addClass("linhaWFAtiva");
        }
    }
};
