var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
angular.module("ramperv3").service("TemplatesService", [
    "$http",
    function ($http) {
        /**
         * realiza requisição para buscar pastas de templates
         *
         * @param {*} filters - filtros de busca
         * @returns {*} lista de pastas de template referente à busca
         */
        function consultarPastasTemplates(filters) {
            return $http({
                method: "GET",
                url: "/v2/pastas/templates",
                params: __assign({}, filters),
            })
                .then(function (response) {
                return response.data.data;
            })
                .then(function (response) {
                donosPastas.set(filters.userId, response.map(function (pasta) { return pasta._id; }));
                return response;
            })
                .then(function (pastas) {
                pastas.flatMap(function (pasta) {
                    pastasJaBuscadas.set(pasta._id, pasta);
                    var ids = pasta.objectIdTemplates.map(function (item) {
                        pastaPorTemplate.set(item.objectIdTemplate, {
                            pastaId: pasta._id,
                        });
                        return item.objectIdTemplate;
                    });
                    return ids;
                });
                return pastas;
            })
                .catch(function () {
                return [];
            });
        }
        function splitArrayInHalf(list) {
            var halfwayThrough = Math.floor(list.length / 2);
            var arrayFirstHalf = list.slice(0, halfwayThrough);
            var arraySecondHalf = list.slice(halfwayThrough, list.length);
            return [arrayFirstHalf, arraySecondHalf];
        }
        /**
         * realiza requisição para buscar templates
         *
         * @param {*} filters - filtros de busca
         * @returns {*} lista de templates referente à busca
         */
        function consultarTemplates(filters) {
            var _a;
            if (filters.templateIds && ((_a = filters.templateIds) === null || _a === void 0 ? void 0 : _a.length) > 50) {
                var _b = splitArrayInHalf(filters.templateIds), firstHalf = _b[0], secondHalf = _b[1];
                return Promise.all([
                    consultarTemplates(__assign(__assign({}, filters), { templateIds: firstHalf })),
                    consultarTemplates(__assign(__assign({}, filters), { templateIds: secondHalf })),
                ]);
            }
            return $http({
                method: "GET",
                url: "/v2/templates",
                params: __assign({}, filters),
            })
                .then(function (response) {
                return response.data.data;
            })
                .then(function (response) {
                var templateIds = response.map(function (template) {
                    templatesJaBuscados.set(template._id, template);
                    return template._id;
                });
                donosTemplates.set(filters.userId, templateIds);
                return response;
            })
                .catch(function () {
                return [];
            });
        }
        // TODO: pensar em cachear isso
        // IN-MEMORY
        var templatesJaBuscados = new Map();
        var pastasJaBuscadas = new Map();
        var donosPastas = new Map();
        var donosTemplates = new Map();
        var pastaPorTemplate = new Map();
        function getPastaByTemplateIdInMemory(templateId) {
            if (!pastaPorTemplate.has(templateId)) {
                return null;
            }
            var template = pastaPorTemplate.get(templateId);
            if (!(template.pastaId && pastasJaBuscadas.has(template.pastaId))) {
                return null;
            }
            return pastasJaBuscadas.get(template.pastaId);
        }
        function getTemplateByIdInMemory(templateId) {
            if (!templatesJaBuscados.has(templateId)) {
                return null;
            }
            return templatesJaBuscados.get(templateId);
        }
        function getTemplatesByPastaIdInMemory(pastaId) {
            if (pastasJaBuscadas.has(pastaId)) {
                var pasta = pastasJaBuscadas.get(pastaId);
                var idsTemplates = pasta.objectIdTemplates.map(function (item) { return item.objectIdTemplate; });
                var templates = idsTemplates
                    .map(function (id) { return templatesJaBuscados.get(id); })
                    .filter(function (x) { return x; });
                return templates;
            }
            return [];
        }
        function getTemplatesByUserIdInMemory(userId) {
            if (donosTemplates.has(userId)) {
                var idsTemplates = donosTemplates.get(userId);
                var templates = idsTemplates
                    .map(function (id) { return templatesJaBuscados.get(id); })
                    .filter(function (x) { return x; });
                return templates;
            }
            return [];
        }
        return {
            consultarPastasTemplates: consultarPastasTemplates,
            consultarTemplates: consultarTemplates,
            templatesJaBuscados: templatesJaBuscados,
            pastasJaBuscadas: pastasJaBuscadas,
            donosPastas: donosPastas,
            donosTemplates: donosTemplates,
            pastaPorTemplate: pastaPorTemplate,
            getPastaByTemplateIdInMemory: getPastaByTemplateIdInMemory,
            getTemplateByIdInMemory: getTemplateByIdInMemory,
            getTemplatesByPastaIdInMemory: getTemplatesByPastaIdInMemory,
            getTemplatesByUserIdInMemory: getTemplatesByUserIdInMemory,
        };
    },
]);
