angular.module("ramperv3").controller("AnaliseComparativaController", [
    "$rootScope",
    "$scope",
    "$http",
    function ($rootScope, $scope, $http) {
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $("#menuPrincipal").show();
        $rootScope.mostrarMenu = true;
        $scope.menuNavAberto = true;
        $scope.rankingMenuAberto = true;
        $scope.mostraAddCard = false;
        $scope.mostraFiltro = false;
        $scope.menuVisaoGeral = true;
        $scope.menuProspeccao = false;
        $scope.mostraFiltroUsuarios = false;
        $scope.mostraFiltroCadencias = false;
        $scope.mostraFiltroMes = false;
        $scope.mostraFiltroSegmentacao = false;
        $scope.mostraAtividadesRanking = false;
        $scope.mostrarTabela = false;
        $scope.atividadesSelecionadas = 0;
        $scope.usuariosSelecionados = 0;
        $scope.cadenciasSelecionadas = 0;
        $scope.parametrosSelecionados = 0;
        $scope.segmentacoesSelecionadas = 0;
        $scope.atividadeRanking = "";
        $scope.timeRanking = [];
        $scope.dataRanking = [];
        $scope.timeCadencia = [];
        paramOrdenado = "";
        inverteOrdenacao = 1;
        var meses = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        $scope.mesFront = meses[new Date().getMonth()];
        $scope.anoFront = new Date().getFullYear();
        $scope.diaFront = new Date().getDate();
        if ($scope.mesFront === "Janeiro")
            $scope.mesAnteriorFront = "Dezembro";
        else
            $scope.mesAnteriorFront = meses[new Date().getMonth() - 1];
        var idMesInicial = "mes" + new Date().getMonth() + $scope.anoFront;
        var mesInicial = new Date().getMonth();
        $scope.enviarEmailExportacao = false;
        $scope.baixarExportacao = false;
        $scope.linkParaDownload = "";
        $scope.processandoArquivoParaDownload = false;
        $scope.escolhaExportacao = false;
        $scope.exportarEnviarEmail = false;
        $scope.baixarExportacao = false;
        $scope.baixarExportacaoConcluida = false;
        $scope.loaderAtivo = false;
        $scope.loaderTrava = 0;
        $scope.ordenacaoEscolhida = "MembrosDoTime";
        $scope.usuariosTime = [];
        $scope.cadenciasTime = [];
        $scope.segmentacoesTime = [];
        $scope.mesesFiltro = [];
        $scope.todosParametros = [
            {
                nome: "Contatos Capturados",
                code: "COCA",
                order: 1,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Contatos Abordados",
                code: "COAB",
                order: 2,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Contatos - E-mails Abertos",
                code: "COEA",
                order: 3,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Alta Confiabilidade",
                code: "COAL",
                order: 4,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Média Confiabilidade",
                code: "COME",
                order: 5,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Baixa Confiabilidade",
                code: "COBA",
                order: 6,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Sem Confiabilidade",
                code: "COSE",
                order: 7,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Cadências Ativas",
                code: "CAAT",
                order: 8,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "E-mails Enviados",
                code: "EMEN",
                order: 9,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "E-mails Entregues",
                code: "EMET",
                order: 10,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "E-mails Bounces",
                code: "EMBO",
                order: 11,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "E-mails Abertos",
                code: "EMAB",
                order: 12,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Respostas Positivas",
                code: "REPO",
                order: 13,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Respostas Neutras",
                code: "RENU",
                order: 14,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Respostas Negativas",
                code: "RENE",
                order: 15,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Total de Respostas",
                code: "TORE",
                order: 16,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            {
                nome: "Janela de Ligação",
                code: "JAOP",
                order: 17,
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            },
            // 'Tempo Ligacao',
            // 'Conexao Conectar',
            // 'Conversao Conectar',
        ];
        var filtroUsuarios = [];
        var filtroCadencias = [];
        var filtroSegmentacoes = [];
        $scope.filtroPeriodoEscolhido = {};
        $scope.filtroComparadoA = {};
        var filtroMeses = [];
        $scope.filtroParametros = [];
        $scope.listaUsuarios = [];
        $scope.selecionadoComparadoA = "";
        $scope.selecionadoComparacao = "";
        $scope.selecionadoPeriodoNome = "";
        $scope.popupGerarCSV = false;
        $scope.popupAberto = false;
        var popupsEsteMes = document.getElementById("conjunto-esteMes");
        var popupsComparadoA = document.getElementById("conjunto-comparadoA");
        var popupsParametros = document.getElementById("conjunto-parametros");
        var popupsFiltroUsuario = document.getElementById("conjunto-filtro-usuario");
        var popupsFiltroCadencia = document.getElementById("conjunto-filtro-cadencia");
        var popupsFiltroSegmentacao = document.getElementById("conjunto-filtro-segmentacao");
        var popupsNovoParametro = document.getElementById("conjunto-novoParametro");
        var popupsBtnNovoParametro = document.getElementById("popup-novoparametro");
        $scope.mouseOut = true;
        $scope.dataFiltro = new Date();
        $scope.montaTela = function (novaTela) {
            $("#menuNavVisaoGeral").removeClass("subMenuRelatorioBold");
            $("#menuNavVisaoGeral").removeClass("liRelatorioAtivo");
            $("#menuNavAnaliseComparativa").removeClass("subMenuRelatorioBold");
            $("#menuNavAnaliseComparativa").removeClass("liRelatorioAtivo");
            $("#menuNavMetasProspeccao").removeClass("subMenuRelatorioBold");
            $("#menuNavMetasProspeccao").removeClass("liRelatorioAtivo");
            if (novaTela == "VisaoGeral") {
                $scope.menuVisaoGeral = true;
                $scope.menuProspeccao = false;
                $scope.menuAnaliseComparativa = false;
                $("#menuNavVisaoGeral").addClass("subMenuRelatorioBold");
                $("#menuNavVisaoGeral").addClass("liRelatorioAtivo");
            }
            else if (novaTela == "Metas") {
                $scope.menuVisaoGeral = false;
                $scope.menuProspeccao = true;
                $scope.menuAnaliseComparativa = false;
                $("#menuNavMetasProspeccao").addClass("subMenuRelatorioBold");
                $("#menuNavMetasProspeccao").addClass("liRelatorioAtivo");
            }
            else if (novaTela == "AnaliseComparativa") {
                $scope.menuVisaoGeral = false;
                $scope.menuProspeccao = false;
                $scope.menuAnaliseComparativa = true;
                $("#menuNavAnaliseComparativa").addClass("subMenuRelatorioBold");
                $("#menuNavAnaliseComparativa").addClass("liRelatorioAtivo");
            }
        };
        var paramsRefresh = {
            COCA: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarContatosCapturados(filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            COAB: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarContatosAbordados(filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            COEA: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarContatosEmailsAbertos(filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            COAL: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarConfiabilidade("alta", "COAL", 4, "Alta Confiabilidade", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            COME: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarConfiabilidade("media", "COME", 5, "Média Confiabilidade", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            COBA: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarConfiabilidade("baixa", "COBA", 6, "Baixa Confiabilidade", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            COSE: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarConfiabilidade("sem", "COSE", 7, "Sem Confiabilidade", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            EMEN: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarEmails("Env", "EMEN", 9, "Emails Enviados", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            EMET: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarEmails("E", "EMET", 10, "Emails Entregues", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            EMBO: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarEmails("B", "EMBO", 11, "Emails Bounce", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            EMAB: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarEmails("A", "EMAB", 12, "Emails Abertos", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            REPO: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarRespostas("P", "REPO", 13, "Respostas Positivas", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            RENU: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarRespostas("C", "RENU", 14, "Respostas Neutras", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            RENE: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarRespostas("N", "RENE", 15, "Respostas Negativas", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            TORE: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarRespostas("Total", "TORE", 16, "Total de Respostas", filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            JAOP: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarJanelaLigacao(filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            },
            CAAT: function (filtroUsuarios, filtroPeriodoEscolhido, filtroComparadoA, filtroCadencias, filtroSegmentacoes) {
                consultarCadenciasAtivas(filtroUsuarios);
            },
        };
        function refresh() {
            $scope.loaderTrava++;
            $scope.filtroParametros.map(function (param) {
                param.loader = true;
                paramsRefresh[param.code](filtroUsuarios, $scope.filtroPeriodoEscolhido, $scope.filtroComparadoA, filtroCadencias, filtroSegmentacoes);
            });
        }
        function montaComparacao(param) {
            for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                if ($scope.listaUsuarios[i].params[param].periodoInicial != 0 &&
                    $scope.listaUsuarios[i].params[param].periodoComparacao == 0)
                    $scope.listaUsuarios[i].params[param].comparacao = "positivo";
                else if ($scope.listaUsuarios[i].params[param].periodoInicial == 0 &&
                    $scope.listaUsuarios[i].params[param].periodoComparacao != 0)
                    $scope.listaUsuarios[i].params[param].comparacao = "negativo";
                else if ($scope.listaUsuarios[i].params[param].periodoInicial == 0 &&
                    $scope.listaUsuarios[i].params[param].periodoComparacao == 0)
                    $scope.listaUsuarios[i].params[param].comparacao = "neutro";
                else if ((($scope.listaUsuarios[i].params[param].periodoInicial -
                    $scope.listaUsuarios[i].params[param].periodoComparacao) /
                    $scope.listaUsuarios[i].params[param].periodoComparacao) *
                    100 >
                    0)
                    $scope.listaUsuarios[i].params[param].comparacao = "positivo";
                else if ((($scope.listaUsuarios[i].params[param].periodoInicial -
                    $scope.listaUsuarios[i].params[param].periodoComparacao) /
                    $scope.listaUsuarios[i].params[param].periodoComparacao) *
                    100 <
                    0)
                    $scope.listaUsuarios[i].params[param].comparacao = "negativo";
                else if ((($scope.listaUsuarios[i].params[param].periodoInicial -
                    $scope.listaUsuarios[i].params[param].periodoComparacao) /
                    $scope.listaUsuarios[i].params[param].periodoComparacao) *
                    100 ==
                    0)
                    $scope.listaUsuarios[i].params[param].comparacao = "neutro";
            }
        }
        function calculaPorcentagemTotal(param) {
            param.porcentagem = 0;
            if (param.totalComparacao !== 0 && param.totalAtual !== 0) {
                param.porcentagem =
                    ((param.totalAtual - param.totalComparacao) / param.totalComparacao) *
                        100;
            }
            if (param.porcentagem >= 1000) {
                param.maiorQueMil = true;
            }
            if (param.porcentagem > 0) {
                param.corPorcent = { "background-color": "#75c18d" };
            }
            else if (param.porcentagem == 0) {
                param.corPorcent = { "background-color": "#a8a8a8" };
            }
            else {
                param.corPorcent = { "background-color": "#ff5050" };
            }
            // Regras especiais para o Bounce já que ter menos bounce que o período anterior é algo bom
            if (param.code === "EMBO") {
                if (param.porcentagem > 0) {
                    param.corPorcent = { "background-color": "#ff5050" };
                }
                if (param.porcentagem < 0) {
                    param.corPorcent = { "background-color": "#75c18d" };
                }
            }
            $scope.filtroParametros.find(function (elemento) {
                if (elemento.code == param.code) {
                    elemento.totalAtual = param.totalAtual;
                    elemento.totalComparacao = param.totalComparacao;
                    elemento.porcentagem = param.porcentagem;
                    elemento.corPorcent = param.corPorcent;
                    elemento.maiorQueMil = param.maiorQueMil;
                    elemento.loader = false;
                }
            });
            $scope.ordenarTabela($scope.ordenacaoEscolhida, false);
        }
        function consultarContatosCapturados(pUsuarios, pDataPeriodo, pDataComparadoA, pCadencias, pSegmentacoes) {
            if (pDataPeriodo.dataInicial && pDataPeriodo.dataFinal) {
                $http({
                    method: "POST",
                    url: "/relatorio/consultarContatosCapturadosAnaliseComparativa",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    data: {
                        dateFilterPeriodo: pDataPeriodo,
                        dateFilterComparadoA: pDataComparadoA,
                        pObjectIdLogin: pUsuarios,
                        pObjectIdDisparos: pCadencias,
                        pObjectIdSegmentacoes: pSegmentacoes,
                        loaderTrava: $scope.loaderTrava,
                    },
                }).then(function (response) {
                    if (response.data.loaderTrava === $scope.loaderTrava) {
                        // a v3 retorna uma lista de contatos
                        var listaContatos = response.data;
                        iniciaValoresParametro("COCA", 1);
                        var paramAux = {
                            nome: "Contatos Capturados",
                            code: "COCA",
                            totalAtual: 0,
                            totalComparacao: 0,
                            porcentagem: 0,
                            corPorcent: { "background-color": "#ffffff" },
                            maiorQueMil: false,
                        };
                        if (listaContatos !== "Nenhum resultado encontrado") {
                            for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                for (var j = 0; j < listaContatos.periodoInicial.length; j++) {
                                    if (listaContatos.periodoInicial[j]._id.objectIdLogin ===
                                        $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params.COCA.periodoInicial =
                                            listaContatos.periodoInicial[j].count;
                                        paramAux.totalAtual +=
                                            listaContatos.periodoInicial[j].count;
                                        j = listaContatos.periodoInicial.length;
                                    }
                                }
                            }
                            if (listaContatos.periodoComparacao !== null) {
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    for (var j = 0; j < listaContatos.periodoComparacao.length; j++) {
                                        if (listaContatos.periodoComparacao[j]._id.objectIdLogin ===
                                            $scope.listaUsuarios[i].objectIdLogin) {
                                            $scope.listaUsuarios[i].params.COCA.periodoComparacao =
                                                listaContatos.periodoComparacao[j].count;
                                            paramAux.totalComparacao +=
                                                listaContatos.periodoComparacao[j].count;
                                            j = listaContatos.periodoComparacao.length;
                                        }
                                    }
                                }
                            }
                        }
                        calculaPorcentagemTotal(paramAux);
                        if ($scope.filtroComparadoA !== null) {
                            montaComparacao("COCA");
                        }
                    }
                });
            }
        }
        function consultarContatosAbordados(pUsuarios, pDataPeriodo, pDataComparadoA, pCadencias, pSegmentacoes) {
            $http({
                method: "POST",
                url: "/relatorio/consultarContatosAbordadosAnaliseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    dateFilterPeriodo: pDataPeriodo,
                    dateFilterComparadoA: pDataComparadoA,
                    pObjectIdLogin: pUsuarios,
                    pObjectIdDisparos: pCadencias,
                    pObjectIdSegmentacoes: pSegmentacoes,
                    loaderTrava: $scope.loaderTrava,
                },
            }).then(function (response) {
                if (response.data.loaderTrava === $scope.loaderTrava) {
                    // a v3 retorna uma lista de emails
                    var respEmails = response.data;
                    iniciaValoresParametro("COAB", 2);
                    var paramAux = {
                        nome: "Contatos Abordados",
                        code: "COAB",
                        totalAtual: 0,
                        totalComparacao: 0,
                        porcentagem: 0,
                        corPorcent: { "background-color": "#ffffff" },
                        maiorQueMil: false,
                    };
                    if (respEmails != "Nenhum resultado encontrado") {
                        if (respEmails.periodoInicial.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoInicial, "objectIdLogin");
                            for (var login in listaLogins) {
                                var contatosDoLogin = agrupaArrayPorChave(listaLogins[login], "objectIdContato");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params["COAB"].periodoInicial =
                                            Object.keys(contatosDoLogin).length;
                                        paramAux.totalAtual += Object.keys(contatosDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                        if (respEmails.periodoComparacao !== null &&
                            respEmails.periodoComparacao.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoComparacao, "objectIdLogin");
                            for (var login in listaLogins) {
                                var contatosDoLogin = agrupaArrayPorChave(listaLogins[login], "objectIdContato");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params["COAB"].periodoComparacao =
                                            Object.keys(contatosDoLogin).length;
                                        paramAux.totalComparacao +=
                                            Object.keys(contatosDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                    }
                    calculaPorcentagemTotal(paramAux);
                    if ($scope.filtroComparadoA !== null) {
                        montaComparacao("COAB");
                    }
                }
            });
        }
        function consultarContatosEmailsAbertos(pUsuarios, pDataPeriodo, pDataComparadoA, pCadencias, pSegmentacoes) {
            $http({
                method: "POST",
                url: "/relatorio/consultarContatosEmailsAbertosAnaliseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    dateFilterPeriodo: pDataPeriodo,
                    dateFilterComparadoA: pDataComparadoA,
                    pObjectIdLogin: pUsuarios,
                    pObjectIdDisparos: pCadencias,
                    pObjectIdSegmentacoes: pSegmentacoes,
                    loaderTrava: $scope.loaderTrava,
                },
            }).then(function (response) {
                if (response.data.loaderTrava === $scope.loaderTrava) {
                    // a v3 retorna uma lista de emails disparados
                    var respEmails = response.data;
                    iniciaValoresParametro("COEA", 3);
                    var paramAux = {
                        nome: "Contatos - E-mails Abertos",
                        code: "COEA",
                        totalAtual: 0,
                        totalComparacao: 0,
                        porcentagem: 0,
                        corPorcent: { "background-color": "#ffffff" },
                        maiorQueMil: false,
                    };
                    if (respEmails != "Nenhum resultado encontrado") {
                        if (respEmails.periodoInicial.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoInicial, "objectIdLogin");
                            for (var login in listaLogins) {
                                var contatosDoLogin = agrupaArrayPorChave(listaLogins[login], "objectIdContato");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params["COEA"].periodoInicial =
                                            Object.keys(contatosDoLogin).length;
                                        paramAux.totalAtual += Object.keys(contatosDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                        if (respEmails.periodoComparacao !== null &&
                            respEmails.periodoComparacao.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoComparacao, "objectIdLogin");
                            for (var login in listaLogins) {
                                var contatosDoLogin = agrupaArrayPorChave(listaLogins[login], "objectIdContato");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params["COEA"].periodoComparacao =
                                            Object.keys(contatosDoLogin).length;
                                        paramAux.totalComparacao +=
                                            Object.keys(contatosDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                    }
                    calculaPorcentagemTotal(paramAux);
                    if ($scope.filtroComparadoA !== null) {
                        montaComparacao("COEA");
                    }
                }
            });
        }
        function consultarConfiabilidade(grauConf, codeGrauConf, ordemParam, nome, pUsuarios, pDataPeriodo, pDataComparadoA, pCadencias, pSegmentacoes) {
            $http({
                method: "POST",
                url: "/relatorio/consultarConfiabilidadeAnaliseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    pGrauConfiabilidade: grauConf,
                    dateFilterPeriodo: pDataPeriodo,
                    dateFilterComparadoA: pDataComparadoA,
                    pObjectIdLogin: pUsuarios,
                    pObjectIdDisparos: pCadencias,
                    pObjectIdSegmentacoes: pSegmentacoes,
                    loaderTrava: $scope.loaderTrava,
                },
            }).then(function (response) {
                if (response.data.loaderTrava === $scope.loaderTrava) {
                    // a v3 retorna uma lista de contatos
                    var listaContatos = response.data;
                    iniciaValoresParametro(codeGrauConf, ordemParam);
                    var paramAux = {
                        nome: nome,
                        code: codeGrauConf,
                        totalAtual: 0,
                        totalComparacao: 0,
                        porcentagem: 0,
                        corPorcent: { "background-color": "#ffffff" },
                        maiorQueMil: false,
                    };
                    if (listaContatos !== "Nenhum resultado encontrado") {
                        for (var i = 0; i < listaContatos.periodoInicial.length; i++) {
                            for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                                if (listaContatos.periodoInicial[i].objectIdLogin ==
                                    $scope.listaUsuarios[j].objectIdLogin) {
                                    $scope.listaUsuarios[j].params[codeGrauConf].periodoInicial =
                                        $scope.listaUsuarios[j].params[codeGrauConf]
                                            .periodoInicial + 1;
                                    paramAux.totalAtual += 1;
                                    j = $scope.listaUsuarios.length;
                                }
                            }
                        }
                        if (listaContatos.periodoComparacao !== null) {
                            for (var i = 0; i < listaContatos.periodoComparacao.length; i++) {
                                for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                                    if (listaContatos.periodoComparacao[i].objectIdLogin ==
                                        $scope.listaUsuarios[j].objectIdLogin) {
                                        $scope.listaUsuarios[j].params[codeGrauConf].periodoComparacao =
                                            $scope.listaUsuarios[j].params[codeGrauConf]
                                                .periodoComparacao + 1;
                                        paramAux.totalComparacao += 1;
                                        j = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                    }
                    calculaPorcentagemTotal(paramAux);
                    if ($scope.filtroComparadoA !== null) {
                        montaComparacao(codeGrauConf);
                    }
                }
            });
        }
        function consultarRespostas(siglaResp, codeResp, ordemParam, nome, pUsuarios, pDataPeriodo, pDataComparadoA, pCadencias, pSegmentacoes) {
            $http({
                method: "POST",
                url: "/relatorio/consultarRespostasAnaliseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    pStatus: siglaResp,
                    dateFilterPeriodo: pDataPeriodo,
                    dateFilterComparadoA: pDataComparadoA,
                    pObjectIdLogin: pUsuarios,
                    pObjectIdDisparos: pCadencias,
                    pObjectIdSegmentacoes: pSegmentacoes,
                    loaderTrava: $scope.loaderTrava,
                },
            }).then(function (response) {
                if (response.data.loaderTrava === $scope.loaderTrava) {
                    // a v3 retorna uma lista de emails
                    var respEmails = response.data;
                    iniciaValoresParametro(codeResp, ordemParam);
                    var paramAux = {
                        nome: nome,
                        code: codeResp,
                        totalAtual: 0,
                        totalComparacao: 0,
                        porcentagem: 0,
                        corPorcent: { "background-color": "#ffffff" },
                        maiorQueMil: false,
                    };
                    if (respEmails != "Nenhum resultado encontrado") {
                        if (respEmails.periodoInicial.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoInicial, "objectIdLogin");
                            for (var login in listaLogins) {
                                var emailsDoLogin = agrupaArrayPorChave(listaLogins[login], "_id");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params[codeResp].periodoInicial =
                                            Object.keys(emailsDoLogin).length;
                                        paramAux.totalAtual += Object.keys(emailsDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                        if (respEmails.periodoComparacao !== null &&
                            respEmails.periodoComparacao.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoComparacao, "objectIdLogin");
                            for (var login in listaLogins) {
                                var emailsDoLogin = agrupaArrayPorChave(listaLogins[login], "_id");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params[codeResp].periodoComparacao =
                                            Object.keys(emailsDoLogin).length;
                                        paramAux.totalComparacao +=
                                            Object.keys(emailsDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                    }
                    calculaPorcentagemTotal(paramAux);
                    if ($scope.filtroComparadoA !== null) {
                        montaComparacao(codeResp);
                    }
                }
            });
        }
        function consultarCadenciasAtivas(pUsuarios) {
            var paramAux = {
                nome: "Cadências Ativas",
                code: "CAAT",
                totalAtual: 0,
                totalComparacao: 0,
                porcentagem: 0,
                corPorcent: { "background-color": "#ffffff" },
                maiorQueMil: false,
            };
            $http({
                method: "POST",
                url: "/relatorio/consultarCadenciasAtivasAnaliseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    pObjectIdLogin: pUsuarios,
                    loaderTrava: $scope.loaderTrava,
                },
            }).then(function (response) {
                if (response.data.loaderTrava === $scope.loaderTrava) {
                    // a v3 retorna uma lista de disparos (cadencias)
                    var respDisparos = response.data;
                    iniciaValoresParametro("CAAT", 8);
                    if (respDisparos != "Nenhum resultado encontrado" &&
                        respDisparos.periodoInicial.length > 0) {
                        var listaLogins = agrupaArrayPorChave(respDisparos.periodoInicial, "objectIdLogin");
                        for (var login in listaLogins) {
                            for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                    $scope.listaUsuarios[i].params["CAAT"].periodoInicial =
                                        listaLogins[login].length;
                                    paramAux.totalAtual += listaLogins[login].length;
                                    i = $scope.listaUsuarios.length;
                                }
                            }
                        }
                    }
                    calculaPorcentagemTotal(paramAux);
                    if ($scope.filtroComparadoA !== null) {
                        montaComparacao("CAAT");
                    }
                }
            });
        }
        function consultarJanelaLigacao(pUsuarios, pDataPeriodo, pDataComparadoA, pCadencias, pSegmentacoes) {
            $http({
                method: "POST",
                url: "/relatorio/consultarJanelasLigacaoAnaliseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    dateFilterPeriodo: pDataPeriodo,
                    dateFilterComparadoA: pDataComparadoA,
                    pObjectIdLogin: pUsuarios,
                    pObjectIdDisparos: pCadencias,
                    pObjectIdSegmentacoes: pSegmentacoes,
                    loaderTrava: $scope.loaderTrava,
                },
            }).then(function (response) {
                if (response.data.loaderTrava === $scope.loaderTrava) {
                    // a v3 retorna uma lista de contatos
                    var respContatos = response.data;
                    iniciaValoresParametro("JAOP", 17);
                    var paramAux = {
                        nome: "Janela de Ligação",
                        code: "JAOP",
                        totalAtual: 0,
                        totalComparacao: 0,
                        porcentagem: 0,
                        corPorcent: { "background-color": "#ffffff" },
                        maiorQueMil: false,
                    };
                    if (respContatos != "Nenhum resultado encontrado") {
                        if (respContatos.periodoInicial.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respContatos.periodoInicial, "objectIdLogin");
                            for (var login in listaLogins) {
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if ($scope.listaUsuarios[i].objectIdLogin == login) {
                                        $scope.listaUsuarios[i].params["JAOP"].periodoInicial =
                                            listaLogins[login].length;
                                        paramAux.totalAtual += listaLogins[login].length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                        if (respContatos.periodoComparacao !== null &&
                            respContatos.periodoComparacao.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respContatos.periodoComparacao, "objectIdLogin");
                            for (var login in listaLogins) {
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if ($scope.listaUsuarios[i].objectIdLogin == login) {
                                        $scope.listaUsuarios[i].params["JAOP"].periodoComparacao =
                                            listaLogins[login].length;
                                        paramAux.totalComparacao += listaLogins[login].length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                    }
                    calculaPorcentagemTotal(paramAux);
                    if ($scope.filtroComparadoA !== null) {
                        montaComparacao("JAOP");
                    }
                }
            });
        }
        function consultarEmails(siglaEmail, codeEmail, ordemParam, nome, pUsuarios, pDataPeriodo, pDataComparadoA, pCadencias, pSegmentacoes) {
            $http({
                method: "POST",
                url: "/relatorio/consultarEnviosEmailsAnaliseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    pStatus: siglaEmail,
                    dateFilterPeriodo: pDataPeriodo,
                    dateFilterComparadoA: pDataComparadoA,
                    pObjectIdLogin: pUsuarios,
                    pObjectIdDisparos: pCadencias,
                    pObjectIdSegmentacoes: pSegmentacoes,
                    loaderTrava: $scope.loaderTrava,
                },
            }).then(function (response) {
                if (response.data.loaderTrava === $scope.loaderTrava) {
                    // a v3 retorna uma lista de emails
                    var respEmails = response.data;
                    iniciaValoresParametro(codeEmail, ordemParam);
                    var paramAux = {
                        nome: nome,
                        code: codeEmail,
                        totalAtual: 0,
                        totalComparacao: 0,
                        porcentagem: 0,
                        corPorcent: { "background-color": "#ffffff" },
                        maiorQueMil: false,
                    };
                    if (respEmails != "Nenhum resultado encontrado") {
                        if (respEmails.periodoInicial.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoInicial, "objectIdLogin");
                            for (var login in listaLogins) {
                                var emailsDoLogin = agrupaArrayPorChave(listaLogins[login], "_id");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params[codeEmail].periodoInicial =
                                            Object.keys(emailsDoLogin).length;
                                        paramAux.totalAtual += Object.keys(emailsDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                        if (respEmails.periodoComparacao !== null &&
                            respEmails.periodoComparacao.length > 0) {
                            var listaLogins = agrupaArrayPorChave(respEmails.periodoComparacao, "objectIdLogin");
                            for (var login in listaLogins) {
                                var emailsDoLogin = agrupaArrayPorChave(listaLogins[login], "_id");
                                for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                                    if (login == $scope.listaUsuarios[i].objectIdLogin) {
                                        $scope.listaUsuarios[i].params[codeEmail].periodoComparacao = Object.keys(emailsDoLogin).length;
                                        paramAux.totalComparacao +=
                                            Object.keys(emailsDoLogin).length;
                                        i = $scope.listaUsuarios.length;
                                    }
                                }
                            }
                        }
                    }
                    calculaPorcentagemTotal(paramAux);
                    if ($scope.filtroComparadoA !== null) {
                        montaComparacao(codeEmail);
                    }
                }
            });
        }
        $scope.togglePopUp = function (popup) {
            if (popup == "todos") {
                $scope.mostraFiltro = false;
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraEsteMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "filtro") {
                $scope.mostraFiltro = !$scope.mostraFiltro;
                if ($scope.mostraFiltro == false) {
                    $scope.mostraFiltroUsuarios = false;
                    $scope.mostraFiltroCadencias = false;
                    $scope.mostraFiltroMes = false;
                    $scope.mostraFiltroSegmentacao = false;
                }
                $scope.mostraEsteMes = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "usuarios") {
                $scope.mostraFiltroUsuarios = !$scope.mostraFiltroUsuarios;
                $scope.ajustarPopups("#filtro-usuario", 0, 0);
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraEsteMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "cadencias") {
                $scope.mostraFiltroCadencias = !$scope.mostraFiltroCadencias;
                $scope.ajustarPopups("#filtro-cadencia", 0, 0);
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraEsteMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "segmentacao") {
                $scope.mostraFiltroSegmentacao = !$scope.mostraFiltroSegmentacao;
                $scope.ajustarPopups("#filtro-segmentacao", 0, 0);
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraEsteMes = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "mes") {
                $scope.mostraFiltroMes = !$scope.mostraFiltroMes;
                $scope.ajustarPopups("#filtro-mes", 0, 0);
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraEsteMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "esteMes") {
                $scope.mostraEsteMes = !$scope.mostraEsteMes;
                $scope.mostraFiltro = false;
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "comparadoA") {
                $scope.mostraComparadoA = !$scope.mostraComparadoA;
                $scope.mostraFiltro = false;
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraEsteMes = false;
                $scope.mostraParametros = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "parametros") {
                $scope.mostraParametros = !$scope.mostraParametros;
                $scope.mostraFiltro = false;
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraEsteMes = false;
                $scope.mostraComparadoA = false;
                $scope.mostraNovoParametro = false;
            }
            else if (popup == "novoParametro") {
                $scope.mostraNovoParametro = !$scope.mostraNovoParametro;
                $scope.mostraFiltro = false;
                $scope.mostraFiltroUsuarios = false;
                $scope.mostraFiltroCadencias = false;
                $scope.mostraFiltroMes = false;
                $scope.mostraFiltroSegmentacao = false;
                $scope.mostraEsteMes = false;
                $scope.mostraComparadoA = false;
                $scope.mostraParametros = false;
            }
        };
        $scope.buscarInfoFiltro = function (idBusca, idFiltro) {
            var input, filter, div, td, tr, i;
            input = document.getElementById(idBusca);
            filter = input.value.toUpperCase();
            div = document.getElementById(idFiltro);
            tr = div.getElementsByTagName("tr");
            td = div.getElementsByTagName("td");
            for (var i = 0; i < td.length; i++) {
                var txtValue = td[i].textContent || td[i].innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    td[i].style.display = "";
                    tr[i].style.display = "";
                }
                else {
                    td[i].style.display = "none";
                    tr[i].style.display = "none";
                }
            }
        };
        $(document).mousedown(function (event) {
            var clickForaEsteMes = popupsEsteMes.contains(event.target);
            var clickForaComparadoA = popupsComparadoA.contains(event.target);
            var clickForaParametros = popupsParametros.contains(event.target);
            var clickForaFiltroUsuario = popupsFiltroUsuario.contains(event.target);
            var clickForaFiltroCadencia = popupsFiltroCadencia.contains(event.target);
            var clickForaFiltroSegmentacao = popupsFiltroSegmentacao.contains(event.target);
            var clickForaNovoParametro = popupsNovoParametro.contains(event.target);
            var clickForaBtnNovoParametro = popupsBtnNovoParametro.contains(event.target);
            if (!clickForaEsteMes &&
                !clickForaComparadoA &&
                !clickForaParametros &&
                !clickForaFiltroUsuario &&
                !clickForaFiltroCadencia &&
                !clickForaFiltroSegmentacao &&
                !clickForaNovoParametro &&
                !clickForaBtnNovoParametro) {
                $scope.togglePopUp("todos");
                $scope.$apply();
            }
        });
        $scope.colapseOrExpandMenu = function (menu) {
            if (menu == "analise") {
                $scope.menuNavAberto = !$scope.menuNavAberto;
                if ($scope.menuNavAberto) {
                    $("#expandOrCollapseSideMenu").addClass("collapseMenuNav");
                    $("#menuNav").removeClass("menuNavCol");
                    $("#expandOrCollapseSideMenu").removeClass("expandMenuNav");
                    setTimeout(function () {
                        $("#tituloMenuLateral").removeClass("escondeMenuLateral");
                        $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#expandOrCollapseSideMenu").removeClass("collapseMenuNav");
                    $("#menuNav").addClass("menuNavCol");
                    $("#tituloMenuLateral").addClass("escondeMenuLateral");
                    $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideMenu").addClass("expandMenuNav");
                }
            }
        };
        $scope.checkOption = function (checked) {
            var obj;
            obj = document.getElementById(checked);
            if (!$(obj).hasClass("option-checked")) {
                $(obj).addClass("option-checked");
            }
            else {
                $(obj).removeClass("option-checked");
            }
        };
        function animateValue(id, start, end, duration) {
            var range = start - end;
            var current = 0;
            var increment = end > 100 ? 1 : 1;
            var stepTime = Math.abs(Math.floor(duration / range));
            var obj = document.getElementById(id);
            var timer = setInterval(function () {
                current += increment;
                obj.innerHTML = current;
                if (current >= end) {
                    obj.innerHTML = end;
                    clearInterval(timer);
                }
            }, stepTime);
        }
        function checkarPosicao(elem, offsetX, offsetY) {
            var sides = {
                top: false,
                right: false,
                bottom: false,
                left: false,
                any: false,
                all: false,
            };
            sides.top =
                elem.top >
                    (window.innerHeight || document.documentElement.clientHeight);
            sides.left =
                elem.left + offsetX >
                    (window.innerWidth || document.documentElement.clientWidth);
            sides.bottom =
                elem.bottom + offsetY >
                    (window.innerHeight || document.documentElement.clientHeight);
            sides.right =
                elem.right >
                    (window.innerWidth || document.documentElement.clientWidth);
            sides.any = sides.top || sides.left || sides.bottom || sides.right;
            sides.all = sides.top && sides.left && sides.bottom && sides.right;
            return sides;
        }
        $scope.ajustarPopups = function (popup, offsetX, offsetY) {
            var obj = document.querySelector(popup);
            $(obj).removeClass("reposicionar-filtro-analise");
            // $(obj).removeClass('reposicionarCard');
            setTimeout(function () {
                var boundingLista;
                boundingLista = obj.getBoundingClientRect();
                var sides = {
                    top: false,
                    right: false,
                    bottom: false,
                    left: false,
                    any: false,
                    all: false,
                };
                sides = checkarPosicao(boundingLista, offsetX, offsetY);
                if (($scope.mostraFiltroUsuarios ||
                    $scope.mostraFiltroCadencias ||
                    $scope.mostraFiltroMes ||
                    $scope.mostraFiltroSegmentacao) &&
                    sides.any) {
                    $(obj).addClass("reposicionar-filtro-analise");
                }
            }, 100);
        };
        function agrupaArrayPorChave(array, chave) {
            if (array.length > 0) {
                return array.reduce(function (resultado, valorAtual) {
                    (resultado[valorAtual[chave]] =
                        resultado[valorAtual[chave]] || []).push(valorAtual);
                    return resultado;
                }, {});
            }
        }
        //#region MONTAGEM E SELEÇÃO DOS FILTROS
        function montarFiltroMeses() {
            var anoAtual = new Date().getFullYear();
            var mesAtual = new Date().getMonth();
            for (var j = anoAtual; j >= 2018; j--) {
                if (j == anoAtual) {
                    for (var i = mesAtual; i >= 0; i--) {
                        $scope.mesesFiltro.push({
                            id: "mes" + i + j,
                            data: meses[i] + " de " + j,
                            mes: i,
                            ano: j,
                        });
                    }
                }
                else {
                    for (i = 11; i >= 0; i--) {
                        $scope.mesesFiltro.push({
                            id: "mes" + i + j,
                            data: meses[i] + " de " + j,
                            mes: i,
                            ano: j,
                        });
                    }
                }
            }
            var mesCont = $scope.mesesFiltro[0].mes + 1;
            var mesAux = "" + mesCont;
            if (mesCont < 10)
                mesAux = "0" + mesCont;
            $scope.dataRanking =
                "'" + $scope.mesesFiltro[0].ano + "-" + mesAux + "-" + "01'";
        }
        $scope.selecionarMes = function (ano, mes, id, idFiltro) {
            var div, obj, tr, imgs, img;
            div = document.getElementById(idFiltro);
            tr = div.getElementsByTagName("tr");
            imgs = div.getElementsByTagName("img");
            $(tr).removeClass("option-checked");
            $(imgs).addClass("invisible");
            obj = document.getElementById(id);
            img = obj.getElementsByTagName("img");
            var mesFormatado = "01";
            if (mes + 1 < 10)
                mesFormatado = "0" + (mes + 1);
            else
                mesFormatado = mes + 1;
            if (!$(obj).hasClass("option-checked")) {
                $(obj).addClass("option-checked");
                $(img).removeClass("invisible");
                filtroMeses.splice(0, 1);
                filtroMeses.push({
                    _id: id,
                    ano: ano,
                    mes: mes,
                    data: "'" + ano + "-" + mesFormatado + "-" + "01'",
                });
            }
            $scope.anoFront = filtroMeses[0].ano;
            $scope.mesFront = meses[filtroMeses[0].mes];
            if ($scope.mesFront === "Janeiro")
                $scope.mesAnteriorFront = "Dezembro";
            else
                $scope.mesAnteriorFront = meses[filtroMeses[0].mes - 1];
        };
        function mesPorExtenso(mes) {
            switch (mes) {
                case 1:
                    return " jan, ";
                case 2:
                    return " fev, ";
                case 3:
                    return " mar, ";
                case 4:
                    return " abr, ";
                case 5:
                    return " mai, ";
                case 6:
                    return " jun, ";
                case 7:
                    return " jul, ";
                case 8:
                    return " ago, ";
                case 9:
                    return " set, ";
                case 10:
                    return " out, ";
                case 11:
                    return " nov, ";
                case 12:
                    return " dez, ";
            }
        }
        $scope.selecionarPeriodoInicial = function (periodo) {
            var obj, img, objEsteMes;
            obj = document.getElementById(periodo);
            img = obj.getElementsByTagName("img");
            if (!$(obj).hasClass("option-checked")) {
                $(obj).addClass("option-checked");
                $(img).removeClass("invisible");
                var objAux, imgAux;
                if ($scope.selecionadoComparacao !== "") {
                    objAux = document.getElementById($scope.selecionadoComparacao);
                    imgAux = objAux.getElementsByTagName("img");
                    $(objAux).removeClass("option-checked");
                    $(imgAux).addClass("invisible");
                }
                if ($scope.selecionadoComparadoA !== "") {
                    var objComparadoA, imgComparadoA;
                    objComparadoA = document.getElementById($scope.selecionadoComparadoA);
                    imgComparadoA = objComparadoA.getElementsByTagName("img");
                    $(objComparadoA).removeClass("option-checked");
                    $(imgComparadoA).addClass("invisible");
                    $scope.selecionadoComparadoA = "";
                    $scope.filtroComparadoA = null;
                }
                $scope.selecionadoComparacao = periodo;
                $scope.mostraComparadoAHoje = false;
                $scope.mostraComparadoAEstaSemana = false;
                $scope.mostraComparadoAEsteMes = false;
                $scope.mostraComparadoAEsteTrimestre = false;
                $scope.mostraComparadoAEsteAno = false;
                $scope.mostraComparadoAOntem = false;
                $scope.mostraComparadoASemanaAnterior = false;
                $scope.mostraComparadoAMesAnterior = false;
                $scope.mostraComparadoATrimestreAnterior = false;
                $scope.mostraComparadoAAnoAnterior = false;
                $scope.naoMostraComparadoA = false;
                objEsteMes = document.getElementById("conjunto-esteMes");
                $(objEsteMes).removeClass("conjunto-esteMes-semComparacao");
                var mes = new Date().getMonth() + 1;
                var mesExtenso = mesPorExtenso(mes);
                switch (periodo) {
                    case "periodoInicialHoje": {
                        $scope.mostraComparadoAHoje = true;
                        $scope.selecionadoPeriodoNome = "Hoje";
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos($scope.anoFront, mesInicial, new Date().getDate(), $scope.anoFront, mesInicial, $scope.diaFront, "Hoje", new Date().getDate() + mesExtenso + $scope.anoFront, "periodoInicialHoje");
                        break;
                    }
                    case "periodoInicialEstaSemana": {
                        $scope.mostraComparadoAEstaSemana = true;
                        $scope.selecionadoPeriodoNome = "Esta semana";
                        var primeiroDiaSemana = new Date(Date.now() - 86400000 * new Date().getDay());
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(primeiroDiaSemana.getFullYear(), primeiroDiaSemana.getMonth(), primeiroDiaSemana.getDate(), $scope.anoFront, mesInicial, $scope.diaFront, "Esta semana", primeiroDiaSemana.getDate() +
                            " a " +
                            $scope.diaFront +
                            mesExtenso +
                            $scope.anoFront, "periodoInicialEstaSemana");
                        break;
                    }
                    case "periodoInicialEsteMes": {
                        $scope.mostraComparadoAEsteMes = true;
                        $scope.selecionadoPeriodoNome = "Este mês";
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos($scope.anoFront, mesInicial, 1, $scope.anoFront, mesInicial, $scope.diaFront, "Este mês", "01 a " + $scope.diaFront + mesExtenso + $scope.anoFront, "periodoInicialEsteMes");
                        break;
                    }
                    case "periodoInicialEsteTrimestre": {
                        $scope.mostraComparadoAEsteTrimestre = true;
                        $scope.selecionadoPeriodoNome = "Este trimestre";
                        var primeiroDiaTrimestre = new Date();
                        primeiroDiaTrimestre.setDate(1);
                        if (primeiroDiaTrimestre.getMonth() + 1 < 4) {
                            primeiroDiaTrimestre.setMonth(0);
                        }
                        else if (primeiroDiaTrimestre.getMonth() + 1 < 7) {
                            primeiroDiaTrimestre.setMonth(3);
                        }
                        else if (primeiroDiaTrimestre.getMonth() + 1 < 10) {
                            primeiroDiaTrimestre.setMonth(6);
                        }
                        else {
                            primeiroDiaTrimestre.setMonth(9);
                        }
                        var mesTrimExtenso = mesPorExtenso(primeiroDiaTrimestre.getMonth() + 1);
                        mesTrimExtenso = mesTrimExtenso.replace(", ", "");
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos($scope.anoFront, primeiroDiaTrimestre.getMonth(), 1, $scope.anoFront, mesInicial, $scope.diaFront, "Este trimestre", "01" +
                            mesTrimExtenso +
                            " a " +
                            $scope.diaFront +
                            mesExtenso +
                            $scope.anoFront, "periodoInicialEsteTrimestre");
                        break;
                    }
                    // case "periodoInicialEsteAno": {
                    //   $scope.mostraComparadoAEsteAno = true;
                    //   $scope.selecionadoPeriodoNome = "Este ano";
                    //   $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(
                    //     $scope.anoFront,
                    //     0,
                    //     1,
                    //     $scope.anoFront,
                    //     mesInicial,
                    //     $scope.diaFront,
                    //     "Este ano",
                    //     "01 jan a " + $scope.diaFront + mesExtenso + $scope.anoFront,
                    //     "periodoInicialEsteAno",
                    //   );
                    //   break;
                    // }
                    case "periodoInicialOntem": {
                        $scope.mostraComparadoAOntem = true;
                        $scope.selecionadoPeriodoNome = "Ontem";
                        var ontem = new Date(Date.now() - 86400000);
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), ontem.getMonth(), ontem.getDate(), ontem.getFullYear(), ontem.getMonth(), ontem.getDate(), "Ontem", ontem.getDate() + mesExtenso + ontem.getFullYear(), "periodoInicialOntem");
                        break;
                    }
                    case "periodoInicialSemanaAnterior": {
                        $scope.mostraComparadoASemanaAnterior = true;
                        $scope.selecionadoPeriodoNome = "Semana anterior";
                        var primeiroDiaSemanaPassada = new Date(Date.now() - 86400000 * (new Date().getDay() + 7));
                        var ultimoDiaSemanaPassada = new Date(Date.now() - 86400000 * (new Date().getDay() + 1));
                        var semanaAnteriorExtenso = mesPorExtenso(primeiroDiaSemanaPassada.getMonth() + 1);
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(primeiroDiaSemanaPassada.getFullYear(), primeiroDiaSemanaPassada.getMonth(), primeiroDiaSemanaPassada.getDate(), ultimoDiaSemanaPassada.getFullYear(), ultimoDiaSemanaPassada.getMonth(), ultimoDiaSemanaPassada.getDate(), "Semana anterior", primeiroDiaSemanaPassada.getDate() +
                            " a " +
                            ultimoDiaSemanaPassada.getDate() +
                            semanaAnteriorExtenso +
                            ultimoDiaSemanaPassada.getFullYear(), "periodoInicialSemanaAnterior");
                        break;
                    }
                    case "periodoInicialMesAnterior": {
                        $scope.mostraComparadoAMesAnterior = true;
                        $scope.selecionadoPeriodoNome = "Mês anterior";
                        var ultimoDiaMesAnterior = new Date(Date.now() - 86400000 * new Date().getDate());
                        var mesAnteriorExtenso = mesPorExtenso(ultimoDiaMesAnterior.getMonth() + 1);
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(ultimoDiaMesAnterior.getFullYear(), ultimoDiaMesAnterior.getMonth(), 1, ultimoDiaMesAnterior.getFullYear(), ultimoDiaMesAnterior.getMonth(), ultimoDiaMesAnterior.getDate(), "Mês anterior", "01 a " +
                            ultimoDiaMesAnterior.getDate() +
                            mesAnteriorExtenso +
                            ultimoDiaMesAnterior.getFullYear(), "periodoInicialMesAnterior");
                        break;
                    }
                    case "periodoInicialTrimestreAnterior": {
                        $scope.mostraComparadoATrimestreAnterior = true;
                        $scope.selecionadoPeriodoNome = "Trimestre anterior";
                        var primeiroDiaTrimestreAnterior = new Date();
                        primeiroDiaTrimestreAnterior.setDate(1);
                        if (primeiroDiaTrimestreAnterior.getMonth() + 1 < 4) {
                            primeiroDiaTrimestreAnterior.setMonth(9);
                            primeiroDiaTrimestreAnterior.setFullYear(primeiroDiaTrimestreAnterior.getFullYear() - 1);
                        }
                        else if (primeiroDiaTrimestreAnterior.getMonth() + 1 < 7) {
                            primeiroDiaTrimestreAnterior.setMonth(1);
                        }
                        else if (primeiroDiaTrimestreAnterior.getMonth() + 1 < 10) {
                            primeiroDiaTrimestreAnterior.setMonth(3);
                        }
                        else {
                            primeiroDiaTrimestreAnterior.setMonth(6);
                        }
                        var ultimoDiaTrimestreAnterior = new Date(primeiroDiaTrimestreAnterior.getFullYear(), primeiroDiaTrimestreAnterior.getMonth() + 3, 0);
                        var trimAntExtensoUm = mesPorExtenso(primeiroDiaTrimestreAnterior.getMonth() + 1);
                        trimAntExtensoUm = trimAntExtensoUm.replace(", ", "");
                        var trimAntExtensoDois = mesPorExtenso(ultimoDiaTrimestreAnterior.getMonth() + 1);
                        $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(primeiroDiaTrimestreAnterior.getFullYear(), primeiroDiaTrimestreAnterior.getMonth(), 1, ultimoDiaTrimestreAnterior.getFullYear(), ultimoDiaTrimestreAnterior.getMonth(), ultimoDiaTrimestreAnterior.getDate(), "Trimestre anterior", "01" +
                            trimAntExtensoUm +
                            " a " +
                            ultimoDiaTrimestreAnterior.getDate() +
                            trimAntExtensoDois +
                            ultimoDiaTrimestreAnterior.getFullYear(), "periodoInicialEsteTrimestre");
                        break;
                    }
                    // case "periodoInicialAnoAnterior": {
                    //   $scope.mostraComparadoAAnoAnterior = true;
                    //   $scope.selecionadoPeriodoNome = "Ano anterior";
                    //   $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(
                    //     $scope.anoFront - 1,
                    //     0,
                    //     1,
                    //     $scope.anoFront - 1,
                    //     11,
                    //     31,
                    //     "Ano anterior",
                    //     "01 jan a 31 dez, " + ($scope.anoFront - 1),
                    //     "periodoInicialAnoAnterior",
                    //   );
                    //   break;
                    // }
                    // case "periodoInicialTodoPeriodo": {
                    //   var obj = document.getElementById("conjunto-esteMes");
                    //   $(obj).addClass("conjunto-esteMes-semComparacao");
                    //   $scope.naoMostraComparadoA = true;
                    //   $scope.selecionadoPeriodoNome = "Todo período";
                    //   $scope.filtroPeriodoEscolhido = $scope.montarDataFiltrosPeriodos(
                    //     2017,
                    //     0,
                    //     1,
                    //     $scope.anoFront,
                    //     mesInicial,
                    //     $scope.diaFront,
                    //     "Todo período",
                    //     "Todo período",
                    //     "periodoInicialTodoPeriodo",
                    //   );
                    //   break;
                    // }
                    default:
                        break;
                }
            }
            // else { // else para caso queira desmarcar a opção que está marcada
            //     $(obj).removeClass('option-checked');
            //     $(img).addClass('invisible');
            //     $scope.selecionadoComparacao = '';
            // }
        };
        $scope.selecionarPeriodoComparacao = function (periodo) {
            var obj, img;
            obj = document.getElementById(periodo);
            img = obj.getElementsByTagName("img");
            if (!$(obj).hasClass("option-checked")) {
                $(obj).addClass("option-checked");
                $(img).removeClass("invisible");
                var objAux, imgAux;
                if ($scope.selecionadoComparadoA !== "") {
                    objAux = document.getElementById($scope.selecionadoComparadoA);
                    imgAux = objAux.getElementsByTagName("img");
                    $(objAux).removeClass("option-checked");
                    $(imgAux).addClass("invisible");
                }
                $scope.selecionadoComparadoA = periodo;
                switch (periodo) {
                    case "hojeComparadoAOntem": {
                        var ontem = new Date(Date.now() - 86400000);
                        var mesExtenso = mesPorExtenso(ontem.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), ontem.getMonth(), ontem.getDate(), ontem.getFullYear(), ontem.getMonth(), ontem.getDate(), "Ontem", ontem.getDate() + mesExtenso + ontem.getFullYear(), "hojeComparadoAOntem");
                        break;
                    }
                    case "hojeComparadoASemanaAnteriorNoMesmoDia": {
                        var primeiroDiaSemanaPassada = new Date(Date.now() - 86400000 * 7);
                        var ultimoDiaSemanaPassada = new Date(Date.now() - 86400000 * 7);
                        var mesExtenso = mesPorExtenso(primeiroDiaSemanaPassada.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaSemanaPassada.getFullYear(), primeiroDiaSemanaPassada.getMonth(), primeiroDiaSemanaPassada.getDate(), ultimoDiaSemanaPassada.getFullYear(), ultimoDiaSemanaPassada.getMonth(), ultimoDiaSemanaPassada.getDate(), "Mesmo dia da semana anterior", primeiroDiaSemanaPassada.getDate() +
                            mesExtenso +
                            primeiroDiaSemanaPassada.getFullYear(), "hojeComparadoASemanaAnteriorNoMesmoDia");
                        break;
                    }
                    case "hojeComparadoAMesAnteriorNoMesmoDia": {
                        var hoje = new Date();
                        var mesAtual = hoje.getMonth();
                        var mesExtenso = mesPorExtenso(mesAtual);
                        if (mesAtual + 1 == 2 ||
                            mesAtual + 1 == 4 ||
                            mesAtual + 1 == 6 ||
                            mesAtual + 1 == 8 ||
                            mesAtual + 1 == 9 ||
                            mesAtual + 1 == 11) {
                            // mes anterior com numero total de dias maior ou igual que o atual
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 1, hoje.getDate(), hoje.getFullYear(), mesAtual - 1, hoje.getDate(), "Mesmo dia do mês anterior", hoje.getDate() + mesExtenso + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                        }
                        else if (mesAtual + 1 == 5 ||
                            mesAtual + 1 == 7 ||
                            mesAtual + 1 == 10 ||
                            mesAtual + 1 == 12) {
                            // mes anterior com numero total de dias menor que o atual
                            if (hoje.getDate() == 31)
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 1, 30, hoje.getFullYear(), mesAtual - 1, 30, "Mesmo dia do mês anterior", 30 + mesExtenso + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                            else
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 1, hoje.getDate(), hoje.getFullYear(), mesAtual - 1, hoje.getDate(), "Mesmo dia do mês anterior", hoje.getDate() + mesExtenso + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                        }
                        else if (mesAtual + 1 == 1) {
                            // mes anterior sendo dezembro entao o ano e menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear() - 1, 12, hoje.getDate(), hoje.getFullYear() - 1, 12, hoje.getDate(), "Mesmo dia do mês anterior", hoje.getDate() + " dez," + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                        }
                        else {
                            // mes anterior sendo fevereiro entao pode ter 2 ou 3 dias a menos dependendo do ano ser bisexto
                            if (hoje.getFullYear() % 4 == 0) {
                                // ano bisexto
                                if (hoje.getDate() == 30 || hoje.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 1, 29, hoje.getFullYear(), mesAtual - 1, 29, "Mesmo dia do mês anterior", "29" + mesExtenso + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 1, hoje.getDate(), hoje.getFullYear(), mesAtual - 1, hoje.getDate(), "Mesmo dia do mês anterior", hoje.getDate() + mesExtenso + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                            }
                            else {
                                if (hoje.getDate() == 29 ||
                                    hoje.getDate() == 30 ||
                                    hoje.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 1, 28, hoje.getFullYear(), mesAtual - 1, 28, "Mesmo dia do mês anterior", "28" + mesExtenso + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 1, hoje.getDate(), hoje.getFullYear(), mesAtual - 1, hoje.getDate(), "Mesmo dia do mês anterior", hoje.getDate() + mesExtenso + hoje.getFullYear(), "hojeComparadoAMesAnteriorNoMesmoDia");
                            }
                        }
                        break;
                    }
                    case "hojeComparadoATrimestreAnteriorNoMesmoDia": {
                        var hoje = new Date();
                        var mesAtual = hoje.getMonth();
                        var mesTrimAnteriorExtenso = mesPorExtenso(mesAtual - 2);
                        if (mesAtual + 1 == 1) {
                            // mes do trimestre anterior com mesmo numero de dias mas com ano menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear() - 1, 9, hoje.getDate(), hoje.getFullYear() - 1, 9, hoje.getDate(), "Mesmo dia do trimestre anterior", hoje.getDate() + " set, " + (hoje.getFullYear() - 1), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                        }
                        else if (mesAtual + 1 == 2) {
                            // mes do trimestre anterior com mesmo numero de dias mas com ano menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear() - 1, 10, hoje.getDate(), hoje.getFullYear() - 1, 10, hoje.getDate(), "Mesmo dia do trimestre anterior", hoje.getDate() + " out, " + (hoje.getFullYear() - 1), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                        }
                        else if (mesAtual + 1 == 3) {
                            // mes do trimestre anterior com mesmo numero de dias mas com ano menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear() - 1, 11, hoje.getDate(), hoje.getFullYear() - 1, 11, hoje.getDate(), "Mesmo dia do trimestre anterior", hoje.getDate() + " nov, " + (hoje.getFullYear() - 1), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                        }
                        else if (mesAtual + 1 == 4 ||
                            mesAtual + 1 == 6 ||
                            mesAtual + 1 == 8 ||
                            mesAtual + 1 == 9 ||
                            mesAtual + 1 == 10 ||
                            mesAtual + 1 == 11) {
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 3, hoje.getDate(), hoje.getFullYear(), mesAtual - 3, hoje.getDate(), "Mesmo dia do trimestre anterior", hoje.getDate() +
                                mesTrimAnteriorExtenso +
                                (hoje.getFullYear() - 1), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                        }
                        else if (mesAtual + 1 == 7 || mesAtual + 1 == 12) {
                            if (hoje.getDate() == 31)
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 3, 30, hoje.getFullYear(), mesAtual - 3, 30, "Mesmo dia do trimestre anterior", "30" + mesTrimAnteriorExtenso + hoje.getFullYear(), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                            else
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 3, hoje.getDate(), hoje.getFullYear(), mesAtual - 3, hoje.getDate(), "Mesmo dia do trimestre anterior", hoje.getDate() + mesTrimAnteriorExtenso + hoje.getFullYear(), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                        }
                        else {
                            // mes anterior sendo fevereiro entao pode ter 2 ou 3 dias a menos dependendo do ano ser bisexto
                            if (hoje.getFullYear() % 4 == 0) {
                                // ano bisexto
                                if (hoje.getDate() == 30 || hoje.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 3, 29, hoje.getFullYear(), mesAtual - 3, 29, "Mesmo dia do trimestre anterior", "29" + mesTrimAnteriorExtenso + hoje.getFullYear(), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 3, hoje.getDate(), hoje.getFullYear(), mesAtual - 3, hoje.getDate(), "Mesmo dia do trimestre anterior", hoje.getDate() +
                                        mesTrimAnteriorExtenso +
                                        hoje.getFullYear(), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                            }
                            else {
                                if (hoje.getDate() == 29 ||
                                    hoje.getDate() == 30 ||
                                    hoje.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 3, 28, hoje.getFullYear(), mesAtual - 3, 28, "Mesmo dia do trimestre anterior", "28" + mesTrimAnteriorExtenso + hoje.getFullYear(), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear(), mesAtual - 3, hoje.getDate(), hoje.getFullYear(), mesAtual - 3, hoje.getDate(), "Mesmo dia do trimestre anterior", hoje.getDate() +
                                        mesTrimAnteriorExtenso +
                                        hoje.getFullYear(), "hojeComparadoATrimestreAnteriorNoMesmoDia");
                            }
                        }
                        break;
                    }
                    case "hojeComparadoAAnoAnteriorNoMesmoDia": {
                        var hoje = new Date();
                        var mesAtual = hoje.getMonth();
                        var mesExtenso = mesPorExtenso(mesAtual + 1);
                        // ano atual sendo bisexto, fevereiro do ano anterior nao tem o dia 29
                        if (hoje.getFullYear() % 4 == 0)
                            if (mesAtual == 2 && hoje == 29)
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear() - 1, mesAtual, 28, hoje.getFullYear() - 1, mesAtual, 28, "Mesmo dia do ano anterior", "28" + mesExtenso + (hoje.getFullYear() - 1), "hojeComparadoAAnoAnteriorNoMesmoDia");
                            else
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(hoje.getFullYear() - 1, mesAtual, hoje.getDate(), hoje.getFullYear() - 1, mesAtual, hoje.getDate(), "Mesmo dia do ano anterior", hoje.getDate() + mesExtenso + (hoje.getFullYear() - 1), "hojeComparadoAAnoAnteriorNoMesmoDia");
                        break;
                    }
                    case "estaSemanaComparadoASemanaAnterior": {
                        var hoje = new Date();
                        var primeiroDiaSemanaAnterior = new Date(Date.now() - 86400000 * (hoje.getDay() + 7));
                        var hojeDaSemanaAnterior = new Date(Date.now() - 86400000 * 7);
                        var semanaAnteriorExtenso = mesPorExtenso(hojeDaSemanaAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaSemanaAnterior.getFullYear(), primeiroDiaSemanaAnterior.getMonth(), primeiroDiaSemanaAnterior.getDate(), hojeDaSemanaAnterior.getFullYear(), hojeDaSemanaAnterior.getMonth(), hojeDaSemanaAnterior.getDate(), "Semana anterior", primeiroDiaSemanaAnterior.getDate() +
                            " a " +
                            hojeDaSemanaAnterior.getDate() +
                            semanaAnteriorExtenso +
                            hojeDaSemanaAnterior.getFullYear(), "estaSemanaComparadoASemanaAnterior");
                        break;
                    }
                    case "estaSemanaComparadoAMesAnteriorNaMesmaSemana": {
                        var hoje = new Date();
                        var hojeAux = hoje;
                        var contSemanas = 0;
                        var ultimoDomingo;
                        var diaSemana = hoje.getDay();
                        var diaMes = hoje.getDate();
                        // contagem de quantas semanas se passaram ate a semana atual
                        // um mes pode ter no maximo 6 semanas
                        for (var i = 0; i < 6; i++) {
                            contSemanas++;
                            ultimoDomingo = new Date(hojeAux.getTime() - 86400000 * diaSemana);
                            if (ultimoDomingo.getDate() > diaMes) {
                                i = 6;
                            }
                            else {
                                diaSemana = 7;
                                diaMes = ultimoDomingo.getDate();
                                hojeAux = ultimoDomingo;
                                if (diaMes == 1)
                                    i = 6;
                            }
                        }
                        // encontra qual e o primeiro dia do mes anterior
                        var mesAtual = hoje.getMonth() + 1;
                        if (mesAtual == 1) {
                            var primeiroDiaMesAnterior = new Date("12-01-" + (hoje.getFullYear() - 1));
                        }
                        else {
                            var primeiroDiaMesAnterior = new Date(mesAtual - 1 + "-01-" + hoje.getFullYear());
                        }
                        var diaSemana = primeiroDiaMesAnterior.getDay();
                        var diaMes = primeiroDiaMesAnterior.getDate();
                        var primeiroDiaMesmaSemanaMesAnterior;
                        var ultimoDiaMesmaSemanaMesAnterior;
                        // encontra qual e o primeiro domingo do mes anterior
                        var primeiroDomingoMesAnterior = new Date(primeiroDiaMesAnterior.getTime() - 86400000 * diaSemana);
                        // encontra qual e a semana que tem a mesma contagem da semana atual dentro do mes passado
                        for (var i = contSemanas; i > 0; i--) {
                            primeiroDiaMesmaSemanaMesAnterior = primeiroDomingoMesAnterior;
                            ultimoDiaMesmaSemanaMesAnterior = new Date(primeiroDiaMesmaSemanaMesAnterior.getTime() +
                                86400000 * hoje.getDay());
                            primeiroDomingoMesAnterior = new Date(primeiroDomingoMesAnterior.getTime() + 86400000 * 7);
                        }
                        var semanaMesAnteriorExtenso = mesPorExtenso(ultimoDiaMesmaSemanaMesAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesmaSemanaMesAnterior.getFullYear(), primeiroDiaMesmaSemanaMesAnterior.getMonth(), primeiroDiaMesmaSemanaMesAnterior.getDate(), ultimoDiaMesmaSemanaMesAnterior.getFullYear(), ultimoDiaMesmaSemanaMesAnterior.getMonth(), ultimoDiaMesmaSemanaMesAnterior.getDate(), "Mesma semana do mês anterior", primeiroDiaMesmaSemanaMesAnterior.getDate() +
                            " a " +
                            ultimoDiaMesmaSemanaMesAnterior.getDate() +
                            semanaMesAnteriorExtenso +
                            ultimoDiaMesmaSemanaMesAnterior.getFullYear(), "estaSemanaComparadoAMesAnteriorNaMesmaSemana");
                        break;
                    }
                    case "estaSemanaComparadoATrimestreAnteriorNaMesmaSemana": {
                        var hoje = new Date();
                        var hojeAux = hoje;
                        var contSemanas = 0;
                        var ultimoDomingo;
                        var diaSemana = hoje.getDay();
                        var diaMes = hoje.getDate();
                        // contagem de quantas semanas se passaram ate a semana atual
                        // um mes pode ter no maximo 6 semanas
                        for (var i = 0; i < 6; i++) {
                            contSemanas++;
                            ultimoDomingo = new Date(hojeAux.getTime() - 86400000 * diaSemana);
                            if (ultimoDomingo.getDate() > diaMes) {
                                i = 6;
                            }
                            else {
                                diaSemana = 7;
                                diaMes = ultimoDomingo.getDate();
                                hojeAux = ultimoDomingo;
                                if (diaMes == 1)
                                    i = 6;
                            }
                        }
                        // encontra qual e o primeiro dia do mes equivalente no trimestre anterior
                        var mesAtual = hoje.getMonth() + 1;
                        if (mesAtual == 1) {
                            var primeiroDiaTrimestreAnterior = new Date("10-01-" + (hoje.getFullYear() - 1));
                        }
                        else if (mesAtual == 2) {
                            var primeiroDiaTrimestreAnterior = new Date("11-01-" + (hoje.getFullYear() - 1));
                        }
                        else if (mesAtual == 3) {
                            var primeiroDiaTrimestreAnterior = new Date("12-01-" + (hoje.getFullYear() - 1));
                        }
                        else {
                            var primeiroDiaTrimestreAnterior = new Date(mesAtual - 3 + "-01-" + hoje.getFullYear());
                        }
                        var diaSemana = primeiroDiaTrimestreAnterior.getDay();
                        var diaMes = primeiroDiaTrimestreAnterior.getDate();
                        var primeiroDiaMesmaSemanaTrimestreAnterior;
                        var ultimoDiaMesmaSemanaTrimestreAnterior;
                        // encontra qual e o primeiro domingo do mes equivalente no trimestre anterior
                        var primeiroDomingoTrimestreAnterior = new Date(primeiroDiaTrimestreAnterior.getTime() - 86400000 * diaSemana);
                        // encontra qual e a semana que tem a mesma contagem da semana atual dentro do trimestre passado
                        for (var i = contSemanas; i > 0; i--) {
                            primeiroDiaMesmaSemanaTrimestreAnterior =
                                primeiroDomingoTrimestreAnterior;
                            ultimoDiaMesmaSemanaTrimestreAnterior = new Date(primeiroDiaMesmaSemanaTrimestreAnterior.getTime() +
                                86400000 * hoje.getDay());
                            primeiroDomingoTrimestreAnterior = new Date(primeiroDomingoTrimestreAnterior.getTime() + 86400000 * 7);
                        }
                        var semanaAnteriorExtenso = mesPorExtenso(ultimoDiaMesmaSemanaTrimestreAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesmaSemanaTrimestreAnterior.getFullYear(), primeiroDiaMesmaSemanaTrimestreAnterior.getMonth(), primeiroDiaMesmaSemanaTrimestreAnterior.getDate(), ultimoDiaMesmaSemanaTrimestreAnterior.getFullYear(), ultimoDiaMesmaSemanaTrimestreAnterior.getMonth(), ultimoDiaMesmaSemanaTrimestreAnterior.getDate(), "Mesma semana do trimestre anterior", primeiroDiaMesmaSemanaTrimestreAnterior.getDate() +
                            " a " +
                            ultimoDiaMesmaSemanaTrimestreAnterior.getDate() +
                            semanaAnteriorExtenso +
                            ultimoDiaMesmaSemanaTrimestreAnterior.getFullYear(), "estaSemanaComparadoATrimestreAnteriorNaMesmaSemana");
                        break;
                    }
                    case "estaSemanaComparadoAAnoAnteriorNaMesmaSemana": {
                        var hoje = new Date();
                        var hojeAux = hoje;
                        var contSemanas = 0;
                        var ultimoDomingo;
                        var diaSemana = hoje.getDay();
                        var diaMes = hoje.getDate();
                        // contagem de quantas semanas se passaram ate a semana atual
                        // um mes pode ter no maximo 6 semanas
                        for (var i = 0; i < 6; i++) {
                            contSemanas++;
                            ultimoDomingo = new Date(hojeAux.getTime() - 86400000 * diaSemana);
                            if (ultimoDomingo.getDate() > diaMes) {
                                i = 6;
                            }
                            else {
                                diaSemana = 7;
                                diaMes = ultimoDomingo.getDate();
                                hojeAux = ultimoDomingo;
                                if (diaMes == 1)
                                    i = 6;
                            }
                        }
                        // encontra qual e o primeiro dia do mesmo mes no ano anterior
                        var mesAtual = hoje.getMonth() + 1;
                        var primeiroDiaMesmoMesDoAnoAnterior = new Date(mesAtual + "-01-" + (hoje.getFullYear() - 1));
                        var diaSemana = primeiroDiaMesmoMesDoAnoAnterior.getDay();
                        var diaMes = primeiroDiaMesmoMesDoAnoAnterior.getDate();
                        var primeiroDiaMesmaSemanaAnoAnterior;
                        var ultimoDiaMesmaSemanaAnoAnterior;
                        // encontra qual e o primeiro domingo do mesmo mes no ano anterior
                        var primeiroDomingoMesmoMesDoAnoAnterior = new Date(primeiroDiaMesmoMesDoAnoAnterior.getTime() - 86400000 * diaSemana);
                        // encontra qual e a semana que tem a mesma contagem da semana atual dentro do ano passado
                        for (var i = contSemanas; i > 0; i--) {
                            primeiroDiaMesmaSemanaAnoAnterior =
                                primeiroDomingoMesmoMesDoAnoAnterior;
                            ultimoDiaMesmaSemanaAnoAnterior = new Date(primeiroDiaMesmaSemanaAnoAnterior.getTime() +
                                86400000 * hoje.getDay());
                            primeiroDomingoMesmoMesDoAnoAnterior = new Date(primeiroDomingoMesmoMesDoAnoAnterior.getTime() + 86400000 * 7);
                        }
                        var semanaAnteriorExtenso = mesPorExtenso(ultimoDiaMesmaSemanaAnoAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesmaSemanaAnoAnterior.getFullYear(), primeiroDiaMesmaSemanaAnoAnterior.getMonth(), primeiroDiaMesmaSemanaAnoAnterior.getDate(), ultimoDiaMesmaSemanaAnoAnterior.getFullYear(), ultimoDiaMesmaSemanaAnoAnterior.getMonth(), ultimoDiaMesmaSemanaAnoAnterior.getDate(), "Mesma semana do ano anterior", primeiroDiaMesmaSemanaAnoAnterior.getDate() +
                            " a " +
                            ultimoDiaMesmaSemanaAnoAnterior.getDate() +
                            semanaAnteriorExtenso +
                            ultimoDiaMesmaSemanaAnoAnterior.getFullYear(), "estaSemanaComparadoAAnoAnteriorNaMesmaSemana");
                        break;
                    }
                    case "esteMesComparadoAMesAnterior": {
                        var hoje = new Date();
                        var diaMes = hoje.getDate();
                        var diaMesAux = hoje.getDate();
                        var mesAtual = hoje.getMonth() + 1;
                        var anoAtual = hoje.getFullYear();
                        // verificando qual e o mes atual pois dependendo do mes o mes anterior pode nao ter o
                        // mesmo numero de dias, exemplo dezembro com 31 dias e novembro, o seu equivalente com 30 dias
                        if (mesAtual == 5 ||
                            mesAtual == 7 ||
                            mesAtual == 10 ||
                            mesAtual == 12) {
                            if (diaMes == 31) {
                                diaMesAux--;
                            }
                        }
                        else if (mesAtual == 3) {
                            // comparacao do mes de março com fevereiro que pode ter 28 ou 29 dias
                            if (anoAtual % 4 == 0) {
                                if (diaMes == 30)
                                    diaMesAux--;
                                if (diaMes == 31)
                                    diaMesAux -= 2;
                            }
                            else {
                                if (diaMes == 29)
                                    diaMesAux--;
                                if (diaMes == 30)
                                    diaMesAux -= 2;
                                if (diaMes == 31)
                                    diaMesAux -= 3;
                            }
                        }
                        if (mesAtual == 1) {
                            var primeiroDiaMesAnterior = new Date("12-01-" + (anoAtual - 1));
                            var ultimoDiaMesAnterior = new Date("12-" + diaMesAux + "-" + (anoAtual - 1));
                        }
                        else {
                            var primeiroDiaMesAnterior = new Date(mesAtual - 1 + "-01-" + anoAtual);
                            var ultimoDiaMesAnterior = new Date(mesAtual - 1 + "-" + diaMesAux + "-" + anoAtual);
                        }
                        var mesAnteriorExtenso = mesPorExtenso(ultimoDiaMesAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesAnterior.getFullYear(), primeiroDiaMesAnterior.getMonth(), primeiroDiaMesAnterior.getDate(), ultimoDiaMesAnterior.getFullYear(), ultimoDiaMesAnterior.getMonth(), ultimoDiaMesAnterior.getDate(), "Mês anterior", "01 a " +
                            ultimoDiaMesAnterior.getDate() +
                            mesAnteriorExtenso +
                            ultimoDiaMesAnterior.getFullYear(), "esteMesComparadoAMesAnterior");
                        break;
                    }
                    case "esteMesComparadoATrimestreAnteriorNoMesmoMes": {
                        var hoje = new Date();
                        var diaMes = hoje.getDate();
                        var diaMesAux = hoje.getDate();
                        var mesAtual = hoje.getMonth() + 1;
                        var anoAtual = hoje.getFullYear();
                        // verificando qual e o mes atual pois dependendo do mes o equivalente do
                        // trimestre anterior pode nao ter o mesmo numero de dias, exemplo
                        // dezembro com 31 dias e setembro, o seu equivalente com 30 dias
                        if (mesAtual == 7 || mesAtual == 12) {
                            if (diaMes == 31) {
                                diaMesAux--;
                            }
                        }
                        if (mesAtual == 5) {
                            // comparacao do mes de maio com fevereiro que pode ter 28 ou 29 dias
                            if (anoAtual % 4 == 0) {
                                if (diaMes == 30)
                                    diaMesAux--;
                                if (diaMes == 31)
                                    diaMesAux -= 2;
                            }
                            else {
                                if (diaMes == 29)
                                    diaMesAux--;
                                if (diaMes == 30)
                                    diaMesAux -= 2;
                                if (diaMes == 31)
                                    diaMesAux -= 3;
                            }
                        }
                        if (mesAtual == 1) {
                            var primeiroDiaMesmoMesDoTrimestreAnterior = new Date("10-01-" + (anoAtual - 1));
                            var ultimoDiaMesmoMesDoTrimestreAnterior = new Date("10-" + diaMesAux + "-" + (anoAtual - 1));
                        }
                        else if (mesAtual == 2) {
                            var primeiroDiaMesmoMesDoTrimestreAnterior = new Date("11-01-" + (anoAtual - 1));
                            var ultimoDiaMesmoMesDoTrimestreAnterior = new Date("11-" + diaMesAux + "-" + (anoAtual - 1));
                        }
                        else if (mesAtual == 3) {
                            var primeiroDiaMesmoMesDoTrimestreAnterior = new Date("12-01-" + (anoAtual - 1));
                            var ultimoDiaMesmoMesDoTrimestreAnterior = new Date("12-" + diaMesAux + "-" + (anoAtual - 1));
                        }
                        else {
                            var primeiroDiaMesmoMesDoTrimestreAnterior = new Date(mesAtual - 3 + "-01-" + anoAtual);
                            var ultimoDiaMesmoMesDoTrimestreAnterior = new Date(mesAtual - 3 + "-" + diaMesAux + "-" + anoAtual);
                        }
                        var mesTrimAnteriorExtenso = mesPorExtenso(ultimoDiaMesmoMesDoTrimestreAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesmoMesDoTrimestreAnterior.getFullYear(), primeiroDiaMesmoMesDoTrimestreAnterior.getMonth(), primeiroDiaMesmoMesDoTrimestreAnterior.getDate(), ultimoDiaMesmoMesDoTrimestreAnterior.getFullYear(), ultimoDiaMesmoMesDoTrimestreAnterior.getMonth(), ultimoDiaMesmoMesDoTrimestreAnterior.getDate(), "Mesmo período do trimestre anterior", "01 a " +
                            ultimoDiaMesmoMesDoTrimestreAnterior.getDate() +
                            mesTrimAnteriorExtenso +
                            ultimoDiaMesmoMesDoTrimestreAnterior.getFullYear(), "esteMesComparadoATrimestreAnteriorNoMesmoMes");
                        break;
                    }
                    case "esteMesComparadoAAnoAnteriorNoMesmoMes": {
                        var hoje = new Date();
                        var diaMes = hoje.getDate();
                        var mesAtual = hoje.getMonth() + 1;
                        var anoAtual = hoje.getFullYear();
                        if (mesAtual == 2 && diaMes == 29) {
                            var primeiroDiaMesmoMesDoAnoAnterior = new Date(mesAtual + "-01-" + (anoAtual - 1));
                            var ultimoDiaMesMesmoMesDoAnoAnterior = new Date(mesAtual + "-28-" + (anoAtual - 1));
                        }
                        else {
                            var primeiroDiaMesmoMesDoAnoAnterior = new Date(mesAtual + "-01-" + (anoAtual - 1));
                            var ultimoDiaMesMesmoMesDoAnoAnterior = new Date(mesAtual + "-" + diaMes + "-" + (anoAtual - 1));
                        }
                        var mesAnoAnteriorExtenso = mesPorExtenso(ultimoDiaMesMesmoMesDoAnoAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesmoMesDoAnoAnterior.getFullYear(), primeiroDiaMesmoMesDoAnoAnterior.getMonth(), primeiroDiaMesmoMesDoAnoAnterior.getDate(), ultimoDiaMesMesmoMesDoAnoAnterior.getFullYear(), ultimoDiaMesMesmoMesDoAnoAnterior.getMonth(), ultimoDiaMesMesmoMesDoAnoAnterior.getDate(), "Mesmo mês do ano anterior", "01 a " +
                            ultimoDiaMesMesmoMesDoAnoAnterior.getDate() +
                            mesAnoAnteriorExtenso +
                            ultimoDiaMesMesmoMesDoAnoAnterior.getFullYear(), "esteMesComparadoAAnoAnteriorNoMesmoMes");
                        break;
                    }
                    // case "esteTrimestreComparadoATrimestreAnterior": {
                    //   var hoje = new Date();
                    //   var diaMes = hoje.getDate();
                    //   var diaMesAux = diaMes;
                    //   var mesAtual = hoje.getMonth() + 1;
                    //   var anoAtual = hoje.getFullYear();
                    //   var trimestreAnteriorNoAnoPassado = false;
                    //   var primeiroMesTrimestre;
                    //   // encontrando qual e o primeiro mes do trimestre atual
                    //   if (mesAtual == 1 || mesAtual == 2 || mesAtual == 3) {
                    //     primeiroMesTrimestre = 1;
                    //     trimestreAnteriorNoAnoPassado = true;
                    //   }
                    //   if (mesAtual == 4 || mesAtual == 5 || mesAtual == 6) {
                    //     primeiroMesTrimestre = 4;
                    //   }
                    //   if (mesAtual == 7 || mesAtual == 8 || mesAtual == 9) {
                    //     primeiroMesTrimestre = 7;
                    //   }
                    //   if (mesAtual == 10 || mesAtual == 11 || mesAtual == 12) {
                    //     primeiroMesTrimestre = 10;
                    //   }
                    //   // verificando qual e o mes atual pois dependendo do mes o equivalente do
                    //   // trimestre anterior pode nao ter o mesmo numero de dias, exemplo
                    //   // dezembro com 31 dias e setembro, o seu equivalente com 30 dias
                    //   if (mesAtual == 7 || mesAtual == 12) {
                    //     if (diaMes == 31) {
                    //       diaMesAux--;
                    //     }
                    //   }
                    //   if (mesAtual == 5) {
                    //     // comparacao do mes de maio com fevereiro que pode ter 28 ou 29 dias
                    //     if (anoAtual % 4 == 0) {
                    //       if (diaMes == 30) diaMesAux--;
                    //       if (diaMes == 31) diaMesAux -= 2;
                    //     } else {
                    //       if (diaMes == 29) diaMesAux--;
                    //       if (diaMes == 30) diaMesAux -= 2;
                    //       if (diaMes == 31) diaMesAux -= 3;
                    //     }
                    //   }
                    //   var primeiroDiaTrimestreAnterior;
                    //   var ultimoDiaTrimestreAnterior;
                    //   // encontrando qual e o primerio mes do trimestre anterior
                    //   if (trimestreAnteriorNoAnoPassado) {
                    //     primeiroDiaTrimestreAnterior = new Date(
                    //       primeiroMesTrimestre + 9 + "-01-" + (anoAtual - 1),
                    //     );
                    //     ultimoDiaTrimestreAnterior = new Date(
                    //       mesAtual + 9 + "-" + diaMesAux + "-" + (anoAtual - 1),
                    //     );
                    //   } else {
                    //     primeiroDiaTrimestreAnterior = new Date(
                    //       primeiroMesTrimestre - 3 + "-01-" + anoAtual,
                    //     );
                    //     ultimoDiaTrimestreAnterior = new Date(
                    //       mesAtual - 3 + "-" + diaMesAux + "-" + anoAtual,
                    //     );
                    //   }
                    //   var mesTriAnteriorExtensoUm = mesPorExtenso(
                    //     primeiroDiaTrimestreAnterior.getMonth() + 1,
                    //   );
                    //   mesTriAnteriorExtensoUm = mesTriAnteriorExtensoUm.replace(", ", "");
                    //   var mesTriAnteriorExtensoDois = mesPorExtenso(
                    //     ultimoDiaTrimestreAnterior.getMonth() + 1,
                    //   );
                    //   $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(
                    //     primeiroDiaTrimestreAnterior.getFullYear(),
                    //     primeiroDiaTrimestreAnterior.getMonth(),
                    //     primeiroDiaTrimestreAnterior.getDate(),
                    //     ultimoDiaTrimestreAnterior.getFullYear(),
                    //     ultimoDiaTrimestreAnterior.getMonth(),
                    //     ultimoDiaTrimestreAnterior.getDate(),
                    //     "Trimestre anterior",
                    //     "01" +
                    //       mesTriAnteriorExtensoUm +
                    //       " a " +
                    //       ultimoDiaTrimestreAnterior.getDate() +
                    //       mesTriAnteriorExtensoDois +
                    //       ultimoDiaTrimestreAnterior.getFullYear(),
                    //     "esteTrimestreComparadoATrimestreAnterior",
                    //   );
                    //   break;
                    // }
                    case "esteTrimestreComparadoAAnoAnteriorNoMesmoTrimestre": {
                        var hoje = new Date();
                        var diaMes = hoje.getDate();
                        var diaMesAux = diaMes;
                        var mesAtual = hoje.getMonth() + 1;
                        var anoAtual = hoje.getFullYear();
                        var primeiroMesTrimestre;
                        // encontrando qual e o primeiro mes do trimestre atual
                        if (mesAtual == 1 || mesAtual == 2 || mesAtual == 3) {
                            primeiroMesTrimestre = 1;
                        }
                        if (mesAtual == 4 || mesAtual == 5 || mesAtual == 6) {
                            primeiroMesTrimestre = 4;
                        }
                        if (mesAtual == 7 || mesAtual == 8 || mesAtual == 9) {
                            primeiroMesTrimestre = 7;
                        }
                        if (mesAtual == 10 || mesAtual == 11 || mesAtual == 12) {
                            primeiroMesTrimestre = 10;
                        }
                        // verificando se o mes atual e fevereiro para compensar o dia a mais
                        // se for ano bisexto
                        if (mesAtual == 2 && anoAtual % 4 == 0) {
                            // comparacao do mes de maio com fevereiro que pode ter 28 ou 29 dias
                            if (diaMes == 29)
                                diaMesAux--;
                        }
                        var primeiroDiaTrimestreDoAnoAnterior = new Date(primeiroMesTrimestre + "-01-" + (anoAtual - 1));
                        var ultimoDiaTrimestreDoAnoAnterior = new Date(mesAtual + "-" + diaMesAux + "-" + (anoAtual - 1));
                        var mesAnoAnteriorExtensoUm = mesPorExtenso(primeiroDiaTrimestreDoAnoAnterior.getMonth() + 1);
                        mesAnoAnteriorExtensoUm = mesAnoAnteriorExtensoUm.replace(", ", "");
                        var mesAnoAnteriorExtensoDois = mesPorExtenso(ultimoDiaTrimestreDoAnoAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaTrimestreDoAnoAnterior.getFullYear(), primeiroDiaTrimestreDoAnoAnterior.getMonth(), primeiroDiaTrimestreDoAnoAnterior.getDate(), ultimoDiaTrimestreDoAnoAnterior.getFullYear(), ultimoDiaTrimestreDoAnoAnterior.getMonth(), ultimoDiaTrimestreDoAnoAnterior.getDate(), "Mesmo trimestre do ano anterior", "01" +
                            mesAnoAnteriorExtensoUm +
                            " a " +
                            ultimoDiaTrimestreDoAnoAnterior.getDate() +
                            mesAnoAnteriorExtensoDois +
                            ultimoDiaTrimestreDoAnoAnterior.getFullYear(), "esteTrimestreComparadoAAnoAnteriorNoMesmoTrimestre");
                        break;
                    }
                    // case "esteAnoComparadoAAnoAnterior": {
                    //   var hoje = new Date();
                    //   var diaMes = hoje.getDate();
                    //   var diaMesAux = diaMes;
                    //   var mesAtual = hoje.getMonth() + 1;
                    //   var anoAtual = hoje.getFullYear();
                    //   // verificando se o mes atual e fevereiro para compensar o dia a mais
                    //   // se for ano bisexto
                    //   if (mesAtual == 2 && anoAtual % 4 == 0) {
                    //     // comparacao do mes de maio com fevereiro que pode ter 28 ou 29 dias
                    //     if (diaMes == 29) diaMesAux--;
                    //   }
                    //   var primeiroDiaDoAnoAnterior = new Date("01-01-" + (anoAtual - 1));
                    //   var ultimoDiaDoAnoAnterior = new Date(
                    //     mesAtual + "-" + diaMesAux + "-" + (anoAtual - 1),
                    //   );
                    //   var mesExtenso = mesPorExtenso(
                    //     ultimoDiaDoAnoAnterior.getMonth() + 1,
                    //   );
                    //   $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(
                    //     primeiroDiaDoAnoAnterior.getFullYear(),
                    //     primeiroDiaDoAnoAnterior.getMonth(),
                    //     primeiroDiaDoAnoAnterior.getDate(),
                    //     ultimoDiaDoAnoAnterior.getFullYear(),
                    //     ultimoDiaDoAnoAnterior.getMonth(),
                    //     ultimoDiaDoAnoAnterior.getDate(),
                    //     "Ano anterior",
                    //     "01 jan a " +
                    //       ultimoDiaDoAnoAnterior.getDate() +
                    //       mesExtenso +
                    //       ultimoDiaDoAnoAnterior.getFullYear(),
                    //     "esteAnoComparadoAAnoAnterior",
                    //   );
                    //   break;
                    // }
                    case "ontemComparadoADoisDiasAtras": {
                        var doisDiasAtras = new Date(Date.now() - 86400000 * 2);
                        var mesExtenso = mesPorExtenso(doisDiasAtras.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(doisDiasAtras.getFullYear(), doisDiasAtras.getMonth(), doisDiasAtras.getDate(), doisDiasAtras.getFullYear(), doisDiasAtras.getMonth(), doisDiasAtras.getDate(), "2 dias atrás", doisDiasAtras.getDate() +
                            mesExtenso +
                            doisDiasAtras.getFullYear(), "ontemComparadoADoisDiasAtras");
                        break;
                    }
                    case "ontemComparadoAMesmoDiaDaSemanaAnterior": {
                        var primeiroDiaSemanaPassada = new Date(Date.now() - 86400000 * 8);
                        var ultimoDiaSemanaPassada = new Date(Date.now() - 86400000 * 8);
                        var mesExtenso = mesPorExtenso(primeiroDiaSemanaPassada.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaSemanaPassada.getFullYear(), primeiroDiaSemanaPassada.getMonth(), primeiroDiaSemanaPassada.getDate(), ultimoDiaSemanaPassada.getFullYear(), ultimoDiaSemanaPassada.getMonth(), ultimoDiaSemanaPassada.getDate(), "Mesmo dia da semana anterior", primeiroDiaSemanaPassada.getDate() +
                            mesExtenso +
                            primeiroDiaSemanaPassada.getFullYear(), "ontemComparadoAMesmoDiaDaSemanaAnterior");
                        break;
                    }
                    case "ontemComparadoAMesmoDiaDoMesAnterior": {
                        var ontem = new Date(Date.now() - 86400000);
                        var mesAtual = ontem.getMonth();
                        var mesExtenso = mesPorExtenso(mesAtual);
                        if (mesAtual + 1 == 2 ||
                            mesAtual + 1 == 4 ||
                            mesAtual + 1 == 6 ||
                            mesAtual + 1 == 8 ||
                            mesAtual + 1 == 9 ||
                            mesAtual + 1 == 11) {
                            // mes anterior com numero total de dias maior ou igual que o atual
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 1, ontem.getDate(), ontem.getFullYear(), mesAtual - 1, ontem.getDate(), "Mesmo dia do mês anterior", ontem.getDate() + mesExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                        }
                        else if (mesAtual + 1 == 5 ||
                            mesAtual + 1 == 7 ||
                            mesAtual + 1 == 10 ||
                            mesAtual + 1 == 12) {
                            // mes anterior com numero total de dias menor que o atual
                            if (ontem.getDate() == 31)
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 1, 30, ontem.getFullYear(), mesAtual - 1, 30, "Mesmo dia do mês anterior", 30 + mesExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                            else
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 1, ontem.getDate(), ontem.getFullYear(), mesAtual - 1, ontem.getDate(), "Mesmo dia do mês anterior", ontem.getDate() + mesExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                        }
                        else if (mesAtual + 1 == 1) {
                            // mes anterior sendo dezembro entao o ano e menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear() - 1, 12, ontem.getDate(), ontem.getFullYear() - 1, 12, ontem.getDate(), "Mesmo dia do mês anterior", ontem.getDate() + " dez," + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                        }
                        else {
                            // mes anterior sendo fevereiro entao pode ter 2 ou 3 dias a menos dependendo do ano ser bisexto
                            if (ontem.getFullYear() % 4 == 0) {
                                // ano bisexto
                                if (ontem.getDate() == 30 || ontem.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 1, 29, ontem.getFullYear(), mesAtual - 1, 29, "Mesmo dia do mês anterior", "29" + mesExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 1, ontem.getDate(), ontem.getFullYear(), mesAtual - 1, ontem.getDate(), "Mesmo dia do mês anterior", ontem.getDate() + mesExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                            }
                            else {
                                if (ontem.getDate() == 29 ||
                                    ontem.getDate() == 30 ||
                                    ontem.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 1, 28, ontem.getFullYear(), mesAtual - 1, 28, "Mesmo dia do mês anterior", "28" + mesExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 1, ontem.getDate(), ontem.getFullYear(), mesAtual - 1, ontem.getDate(), "Mesmo dia do mês anterior", ontem.getDate() + mesExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoMesAnterior");
                            }
                        }
                        break;
                    }
                    case "ontemComparadoAMesmoDiaDoTrimestreAnterior": {
                        var ontem = new Date(Date.now() - 86400000);
                        var mesAtual = ontem.getMonth();
                        var mesTrimAnteriorExtenso = mesPorExtenso(mesAtual - 2);
                        if (mesAtual + 1 == 1) {
                            // mes do trimestre anterior com mesmo numero de dias mas com ano menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear() - 1, 10, ontem.getDate(), ontem.getFullYear() - 1, 10, ontem.getDate(), "Mesmo dia do trimestre anterior", ontem.getDate() + " set, " + (ontem.getFullYear() - 1), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                        }
                        else if (mesAtual + 1 == 2) {
                            // mes do trimestre anterior com mesmo numero de dias mas com ano menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear() - 1, 11, ontem.getDate(), ontem.getFullYear() - 1, 11, ontem.getDate(), "Mesmo dia do trimestre anterior", ontem.getDate() + " out, " + (ontem.getFullYear() - 1), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                        }
                        else if (mesAtual + 1 == 3) {
                            // mes do trimestre anterior com mesmo numero de dias mas com ano menor
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear() - 1, 12, ontem.getDate(), ontem.getFullYear() - 1, 12, ontem.getDate(), "Mesmo dia do trimestre anterior", ontem.getDate() + " nov, " + (ontem.getFullYear() - 1), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                        }
                        else if (mesAtual + 1 == 4 ||
                            mesAtual + 1 == 6 ||
                            mesAtual + 1 == 8 ||
                            mesAtual + 1 == 9 ||
                            mesAtual + 1 == 10 ||
                            mesAtual + 1 == 11) {
                            $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 3, ontem.getDate(), ontem.getFullYear(), mesAtual - 3, ontem.getDate(), "Mesmo dia do trimestre anterior", ontem.getDate() +
                                mesTrimAnteriorExtenso +
                                (ontem.getFullYear() - 1), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                        }
                        else if (mesAtual + 1 == 7 || mesAtual + 1 == 12) {
                            if (ontem.getDate() == 31)
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 3, 30, ontem.getFullYear(), mesAtual - 3, 30, "Mesmo dia do trimestre anterior", "30" + mesTrimAnteriorExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                            else
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 3, ontem.getDate(), ontem.getFullYear(), mesAtual - 3, ontem.getDate(), "Mesmo dia do trimestre anterior", ontem.getDate() +
                                    mesTrimAnteriorExtenso +
                                    ontem.getFullYear(), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                        }
                        else {
                            // mes anterior sendo fevereiro entao pode ter 2 ou 3 dias a menos dependendo do ano ser bisexto
                            if (ontem.getFullYear() % 4 == 0) {
                                // ano bisexto
                                if (ontem.getDate() == 30 || ontem.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 3, 29, ontem.getFullYear(), mesAtual - 3, 29, "Mesmo dia do trimestre anterior", "29" + mesTrimAnteriorExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 3, ontem.getDate(), ontem.getFullYear(), mesAtual - 3, ontem.getDate(), "Mesmo dia do trimestre anterior", ontem.getDate() +
                                        mesTrimAnteriorExtenso +
                                        ontem.getFullYear(), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                            }
                            else {
                                if (ontem.getDate() == 29 ||
                                    ontem.getDate() == 30 ||
                                    ontem.getDate() == 31)
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 3, 28, ontem.getFullYear(), mesAtual - 3, 28, "Mesmo dia do trimestre anterior", "28" + mesTrimAnteriorExtenso + ontem.getFullYear(), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                                else
                                    $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear(), mesAtual - 3, ontem.getDate(), ontem.getFullYear(), mesAtual - 3, ontem.getDate(), "Mesmo dia do trimestre anterior", ontem.getDate() +
                                        mesTrimAnteriorExtenso +
                                        ontem.getFullYear(), "ontemComparadoAMesmoDiaDoTrimestreAnterior");
                            }
                        }
                        break;
                    }
                    case "ontemComparadoAMesmoDiaDoAnoAnterior": {
                        var ontem = new Date(Date.now() - 86400000);
                        var mesAtual = ontem.getMonth();
                        var mesExtenso = mesPorExtenso(mesAtual + 1);
                        // ano atual sendo bisexto, fevereiro do ano anterior nao tem o dia 29
                        if (ontem.getFullYear() % 4 == 0)
                            if (mesAtual == 2 && ontem == 29)
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear() - 1, mesAtual, 28, ontem.getFullYear() - 1, mesAtual, 28, "Mesmo dia do ano anterior", "28" + mesExtenso + (ontem.getFullYear() - 1), "ontemComparadoAMesmoDiaDoAnoAnterior");
                            else
                                $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(ontem.getFullYear() - 1, mesAtual, ontem.getDate(), ontem.getFullYear() - 1, mesAtual, ontem.getDate(), "Mesmo dia do ano anterior", ontem.getDate() + mesExtenso + (ontem.getFullYear() - 1), "ontemComparadoAMesmoDiaDoAnoAnterior");
                        break;
                    }
                    case "semanaAnteriorComparadoADuasSemanasAtras": {
                        var hoje = new Date();
                        var primeiroDiaDuasSemanasAtras = new Date(Date.now() - 86400000 * (hoje.getDay() + 14));
                        var ultimoDiaDuasSemanasAtras = new Date(primeiroDiaDuasSemanasAtras.getTime() + 86400000 * 6);
                        var semanaAnteriorExtenso = mesPorExtenso(ultimoDiaDuasSemanasAtras.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaDuasSemanasAtras.getFullYear(), primeiroDiaDuasSemanasAtras.getMonth(), primeiroDiaDuasSemanasAtras.getDate(), ultimoDiaDuasSemanasAtras.getFullYear(), ultimoDiaDuasSemanasAtras.getMonth(), ultimoDiaDuasSemanasAtras.getDate(), "2 semanas atrás", primeiroDiaDuasSemanasAtras.getDate() +
                            " a " +
                            ultimoDiaDuasSemanasAtras.getDate() +
                            semanaAnteriorExtenso +
                            ultimoDiaDuasSemanasAtras.getFullYear(), "semanaAnteriorComparadoADuasSemanasAtras");
                        break;
                    }
                    case "semanaAnteriorComparadoAMesmaSemanaDoMesAnterior": {
                        var hoje = new Date();
                        var hojeAux = hoje;
                        var contSemanas = 0;
                        var ultimoDomingo;
                        var diaSemana = hoje.getDay();
                        var diaMes = hoje.getDate();
                        // contagem de quantas semanas se passaram ate a semana atual
                        // um mes pode ter no maximo 6 semanas
                        for (var i = 0; i < 6; i++) {
                            contSemanas++;
                            ultimoDomingo = new Date(hojeAux.getTime() - 86400000 * diaSemana);
                            if (ultimoDomingo.getDate() > diaMes) {
                                i = 6;
                            }
                            else {
                                diaSemana = 7;
                                diaMes = ultimoDomingo.getDate();
                                hojeAux = ultimoDomingo;
                                if (diaMes == 1)
                                    i = 6;
                            }
                        }
                        // pegando uma semana anterior
                        contSemanas--;
                        // encontra qual e o primeiro dia do mes anterior
                        var mesAtual = hoje.getMonth() + 1;
                        if (mesAtual == 1) {
                            var primeiroDiaMesAnterior = new Date("12-01-" + (hoje.getFullYear() - 1));
                        }
                        else {
                            var primeiroDiaMesAnterior = new Date(mesAtual - 1 + "-01-" + hoje.getFullYear());
                        }
                        var diaSemana = primeiroDiaMesAnterior.getDay();
                        var diaMes = primeiroDiaMesAnterior.getDate();
                        var primeiroDiaDaSemanaAnteriorNoMesAnterior;
                        var ultimoDiaDaSemanaAnteriorNoMesAnterior;
                        // encontra qual e o primeiro domingo do mes anterior
                        var primeiroDomingoMesAnterior = new Date(primeiroDiaMesAnterior.getTime() - 86400000 * diaSemana);
                        // encontra qual e a semana que tem a mesma contagem da semana atual dentro do mes passado
                        for (var i = contSemanas; i >= 0; i--) {
                            primeiroDiaDaSemanaAnteriorNoMesAnterior =
                                primeiroDomingoMesAnterior;
                            ultimoDiaDaSemanaAnteriorNoMesAnterior = new Date(primeiroDiaDaSemanaAnteriorNoMesAnterior.getTime() +
                                86400000 * 6);
                            primeiroDomingoMesAnterior = new Date(primeiroDomingoMesAnterior.getTime() + 86400000 * 7);
                        }
                        var semanaMesAnteriorExtenso = mesPorExtenso(ultimoDiaDaSemanaAnteriorNoMesAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaDaSemanaAnteriorNoMesAnterior.getFullYear(), primeiroDiaDaSemanaAnteriorNoMesAnterior.getMonth(), primeiroDiaDaSemanaAnteriorNoMesAnterior.getDate(), ultimoDiaDaSemanaAnteriorNoMesAnterior.getFullYear(), ultimoDiaDaSemanaAnteriorNoMesAnterior.getMonth(), ultimoDiaDaSemanaAnteriorNoMesAnterior.getDate(), "Mesma semana do mês anterior", primeiroDiaDaSemanaAnteriorNoMesAnterior.getDate() +
                            " a " +
                            ultimoDiaDaSemanaAnteriorNoMesAnterior.getDate() +
                            semanaMesAnteriorExtenso +
                            ultimoDiaDaSemanaAnteriorNoMesAnterior.getFullYear(), "semanaAnteriorComparadoAMesmaSemanaDoMesAnterior");
                        break;
                    }
                    case "semanaAnteriorComparadoAMesmaSemanaDoTrimestreAnterior": {
                        var hoje = new Date();
                        var hojeAux = hoje;
                        var contSemanas = 0;
                        var ultimoDomingo;
                        var diaSemana = hoje.getDay();
                        var diaMes = hoje.getDate();
                        // contagem de quantas semanas se passaram ate a semana atual
                        // um mes pode ter no maximo 6 semanas
                        for (var i = 0; i < 6; i++) {
                            contSemanas++;
                            ultimoDomingo = new Date(hojeAux.getTime() - 86400000 * diaSemana);
                            if (ultimoDomingo.getDate() > diaMes) {
                                i = 6;
                            }
                            else {
                                diaSemana = 7;
                                diaMes = ultimoDomingo.getDate();
                                hojeAux = ultimoDomingo;
                                if (diaMes == 1)
                                    i = 6;
                            }
                        }
                        // pegando uma semana anterior
                        contSemanas--;
                        // encontra qual e o primeiro dia do mes equivalente no trimestre anterior
                        var mesAtual = hoje.getMonth() + 1;
                        if (mesAtual == 1) {
                            var primeiroDiaTrimestreAnterior = new Date("10-01-" + (hoje.getFullYear() - 1));
                        }
                        else if (mesAtual == 2) {
                            var primeiroDiaTrimestreAnterior = new Date("11-01-" + (hoje.getFullYear() - 1));
                        }
                        else if (mesAtual == 3) {
                            var primeiroDiaTrimestreAnterior = new Date("12-01-" + (hoje.getFullYear() - 1));
                        }
                        else {
                            var primeiroDiaTrimestreAnterior = new Date(mesAtual - 3 + "-01-" + hoje.getFullYear());
                        }
                        var diaSemana = primeiroDiaTrimestreAnterior.getDay();
                        var diaMes = primeiroDiaTrimestreAnterior.getDate();
                        var primeiroDiaDaSemanaAnteriorNoTrimestreAnterior;
                        var ultimoDiaDaSemanaAnteriorNoTrimestreAnterior;
                        // encontra qual e o primeiro domingo do mes equivalente no trimestre anterior
                        var primeiroDomingoTrimestreAnterior = new Date(primeiroDiaTrimestreAnterior.getTime() - 86400000 * diaSemana);
                        // encontra qual e a semana que tem a mesma contagem da semana atual dentro do trimestre passado
                        for (var i = contSemanas; i >= 0; i--) {
                            primeiroDiaDaSemanaAnteriorNoTrimestreAnterior =
                                primeiroDomingoTrimestreAnterior;
                            primeiroDomingoTrimestreAnterior = new Date(primeiroDomingoTrimestreAnterior.getTime() + 86400000 * 7);
                        }
                        ultimoDiaDaSemanaAnteriorNoTrimestreAnterior = new Date(primeiroDiaDaSemanaAnteriorNoTrimestreAnterior.getTime() +
                            86400000 * 6);
                        var semanaAnteriorExtenso = mesPorExtenso(ultimoDiaDaSemanaAnteriorNoTrimestreAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaDaSemanaAnteriorNoTrimestreAnterior.getFullYear(), primeiroDiaDaSemanaAnteriorNoTrimestreAnterior.getMonth(), primeiroDiaDaSemanaAnteriorNoTrimestreAnterior.getDate(), ultimoDiaDaSemanaAnteriorNoTrimestreAnterior.getFullYear(), ultimoDiaDaSemanaAnteriorNoTrimestreAnterior.getMonth(), ultimoDiaDaSemanaAnteriorNoTrimestreAnterior.getDate(), "Mesma semana do trimestre anterior", primeiroDiaDaSemanaAnteriorNoTrimestreAnterior.getDate() +
                            " a " +
                            ultimoDiaDaSemanaAnteriorNoTrimestreAnterior.getDate() +
                            semanaAnteriorExtenso +
                            ultimoDiaDaSemanaAnteriorNoTrimestreAnterior.getFullYear(), "semanaAnteriorComparadoAMesmaSemanaDoTrimestreAnterior");
                        break;
                    }
                    case "semanaAnteriorComparadoAMesmaSemanaDoAnoAnterior": {
                        var hoje = new Date();
                        var hojeAux = hoje;
                        var contSemanas = 0;
                        var ultimoDomingo;
                        var diaSemana = hoje.getDay();
                        var diaMes = hoje.getDate();
                        // contagem de quantas semanas se passaram ate a semana atual
                        // um mes pode ter no maximo 6 semanas
                        for (var i = 0; i < 6; i++) {
                            contSemanas++;
                            ultimoDomingo = new Date(hojeAux.getTime() - 86400000 * diaSemana);
                            if (ultimoDomingo.getDate() > diaMes) {
                                i = 6;
                            }
                            else {
                                diaSemana = 7;
                                diaMes = ultimoDomingo.getDate();
                                hojeAux = ultimoDomingo;
                                if (diaMes == 1)
                                    i = 6;
                            }
                        }
                        // pegando uma semana anterior
                        contSemanas--;
                        // encontra qual e o primeiro dia do mesmo mes no ano anterior
                        var mesAtual = hoje.getMonth() + 1;
                        var primeiroDiaMesmoMesDoAnoAnterior = new Date(mesAtual + "-01-" + (hoje.getFullYear() - 1));
                        var diaSemana = primeiroDiaMesmoMesDoAnoAnterior.getDay();
                        var diaMes = primeiroDiaMesmoMesDoAnoAnterior.getDate();
                        var primeiroDiaDaSemanaAnteriorNoAnoAnterior;
                        var ultimoDiaDaSemanaAnteriorNoAnoAnterior;
                        // encontra qual e o primeiro domingo do mesmo mes no ano anterior
                        var primeiroDomingoMesmoMesDoAnoAnterior = new Date(primeiroDiaMesmoMesDoAnoAnterior.getTime() - 86400000 * diaSemana);
                        // encontra qual e a semana que tem a mesma contagem da semana atual dentro do ano passado
                        for (var i = contSemanas; i >= 0; i--) {
                            primeiroDiaDaSemanaAnteriorNoAnoAnterior =
                                primeiroDomingoMesmoMesDoAnoAnterior;
                            primeiroDomingoMesmoMesDoAnoAnterior = new Date(primeiroDomingoMesmoMesDoAnoAnterior.getTime() + 86400000 * 7);
                        }
                        ultimoDiaDaSemanaAnteriorNoAnoAnterior = new Date(primeiroDiaDaSemanaAnteriorNoAnoAnterior.getTime() + 86400000 * 6);
                        var semanaAnteriorExtenso = mesPorExtenso(ultimoDiaDaSemanaAnteriorNoAnoAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaDaSemanaAnteriorNoAnoAnterior.getFullYear(), primeiroDiaDaSemanaAnteriorNoAnoAnterior.getMonth(), primeiroDiaDaSemanaAnteriorNoAnoAnterior.getDate(), ultimoDiaDaSemanaAnteriorNoAnoAnterior.getFullYear(), ultimoDiaDaSemanaAnteriorNoAnoAnterior.getMonth(), ultimoDiaDaSemanaAnteriorNoAnoAnterior.getDate(), "Mesma semana do ano anterior", primeiroDiaDaSemanaAnteriorNoAnoAnterior.getDate() +
                            " a " +
                            ultimoDiaDaSemanaAnteriorNoAnoAnterior.getDate() +
                            semanaAnteriorExtenso +
                            ultimoDiaDaSemanaAnteriorNoAnoAnterior.getFullYear(), "semanaAnteriorComparadoAMesmaSemanaDoAnoAnterior");
                        break;
                    }
                    case "mesAnteriorComparadoADoisMesesAtras": {
                        var hoje = new Date();
                        var primeiroDiaDoisMesesAtras = new Date(hoje.getFullYear(), hoje.getMonth() - 2, 1);
                        // Passando 0 no dia, a data é automaticamente passada para o último dia do mês anterior
                        var ultimoDiaDoisMesesAtras = new Date(hoje.getFullYear(), hoje.getMonth() - 1, 0);
                        var mesAnteriorExtenso = mesPorExtenso(ultimoDiaDoisMesesAtras.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaDoisMesesAtras.getFullYear(), primeiroDiaDoisMesesAtras.getMonth(), primeiroDiaDoisMesesAtras.getDate(), ultimoDiaDoisMesesAtras.getFullYear(), ultimoDiaDoisMesesAtras.getMonth(), ultimoDiaDoisMesesAtras.getDate(), "2 meses atrás", "01 a " +
                            ultimoDiaDoisMesesAtras.getDate() +
                            mesAnteriorExtenso +
                            ultimoDiaDoisMesesAtras.getFullYear(), "mesAnteriorComparadoADoisMesesAtras");
                        break;
                    }
                    case "mesAnteriorComparadoAMesmoMesDoTrimestreAnterior": {
                        var hoje = new Date();
                        var diaMesAux = 31;
                        var anoAtual = hoje.getFullYear();
                        var mesAnterior = hoje.getMonth();
                        if (hoje.getMonth() == 0)
                            mesAnterior = 12;
                        // verificando qual e o mes atual pois dependendo do mes o equivalente do
                        // trimestre anterior pode nao ter o mesmo numero de dias, exemplo
                        // dezembro com 31 dias e setembro, o seu equivalente com 30 dias
                        if (mesAnterior == 7 || mesAnterior == 12) {
                            diaMesAux = 30;
                        }
                        if (mesAnterior == 5) {
                            // comparacao do mes de maio com fevereiro que pode ter 28 ou 29 dias
                            if (anoAtual % 4 == 0) {
                                diaMesAux = 29;
                            }
                            else {
                                diaMesAux = 28;
                            }
                        }
                        if (mesAnterior == 1) {
                            var primeiroDiaMesAnteriorDoTrimestreAnterior = new Date("10-01-" + (anoAtual - 1));
                            var ultimoDiaMesAnteriorDoTrimestreAnterior = new Date(new Date("10-" + diaMesAux + "-" + (anoAtual - 1)).getTime() -
                                86400000);
                        }
                        else if (mesAnterior == 2) {
                            var primeiroDiaMesAnteriorDoTrimestreAnterior = new Date("11-01-" + (anoAtual - 1));
                            var ultimoDiaMesAnteriorDoTrimestreAnterior = new Date(new Date("11-" + diaMesAux + "-" + (anoAtual - 1)).getTime() -
                                86400000);
                        }
                        else if (mesAnterior == 3) {
                            var primeiroDiaMesAnteriorDoTrimestreAnterior = new Date("12-01-" + (anoAtual - 1));
                            var ultimoDiaMesAnteriorDoTrimestreAnterior = new Date(new Date("12-" + diaMesAux + "-" + (anoAtual - 1)).getTime() -
                                86400000);
                        }
                        else {
                            var primeiroDiaMesAnteriorDoTrimestreAnterior = new Date(mesAnterior - 3 + "-01-" + anoAtual);
                            var ultimoDiaMesAnteriorDoTrimestreAnterior = new Date(new Date(mesAnterior - 3 + "-" + diaMesAux + "-" + anoAtual).getTime() - 86400000);
                        }
                        var mesTrimAnteriorExtenso = mesPorExtenso(ultimoDiaMesAnteriorDoTrimestreAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesAnteriorDoTrimestreAnterior.getFullYear(), primeiroDiaMesAnteriorDoTrimestreAnterior.getMonth(), primeiroDiaMesAnteriorDoTrimestreAnterior.getDate(), ultimoDiaMesAnteriorDoTrimestreAnterior.getFullYear(), ultimoDiaMesAnteriorDoTrimestreAnterior.getMonth(), ultimoDiaMesAnteriorDoTrimestreAnterior.getDate(), "Mesmo mês do trimestre anterior", "01 a " +
                            ultimoDiaMesAnteriorDoTrimestreAnterior.getDate() +
                            mesTrimAnteriorExtenso +
                            ultimoDiaMesAnteriorDoTrimestreAnterior.getFullYear(), "mesAnteriorComparadoAMesmoMesDoTrimestreAnterior");
                        break;
                    }
                    case "mesAnteriorComparadoAMesmoMesDoAnoAnterior": {
                        var hoje = new Date();
                        var diaMesAux = 31;
                        var anoAtual = hoje.getFullYear();
                        var mesAnterior = hoje.getMonth();
                        if (hoje.getMonth() == 0) {
                            mesAnterior = 12;
                            anoAtual--;
                        }
                        if (mesAnterior == 2 && anoAtual % 4 == 0) {
                            var primeiroDiaMesAnteriorDoAnoAnterior = new Date(mesAnterior + "-01-" + (anoAtual - 1));
                            var ultimoDiaMesAnteriorDoAnoAnterior = new Date(new Date(mesAnterior + "-28-" + (anoAtual - 1)).getTime() -
                                86400000);
                        }
                        else {
                            var primeiroDiaMesAnteriorDoAnoAnterior = new Date(mesAnterior + "-01-" + (anoAtual - 1));
                            var ultimoDiaMesAnteriorDoAnoAnterior = new Date(new Date(mesAnterior + "-" + diaMesAux + "-" + (anoAtual - 1)).getTime() - 86400000);
                        }
                        var mesAnoAnteriorExtenso = mesPorExtenso(ultimoDiaMesAnteriorDoAnoAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaMesAnteriorDoAnoAnterior.getFullYear(), primeiroDiaMesAnteriorDoAnoAnterior.getMonth(), primeiroDiaMesAnteriorDoAnoAnterior.getDate(), ultimoDiaMesAnteriorDoAnoAnterior.getFullYear(), ultimoDiaMesAnteriorDoAnoAnterior.getMonth(), ultimoDiaMesAnteriorDoAnoAnterior.getDate(), "Mesmo mês do ano anterior", "01 a " +
                            ultimoDiaMesAnteriorDoAnoAnterior.getDate() +
                            mesAnoAnteriorExtenso +
                            ultimoDiaMesAnteriorDoAnoAnterior.getFullYear(), "mesAnteriorComparadoAMesmoMesDoAnoAnterior");
                        break;
                    }
                    case "trimestreAnteriorComparadoADoisTrimestresAtras": {
                        var hoje = new Date();
                        var diaMesAux = 31;
                        var anoAtual = hoje.getFullYear();
                        var mesAtual = hoje.getMonth() + 1;
                        var primeiroMesTrimestreAnterior;
                        var trimestreAnteriorEstaNoAnoPassado = false;
                        var doisTrimestresAntesEstaNoAnoPassado = false;
                        // encontrando qual e o primeiro mes do trimestre anterior
                        if (mesAtual == 1 || mesAtual == 2 || mesAtual == 3) {
                            primeiroMesTrimestreAnterior = 10;
                            trimestreAnteriorEstaNoAnoPassado = true;
                            doisTrimestresAntesEstaNoAnoPassado = true;
                        }
                        if (mesAtual == 4 || mesAtual == 5 || mesAtual == 6) {
                            primeiroMesTrimestreAnterior = 1;
                            doisTrimestresAntesEstaNoAnoPassado = true;
                        }
                        if (mesAtual == 7 || mesAtual == 8 || mesAtual == 9) {
                            primeiroMesTrimestreAnterior = 4;
                        }
                        if (mesAtual == 10 || mesAtual == 11 || mesAtual == 12) {
                            primeiroMesTrimestreAnterior = 7;
                        }
                        // verificando qual e o mes anterior pois dependendo do mes o equivalente do
                        // trimestre anterior pode nao ter o mesmo numero de dias, exemplo
                        // dezembro com 31 dias e setembro, o seu equivalente com 30 dias
                        if (mesAtual == 7 || mesAtual == 12) {
                            diaMesAux = 30;
                        }
                        if (mesAtual == 5) {
                            // comparacao do mes de maio com fevereiro que pode ter 28 ou 29 dias
                            if (anoAtual % 4 == 0) {
                                diaMesAux = 29;
                            }
                            else {
                                diaMesAux = 28;
                            }
                        }
                        var primeiroDiaDoisTrimestreAtras;
                        var ultimoDiaDoisTrimestreAtras;
                        // encontrando qual e o primerio mes do trimestre anterior ao anterior (2 atras)
                        if (doisTrimestresAntesEstaNoAnoPassado &&
                            trimestreAnteriorEstaNoAnoPassado) {
                            primeiroDiaDoisTrimestreAtras = new Date(primeiroMesTrimestreAnterior - 3 + "-01-" + (anoAtual - 1));
                            ultimoDiaDoisTrimestreAtras = new Date(new Date(primeiroMesTrimestreAnterior -
                                1 +
                                "-" +
                                diaMesAux +
                                "-" +
                                (anoAtual - 1)).getTime() - 86400000);
                        }
                        else if (doisTrimestresAntesEstaNoAnoPassado &&
                            !trimestreAnteriorEstaNoAnoPassado) {
                            primeiroDiaDoisTrimestreAtras = new Date(primeiroMesTrimestreAnterior + 9 + "-01-" + (anoAtual - 1));
                            ultimoDiaDoisTrimestreAtras = new Date(new Date(primeiroMesTrimestreAnterior +
                                11 +
                                "-" +
                                diaMesAux +
                                "-" +
                                (anoAtual - 1)).getTime() - 86400000);
                        }
                        else {
                            primeiroDiaDoisTrimestreAtras = new Date(primeiroMesTrimestreAnterior - 3 + "-01-" + anoAtual);
                            ultimoDiaDoisTrimestreAtras = new Date(new Date(primeiroMesTrimestreAnterior -
                                1 +
                                "-" +
                                diaMesAux +
                                "-" +
                                anoAtual).getTime() - 86400000);
                        }
                        var mesTriAnteriorExtensoUm = mesPorExtenso(primeiroDiaDoisTrimestreAtras.getMonth() + 1);
                        mesTriAnteriorExtensoUm = mesTriAnteriorExtensoUm.replace(", ", "");
                        var mesTriAnteriorExtensoDois = mesPorExtenso(ultimoDiaDoisTrimestreAtras.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaDoisTrimestreAtras.getFullYear(), primeiroDiaDoisTrimestreAtras.getMonth(), primeiroDiaDoisTrimestreAtras.getDate(), ultimoDiaDoisTrimestreAtras.getFullYear(), ultimoDiaDoisTrimestreAtras.getMonth(), ultimoDiaDoisTrimestreAtras.getDate(), "2 trimestres atrás", "01" +
                            mesTriAnteriorExtensoUm +
                            " a " +
                            ultimoDiaDoisTrimestreAtras.getDate() +
                            mesTriAnteriorExtensoDois +
                            ultimoDiaDoisTrimestreAtras.getFullYear(), "trimestreAnteriorComparadoADoisTrimestresAtras");
                        break;
                    }
                    case "trimestreAnteriorComparadoAMesmoTrimestreDoAnoPassado": {
                        var hoje = new Date();
                        var diaMesAux = 31;
                        var anoAtual = hoje.getFullYear();
                        var mesAtual = hoje.getMonth() + 1;
                        var primeiroMesTrimestreAnterior;
                        var trimestreAnteriorEstaNoAnoPassado = false;
                        // encontrando qual e o primeiro mes do trimestre anterior
                        if (mesAtual == 1 || mesAtual == 2 || mesAtual == 3) {
                            primeiroMesTrimestreAnterior = 10;
                            trimestreAnteriorEstaNoAnoPassado = true;
                            anoAtual--;
                        }
                        if (mesAtual == 4 || mesAtual == 5 || mesAtual == 6) {
                            primeiroMesTrimestreAnterior = 1;
                        }
                        if (mesAtual == 7 || mesAtual == 8 || mesAtual == 9) {
                            primeiroMesTrimestreAnterior = 4;
                        }
                        if (mesAtual == 10 || mesAtual == 11 || mesAtual == 12) {
                            primeiroMesTrimestreAnterior = 7;
                        }
                        // verificando se o mes atual e setembro ou dezembro para compensar o dia a menos que
                        // junho e setembro, finais do trimestre anterior, teriam
                        if (mesAtual == 9 || mesAtual == 12) {
                            diaMesAux = 30;
                        }
                        var primeiroDiaTrimestreAnteriorDoAnoAnterior = new Date(primeiroMesTrimestreAnterior + "-01-" + (anoAtual - 1));
                        var ultimoDiaTrimestreAnteriorDoAnoAnterior = new Date(new Date(primeiroMesTrimestreAnterior +
                            2 +
                            "-" +
                            diaMesAux +
                            "-" +
                            (anoAtual - 1)).getTime() - 86400000);
                        var mesAnoAnteriorExtensoUm = mesPorExtenso(primeiroDiaTrimestreAnteriorDoAnoAnterior.getMonth() + 1);
                        mesAnoAnteriorExtensoUm = mesAnoAnteriorExtensoUm.replace(", ", "");
                        var mesAnoAnteriorExtensoDois = mesPorExtenso(ultimoDiaTrimestreAnteriorDoAnoAnterior.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaTrimestreAnteriorDoAnoAnterior.getFullYear(), primeiroDiaTrimestreAnteriorDoAnoAnterior.getMonth(), primeiroDiaTrimestreAnteriorDoAnoAnterior.getDate(), ultimoDiaTrimestreAnteriorDoAnoAnterior.getFullYear(), ultimoDiaTrimestreAnteriorDoAnoAnterior.getMonth(), ultimoDiaTrimestreAnteriorDoAnoAnterior.getDate(), "Mesmo trimestre do ano passado", "01" +
                            mesAnoAnteriorExtensoUm +
                            " a " +
                            ultimoDiaTrimestreAnteriorDoAnoAnterior.getDate() +
                            mesAnoAnteriorExtensoDois +
                            ultimoDiaTrimestreAnteriorDoAnoAnterior.getFullYear(), "trimestreAnteriorComparadoAMesmoTrimestreDoAnoPassado");
                        break;
                    }
                    case "anoAnteriorComparadoADoisAtras": {
                        var hoje = new Date();
                        var doisAnosAtras = hoje.getFullYear() - 2;
                        var primeiroDiaDeDoisAnosAtras = new Date("01-01-" + doisAnosAtras);
                        var ultimoDiaDeDoisAnosAtras = new Date("12-31-" + doisAnosAtras);
                        var mesExtenso = mesPorExtenso(ultimoDiaDeDoisAnosAtras.getMonth() + 1);
                        $scope.filtroComparadoA = $scope.montarDataFiltrosPeriodos(primeiroDiaDeDoisAnosAtras.getFullYear(), primeiroDiaDeDoisAnosAtras.getMonth(), primeiroDiaDeDoisAnosAtras.getDate(), ultimoDiaDeDoisAnosAtras.getFullYear(), ultimoDiaDeDoisAnosAtras.getMonth(), ultimoDiaDeDoisAnosAtras.getDate(), "2 anos atrás", "01 jan a " +
                            ultimoDiaDeDoisAnosAtras.getDate() +
                            mesExtenso +
                            ultimoDiaDeDoisAnosAtras.getFullYear(), "anoAnteriorComparadoADoisAtras");
                        break;
                    }
                    default:
                        break;
                }
            }
            else {
                // else para caso queira desmarcar a opção que está marcada
                $(obj).removeClass("option-checked");
                $(img).addClass("invisible");
                $scope.selecionadoComparadoA = "";
                $scope.filtroComparadoA = null;
            }
        };
        $scope.montarDataFiltrosPeriodos = function (anoInicial, mesInicial, diaInicial, anoFinal, mesFinal, diaFinal, nome, titulo, idFiltro) {
            var mesFormatadoInicial = "01";
            var mesFormatadoFinal = "01";
            var diaFormatadoInicial = "01";
            var diaFormatadoFinal = "01";
            if (mesInicial + 1 < 10)
                mesFormatadoInicial = "0" + (mesInicial + 1);
            else
                mesFormatadoInicial = mesInicial + 1;
            if (mesFinal + 1 < 10)
                mesFormatadoFinal = "0" + (mesFinal + 1);
            else
                mesFormatadoFinal = mesFinal + 1;
            if (diaInicial < 10)
                diaFormatadoInicial = "0" + diaInicial;
            else
                diaFormatadoInicial = diaInicial;
            if (diaFinal < 10)
                diaFormatadoFinal = "0" + diaFinal;
            else
                diaFormatadoFinal = diaFinal;
            return {
                _id: idFiltro,
                anoInicial: anoInicial,
                mesInicial: mesInicial,
                diaInicial: diaInicial,
                anoFinal: anoFinal,
                mesFinal: mesFinal,
                diaFinal: diaFinal,
                nome: nome,
                titulo: titulo,
                dataInicial: "'" +
                    anoInicial +
                    "-" +
                    mesFormatadoInicial +
                    "-" +
                    diaFormatadoInicial +
                    "'",
                dataFinal: "'" +
                    anoFinal +
                    "-" +
                    mesFormatadoFinal +
                    "-" +
                    diaFormatadoFinal +
                    "'",
            };
        };
        function montarFiltroFront() {
            $http({
                method: "GET",
                url: "/relatorio/montarFiltroFront",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (response) {
                for (var i = 0; i < response.data.oid.length; i++) {
                    if (response.data.oid[i].tipo !== "V") {
                        $scope.usuariosTime.push({
                            id: "usuarios_id" + i,
                            nome: response.data.oid[i].nome,
                            sobrenome: response.data.oid[i].sobrenome,
                            objectIdLogin: response.data.oid[i].objectIdLogin,
                        });
                    }
                }
                for (var i = 0; i < response.data.cadencias.length; i++) {
                    $scope.cadenciasTime.push({
                        id: "cadencias_id" + i,
                        idCadencia: response.data.cadencias[i].idCadencia,
                        nomeCadencia: response.data.cadencias[i].nomeCadencia,
                    });
                }
                for (var i = 0; i < response.data.segmentacoes.length; i++) {
                    $scope.segmentacoesTime.push({
                        id: "segmentacoes_id" + i,
                        idSegmentacao: response.data.segmentacoes[i].idSegmentacao,
                        nomeSegmentacao: response.data.segmentacoes[i].nomeSegmentacao,
                    });
                }
                $scope.timeRanking = [];
                for (var i = 0; i < $scope.usuariosTime.length; i++) {
                    $scope.timeRanking.push({
                        _id: $scope.usuariosTime[i].id,
                        objectIdLogin: $scope.usuariosTime[i].objectIdLogin,
                    });
                }
                $scope.timeCadencia = [];
                for (var i = 0; i < $scope.cadenciasTime.length; i++) {
                    $scope.timeCadencia.push({
                        _id: $scope.cadenciasTime[i].id,
                        objectIdDisparo: $scope.cadenciasTime[i].objectIdDisparo,
                    });
                }
                montarListaUsuarios();
                ordenaFiltroUsuarios();
                ordenaFiltroCadencia();
                ordenaFiltroSegmentacao();
                return $scope.usuariosTime;
            });
        }
        function montarListaUsuarios() {
            for (var i = 0; i < $scope.usuariosTime.length; i++) {
                $scope.listaUsuarios.push({
                    nome: $scope.usuariosTime[i].nome,
                    sobrenome: $scope.usuariosTime[i].sobrenome,
                    objectIdLogin: $scope.usuariosTime[i].objectIdLogin,
                    id: $scope.usuariosTime[i].id,
                    aparece: false,
                    params: {},
                });
            }
            angular.element(function () {
                setTimeout(function () {
                    $scope.selecionarUsuarios("TODOS");
                }, 1000);
            });
        }
        $scope.selecionarUsuarios = function (user, id) {
            if (user == "TODOS") {
                for (var i = 0; i < $scope.usuariosTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.usuariosTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if (!$(obj).hasClass("option-checked")) {
                        $(obj).addClass("option-checked");
                        $(img).removeClass("invisible");
                        filtroUsuarios.push({
                            _id: $scope.usuariosTime[i].id,
                            objectIdLogin: $scope.usuariosTime[i].objectIdLogin,
                            periodoInicial: 0,
                            periodoComparacao: 0,
                        });
                        $scope.usuariosSelecionados += 1;
                        for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                            if ($scope.usuariosTime[i].id == $scope.listaUsuarios[j].id) {
                                $scope.listaUsuarios[j].aparece = true;
                            }
                        }
                    }
                }
            }
            else if (user == "DESMARCAR") {
                for (var i = 0; i < $scope.usuariosTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.usuariosTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if ($(obj).hasClass("option-checked")) {
                        $(obj).removeClass("option-checked");
                        $(img).addClass("invisible");
                        filtroUsuarios.splice(0, $scope.usuariosTime.length);
                        $scope.usuariosSelecionados -= 1;
                        for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                            if ($scope.usuariosTime[i].id == $scope.listaUsuarios[j].id) {
                                $scope.listaUsuarios[j].aparece = false;
                            }
                        }
                    }
                }
            }
            else {
                var obj, img;
                obj = document.getElementById(id);
                img = obj.getElementsByTagName("img");
                if (!$(obj).hasClass("option-checked")) {
                    $(obj).addClass("option-checked");
                    $(img).removeClass("invisible");
                    filtroUsuarios.push({
                        _id: id,
                        objectIdLogin: user,
                        periodoInicial: 0,
                        periodoComparacao: 0,
                    });
                    $scope.usuariosSelecionados += 1;
                    for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                        if (id == $scope.listaUsuarios[j].id) {
                            $scope.listaUsuarios[j].aparece = true;
                        }
                    }
                }
                else {
                    var index;
                    for (var i = 0; i < filtroUsuarios.length; i++) {
                        if (filtroUsuarios[i]._id === id) {
                            index = i;
                            filtroUsuarios.splice(index, 1);
                            $(obj).removeClass("option-checked");
                            $(img).addClass("invisible");
                            $scope.usuariosSelecionados -= 1;
                            for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                                if (id == $scope.listaUsuarios[j].id) {
                                    $scope.listaUsuarios[j].aparece = false;
                                }
                            }
                        }
                    }
                }
            }
            $scope.ordenarTabela($scope.ordenacaoEscolhida, false);
        };
        $scope.selecionarCadencias = function (cad, id) {
            if (cad == "TODOS") {
                for (var i = 0; i < $scope.cadenciasTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.cadenciasTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if (!$(obj).hasClass("option-checked")) {
                        $(obj).addClass("option-checked");
                        $(img).removeClass("invisible");
                        filtroCadencias.push({
                            _id: $scope.cadenciasTime[i].id,
                            objectIdDisparo: $scope.cadenciasTime[i].idCadencia,
                        });
                        $scope.cadenciasSelecionadas += 1;
                    }
                }
            }
            else if (cad == "DESMARCAR") {
                for (var i = 0; i < $scope.cadenciasTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.cadenciasTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if ($(obj).hasClass("option-checked")) {
                        $(obj).removeClass("option-checked");
                        $(img).addClass("invisible");
                        filtroCadencias.splice(0, $scope.cadenciasTime.length);
                        $scope.cadenciasSelecionadas -= 1;
                    }
                }
            }
            else {
                var obj, img;
                obj = document.getElementById(id);
                img = obj.getElementsByTagName("img");
                if (!$(obj).hasClass("option-checked")) {
                    $(obj).addClass("option-checked");
                    $(img).removeClass("invisible");
                    filtroCadencias.push({
                        _id: id,
                        objectIdDisparo: cad,
                    });
                    $scope.cadenciasSelecionadas += 1;
                }
                else {
                    var index;
                    for (var i = 0; i < filtroCadencias.length; i++) {
                        if (filtroCadencias[i]._id === id) {
                            index = i;
                            filtroCadencias.splice(index, 1);
                            $(obj).removeClass("option-checked");
                            $(img).addClass("invisible");
                            $scope.cadenciasSelecionadas -= 1;
                            return "cadencia retirada";
                        }
                    }
                }
            }
        };
        $scope.selecionarSegmentacao = function (seg, id) {
            if (seg === "TODOS") {
                for (var i = 0; i < $scope.segmentacoesTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.segmentacoesTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if (!$(obj).hasClass("option-checked")) {
                        $(obj).addClass("option-checked");
                        $(img).removeClass("invisible");
                        filtroSegmentacoes.push({
                            _id: $scope.segmentacoesTime[i].id,
                            objectIdSegmentacao: $scope.segmentacoesTime[i].idSegmentacao,
                        });
                        $scope.segmentacoesSelecionadas += 1;
                    }
                }
            }
            else if (seg === "DESMARCAR") {
                for (var i = 0; i < $scope.segmentacoesTime.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.segmentacoesTime[i].id);
                    img = obj.getElementsByTagName("img");
                    if ($(obj).hasClass("option-checked")) {
                        $(obj).removeClass("option-checked");
                        $(img).addClass("invisible");
                        filtroSegmentacoes.splice(0, $scope.segmentacoesTime.length);
                        $scope.segmentacoesSelecionadas -= 1;
                    }
                }
            }
            else {
                var obj, img;
                obj = document.getElementById(id);
                img = obj.getElementsByTagName("img");
                if (!$(obj).hasClass("option-checked")) {
                    $(obj).addClass("option-checked");
                    $(img).removeClass("invisible");
                    filtroSegmentacoes.push({
                        _id: id,
                        objectIdSegmentacao: seg,
                    });
                    $scope.segmentacoesSelecionadas += 1;
                }
                else {
                    var index;
                    for (var i = 0; i < filtroSegmentacoes.length; i++) {
                        if (filtroSegmentacoes[i]._id === id) {
                            index = i;
                            filtroSegmentacoes.splice(index, 1);
                            $(obj).removeClass("option-checked");
                            $(img).addClass("invisible");
                            $scope.segmentacoesSelecionadas -= 1;
                            return "segmentacao retirada";
                        }
                    }
                }
            }
        };
        $scope.selecionarParametros = function (param) {
            if (param == "TODOS") {
                for (var i = 0; i < $scope.todosParametros.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.todosParametros[i].code);
                    img = obj.getElementsByTagName("img");
                    objNovo = document.getElementById("novo" + $scope.todosParametros[i].code);
                    imgNovo = objNovo.getElementsByTagName("img");
                    if (!$(obj).hasClass("option-checked")) {
                        $(obj).addClass("option-checked");
                        $(img).removeClass("invisible");
                        $(objNovo).addClass("option-checked");
                        $(imgNovo).removeClass("invisible");
                        $scope.filtroParametros.push({
                            nome: $scope.todosParametros[i].nome,
                            code: $scope.todosParametros[i].code,
                            order: $scope.todosParametros[i].order,
                            loader: false,
                        });
                        $scope.parametrosSelecionados += 1;
                    }
                }
            }
            else if (param == "DESMARCAR") {
                for (var i = 0; i < $scope.todosParametros.length; i++) {
                    var obj, img;
                    obj = document.getElementById($scope.todosParametros[i].code);
                    img = obj.getElementsByTagName("img");
                    objNovo = document.getElementById("novo" + $scope.todosParametros[i].code);
                    imgNovo = objNovo.getElementsByTagName("img");
                    if ($(obj).hasClass("option-checked")) {
                        $(obj).removeClass("option-checked");
                        $(img).addClass("invisible");
                        $(objNovo).removeClass("option-checked");
                        $(imgNovo).addClass("invisible");
                        $scope.filtroParametros.splice(0, $scope.todosParametros.length);
                        for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                            $scope.listaUsuarios[j].params = {};
                        }
                        $scope.parametrosSelecionados -= 1;
                    }
                }
            }
            else {
                var obj, img;
                obj = document.getElementById(param.code);
                img = obj.getElementsByTagName("img");
                objNovo = document.getElementById("novo" + param.code);
                imgNovo = objNovo.getElementsByTagName("img");
                if (!$(obj).hasClass("option-checked")) {
                    $(obj).addClass("option-checked");
                    $(img).removeClass("invisible");
                    $(objNovo).addClass("option-checked");
                    $(imgNovo).removeClass("invisible");
                    $scope.filtroParametros.push({
                        nome: param.nome,
                        code: param.code,
                        order: param.order,
                        loader: false,
                    });
                    $scope.parametrosSelecionados += 1;
                }
                else {
                    var index;
                    for (var i = 0; i < $scope.filtroParametros.length; i++) {
                        if ($scope.filtroParametros[i].code == param.code) {
                            index = i;
                            $scope.filtroParametros.splice(index, 1);
                            for (var j = 0; j < $scope.listaUsuarios.length; j++) {
                                delete $scope.listaUsuarios[j].params[param.code];
                            }
                            $(obj).removeClass("option-checked");
                            $(img).addClass("invisible");
                            $(objNovo).removeClass("option-checked");
                            $(imgNovo).addClass("invisible");
                            $scope.parametrosSelecionados -= 1;
                        }
                    }
                }
            }
            ordenaParametros();
        };
        function ordenaParametros() {
            $scope.filtroParametros.sort(function (paramA, paramB) {
                if (paramA.order > paramB.order)
                    return 1;
                else
                    return -1;
            });
        }
        $scope.filtrar = function () {
            $scope.salvarParamsTela();
            refresh();
            $scope.mostrarTabela = true;
        };
        $scope.limparFiltro = function () {
            $scope.mostrarTabela = false;
            $scope.selecionarPeriodoInicial('periodoInicialEsteMes');
            $scope.selecionarPeriodoComparacao('esteMesComparadoAMesAnterior');
            $scope.selecionarSegmentacao('DESMARCAR');
            $scope.selecionarCadencias('DESMARCAR');
            $scope.selecionarUsuarios('TODOS');
        };
        $scope.abrirPopupExportacao = function () {
            $scope.popupAberto = true;
            exportarRelatorioCSV();
        };
        function exportarRelatorioCSV() {
            if ($scope.popupAberto) {
                $scope.popupGerarCSV = true;
                $scope.escolhaExportacao = true;
                $scope.loaderAtivo = true;
                $("#idFundoGerarCSV").fadeIn();
                if ($scope.filtroParametros.length == 0) {
                    $scope.loaderAtivo = false;
                }
                else {
                    setTimeout(function () {
                        verificaLoaderAtivo();
                    }, 2000);
                }
            }
        }
        function verificaLoaderAtivo() {
            for (var i = 0; i < $scope.filtroParametros.length; i++) {
                if ($scope.filtroParametros[i].loader === true) {
                    exportarRelatorioCSV();
                    return;
                }
            }
            $scope.loaderAtivo = false;
            $scope.$apply();
        }
        $scope.fecharPopupGerarCSV = function () {
            $scope.popupGerarCSV = false;
            $scope.baixarExportacao = false;
            $scope.escolhaExportacao = false;
            $scope.enviarEmailExportacao = false;
            $scope.baixarExportacaoConcluida = false;
            $scope.popupAberto = false;
            $("#idFundoPopupContatos").fadeOut();
        };
        $scope.fazerDownloadArquivo = function () {
            window.open($scope.linkParaDownload, "_blank");
            $scope.baixarExportacao = false;
            $scope.baixarExportacaoConcluida = true;
        };
        $scope.baixarDados = function () {
            $scope.baixarExportacaoConcluida = false;
            $scope.escolhaExportacao = false;
            $scope.enviarEmailExportacao = false;
            $http({
                method: "DELETE",
                url: "/alerta/removerLinkAnaliseComparativaExportados",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    assunto: "Analise_Comparativa_Exportada",
                },
            }).then(function (response) {
                $scope.exportarDados(false);
                $scope.processandoArquivoParaDownload = true;
                $scope.baixarExportacao = true;
            });
        };
        $scope.exportarDados = function (enviarEmail) {
            if (enviarEmail) {
                $scope.enviarEmailExportacao = true;
                $scope.baixarExportacao = false;
                $scope.escolhaExportacao = false;
            }
            mixpanel.track("Relatório | Exportação análise comparativa", {
                Local_IP: $rootScope.ip,
            });
            $http({
                method: "POST",
                url: "/relatorio/exportar/excel/analiseComparativa",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    parametros: $scope.filtroParametros,
                    dadosRelatorio: $scope.listaUsuarios,
                    enviarEmailComLink: enviarEmail,
                    possuiComparacao: $scope.filtroComparadoA !== null ? true : false,
                    periodoInicial: $scope.filtroPeriodoEscolhido,
                    periodoComparacao: $scope.filtroComparadoA,
                },
            }).then(function (response) {
                if (!enviarEmail) {
                    (function verificarDownload() {
                        setTimeout(function () {
                            $http({
                                method: "GET",
                                url: "/alerta/consultarLinkContatosExportados",
                                headers: {
                                    "ramp3r-auth": "ramp3r-authorization",
                                    "ramp3r-l": $rootScope.IDCrypto,
                                },
                                params: {
                                    assunto: "Analise_Comparativa_Exportada",
                                },
                            }).then(function (response) {
                                if (!response.data && $scope.popupGerarCSV) {
                                    verificarDownload();
                                }
                                else {
                                    $scope.processandoArquivoParaDownload = false;
                                    $scope.linkParaDownload = response.data.linkDownload;
                                }
                            });
                        }, 5000);
                    })();
                }
            });
        };
        $scope.ordenarTabela = function (param, verifInversao) {
            $scope.ordenacaoEscolhida = param;
            if (verifInversao) {
                addDestaqueSetinha(param);
                inverteSetinha(param);
                if (param === paramOrdenado) {
                    inverteOrdenacao *= -1;
                }
                else {
                    removeDestaqueSetinha(paramOrdenado);
                    if (inverteOrdenacao > 0)
                        inverteSetinha(paramOrdenado);
                    else
                        inverteOrdenacao = 1;
                }
            }
            if (param === "MembrosDoTime" ||
                $scope.listaUsuarios[0].params[param] === undefined) {
                // Ordena os usuarios em ordem alfabetica
                $scope.listaUsuarios.sort(function (paramA, paramB) {
                    return (("" + paramA.nome + " " + paramA.sobrenome).localeCompare(paramB.nome + " " + paramB.sobrenome) * inverteOrdenacao);
                });
            }
            else {
                // Ordena pelo parametro
                $scope.listaUsuarios.sort(function (paramA, paramB) {
                    if (inverteOrdenacao > 0)
                        return (paramB.params[param].periodoInicial -
                            paramA.params[param].periodoInicial);
                    else
                        return (paramA.params[param].periodoInicial -
                            paramB.params[param].periodoInicial);
                });
            }
            paramOrdenado = param;
        };
        function inverteSetinha(param) {
            var obj = document.getElementById("col" + param);
            if ($(obj).hasClass("inverteBtnOrdenacaoAC")) {
                $(obj).addClass("desinverteBtnOrdenacaoAC");
                $(obj).removeClass("inverteBtnOrdenacaoAC");
            }
            else {
                $(obj).addClass("inverteBtnOrdenacaoAC");
                $(obj).removeClass("desinverteBtnOrdenacaoAC");
            }
        }
        function addDestaqueSetinha(param) {
            var obj = document.getElementById("col" + param);
            $(obj).addClass("destaqueBtnOrdenacaoAC");
        }
        function removeDestaqueSetinha(param) {
            var obj = document.getElementById("col" + param);
            if ($(obj).hasClass("destaqueBtnOrdenacaoAC"))
                $(obj).removeClass("destaqueBtnOrdenacaoAC");
        }
        function iniciaValoresParametro(codigoParam, orderParam) {
            for (var i = 0; i < $scope.listaUsuarios.length; i++) {
                $scope.listaUsuarios[i].params[codigoParam] = {
                    periodoInicial: 0,
                    periodoComparacao: 0,
                    comparacao: "neutra",
                    order: orderParam,
                };
            }
        }
        $scope.iluminaLinha = function (objectIdLogin) {
            var obj = document.getElementById(objectIdLogin);
            $(obj).addClass("iluminaLinha");
            for (var i = 0; i < $scope.filtroParametros.length; i++) {
                var objValor = document.getElementById(objectIdLogin + $scope.filtroParametros[i].code);
                $(objValor).addClass("iluminaLinha");
            }
        };
        $scope.escureceLinha = function (objectIdLogin) {
            var obj = document.getElementById(objectIdLogin);
            $(obj).removeClass("iluminaLinha");
            for (var i = 0; i < $scope.filtroParametros.length; i++) {
                var objAux = document.getElementById(objectIdLogin + $scope.filtroParametros[i].code);
                $(objAux).removeClass("iluminaLinha");
            }
        };
        function ordenaFiltroUsuarios() {
            // Ordena os usuarios para que apareça em ordem alfabetica
            $scope.usuariosTime.sort(function (x, y) {
                // if (x.status != 'I' && y.status != 'I') {}
                return ("" + x.nome + " " + x.sobrenome).localeCompare(y.nome + " " + y.sobrenome);
            });
        }
        function ordenaFiltroCadencia() {
            // Ordena as cadências em ordem alfabética
            $scope.cadenciasTime.sort(function (x, y) {
                return ("" + x.nomeCadencia).localeCompare("" + y.nomeCadencia);
            });
        }
        function ordenaFiltroSegmentacao() {
            // Ordena as segmentacoes  em ordem alfabetica
            $scope.segmentacoesTime.sort(function (x, y) {
                return ("" + x.nomeSegmentacao).localeCompare("" + y.nomeSegmentacao);
            });
        }
        function montaData(date) {
            date = new Date(date);
            var dataMontada = {};
            var ano = new Date(date).getUTCFullYear();
            var mes = new Date(date).getUTCMonth() + 1;
            switch (mes) {
                case 12:
                    dataMontada.inicial = ano + "-" + (mes - 1) + "-01";
                    dataMontada.final = ano + 1 + "-0" + 1 + "-01";
                    break;
                case 11:
                    dataMontada.inicial = ano + "-" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                case 10:
                    dataMontada.inicial = ano + "-0" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                case 9:
                    dataMontada.inicial = ano + "-0" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                case 1:
                    dataMontada.inicial = ano - 1 + "-12" + "-01";
                    dataMontada.final = ano + "-" + (mes + 1) + "-01";
                    break;
                default:
                    dataMontada.inicial = ano + "-0" + (mes - 1) + "-01";
                    dataMontada.final = ano + "-0" + (mes + 1) + "-01";
                    break;
            }
            dataMontada.inicial = new Date(dataMontada.inicial);
            dataMontada.final = new Date(dataMontada.final);
            return dataMontada;
        }
        function montaParamsTela() {
            $http({
                method: "GET",
                url: "/relatorio/consultarRelatorioExistente",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (response) {
                var relatorio = response.data;
                if (relatorio && relatorio.listaParams.length > 0) {
                    for (var i = 0; i < relatorio.listaParams.length; i++) {
                        $scope.selecionarParametros($scope.todosParametros.find(function (param) {
                            if (relatorio.listaParams[i] === param.code)
                                return param;
                        }));
                    }
                }
                else {
                    var paramsIniciais = ["COCA", "EMEN", "EMET", "EMBO", "TORE", "REPO"];
                    for (var i = 0; i < paramsIniciais.length; i++) {
                        $scope.selecionarParametros($scope.todosParametros.find(function (param) {
                            if (paramsIniciais[i] === param.code)
                                return param;
                        }));
                    }
                }
            });
        }
        $scope.salvarParamsTela = function () {
            var listaCodigosParams = [];
            for (var i = 0; i < $scope.filtroParametros.length; i++) {
                listaCodigosParams.push($scope.filtroParametros[i].code);
            }
            $http({
                method: "POST",
                url: "/relatorio/salvarListaParamsAC",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    listaParams: listaCodigosParams,
                },
            }).then(function (response) { });
        };
        setTimeout(function () {
            $scope.selecionarMes($scope.anoFront, mesInicial, idMesInicial, "filtrarMes");
        }, 1000);
        montarFiltroFront();
        montarFiltroMeses();
        montaParamsTela();
        $scope.montaTela("AnaliseComparativa");
        $scope.selecionadoPeriodoNome = "Este mês";
        $scope.selecionadoComparadoA = "esteMesComparadoAMesAnterior";
        $scope.selecionarPeriodoInicial("periodoInicialEsteMes");
        $scope.selecionarPeriodoComparacao("esteMesComparadoAMesAnterior");
        addDestaqueSetinha("MembrosDoTime");
        inverteSetinha("MembrosDoTime");
        mixpanel.track("Relatório | Análise comparativa", {
            Local_IP: $rootScope.ip,
        });
        //#endregion
    },
]);
