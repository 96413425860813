angular.module("ramperv3").service("PipedriveService", [
    "$rootScope",
    "$q",
    "$http",
    function ($rootScope, $q, $http) {
        this.getAll = function (apiKey) {
            return $q(function (resolve, reject) {
                $http({
                    method: "GET",
                    url: "/integracoes/pipedrive/consultarDadosPipelineEstagiosUsuarios",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        apiKey: apiKey,
                    },
                })
                    .then(function (rDadosPipedrive) {
                    var dadosPipedrive = {
                        pipelines: rDadosPipedrive.data.pipelines,
                        estagios: rDadosPipedrive.data.estagios,
                        usuarios: rDadosPipedrive.data.usuarios,
                    };
                    return resolve(dadosPipedrive);
                })
                    .catch(function (erro) {
                    return reject(erro.data);
                });
            });
        };
        this.getMotivos = function (apiKey) {
            return $q(function (resolve, reject) {
                $http({
                    method: "GET",
                    url: "/integracoes/pipedrive/motivos",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        apiKey: apiKey,
                    },
                })
                    .then(function (rMotivos) {
                    return resolve(rMotivos.data);
                })
                    .catch(function (erro) {
                    return reject(erro.data);
                });
            });
        };
    },
]);
