var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
angular.module("ramperv3").service("UsuariosService", [
    "$http",
    function ($http) {
        /**
         * realiza requisição para buscar membros do time
         *
         * @param {*} filters - filtros de busca
         * @returns {*} lista de usuários membros do time referente à busca
         */
        function consultarUsuariosTime(filters, sort) {
            return $http({
                method: "GET",
                url: "/v2/usuarios",
                params: __assign(__assign({}, filters), sort),
            }).then(function (response) {
                return response.data.data;
            });
        }
        return {
            consultarUsuariosTime: consultarUsuariosTime,
        };
    },
]);
