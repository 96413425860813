"use strict";
angular.module("ramperv3").service("AcessosService", [
    "$rootScope",
    "$q",
    "$http",
    function ($rootScope, $q, $http) {
        var time;
        var login;
        function setAcessos(dadosTime) {
            time = dadosTime;
            var dados = {
                time: time,
            };
            $http({
                method: "GET",
                url: "/acessos-time/" + time._id,
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            })
                .then(function (response) {
                $rootScope.$emit("acessos-time-salvos", response.data);
            })
                .catch(function (error) {
                $rootScope.$emit("acessos-time-salvos", null);
            });
        }
        return {
            setAcessos: setAcessos,
        };
    },
]);
