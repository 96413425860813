"use strict";
angular.module("ramperv3").service("AgenciaService", [
    "$rootScope",
    "$http",
    function ($rootScope, $http) {
        function consultarAgenciasLogins(usuario) {
            $http({
                method: "GET",
                url: "/agencias/consultarAgenciaLogins",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": usuario ? usuario.iDCrypto : $rootScope.IDCrypto,
                },
            }).then(function (res) {
                var agencias = res.data;
                if (agencias.length > 0) {
                    $rootScope.mostrarTrocarConta = true;
                    agencias.forEach(function (agencia) {
                        if (agencia.fotoPerfil) {
                            agencia.fotoPerfil =
                                "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                    agencia.objectIdLogin.toString() +
                                    "/" +
                                    agencia.fotoPerfil;
                        }
                        else {
                            agencia.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                        }
                        if ($rootScope.Login.email !== agencia.email) {
                            $rootScope.showBrowserLogins.push(agencia);
                        }
                    });
                }
            });
        }
        return {
            consultarAgenciasLogins: consultarAgenciasLogins,
        };
    },
]);
