angular.module("ramperv3").service("ExactSalesService", [
    "$rootScope",
    "$http",
    function ($rootScope, $http) {
        /**
         * Deve consumir a api para obter a lista de pipelines
         * @returns uma lista de pipelines
         */
        function getPipelines(apiKey) {
            var params = {
                apiKey: apiKey,
            };
            return $http({
                method: "GET",
                url: "/integracoes/exactsales/pipelines",
                params: params,
            });
        }
        /**
         * Deve consumir a api para obter a lista de sdrs
         * @returns uma lista de sdrs
         */
        function getSdrs(apiKey) {
            var params = {
                apiKey: apiKey,
            };
            return $http({
                method: "GET",
                url: "/integracoes/exactsales/sdrs",
                params: params,
            });
        }
        /**
         * Deve consumir a api para salvar uma integração (ExactSales)
         */
        function salvar(aplicacao, apiKey, emailExactSales, pipelineId) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            var data = {
                aplicacao: aplicacao,
                apiKey: apiKey,
                emailExactSales: emailExactSales,
                pipelineId: pipelineId,
            };
            return $http({
                method: "POST",
                url: "/integracoes/salvar",
                headers: headers,
                data: data,
            });
        }
        return {
            salvar: salvar,
            getPipelines: getPipelines,
            getSdrs: getSdrs,
        };
    },
]);
