angular.module("ramperv3").service("MonsterNoticiaService", [
    "$rootScope",
    "$http",
    function ($rootScope, $http) {
        /**
         * Deve consumir a api para obter a noticia
         */
        function obterNoticia() {
            var url = "/noticias";
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            return $http({ method: "GET", url: url, headers: headers });
        }
        /**
         * Deve consumir a api para não exibir noticia novamente
         */
        function naoExibirNoticiaNovamente(idNoticia) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            return $http({
                method: "PATCH",
                url: "/noticias/".concat(idNoticia, "/nao-exibir-novamente"),
                headers: headers,
            });
        }
        return {
            obterNoticia: obterNoticia,
            naoExibirNoticiaNovamente: naoExibirNoticiaNovamente,
        };
    },
]);
