angular.module("ramperv3").service("MonsterMfaService", [
    "$http",
    function ($http) {
        /**
         * Deve consumir a api para gerar a imagem base64 mfa
         */
        function gerarBase64Mfa() {
            return $http({ method: "POST", url: "/login/mfa/register" });
        }
        /**
         * Deve consumir a api para gerar a imagem base64 mfa
         * @param token credenciais para validar mfa
         */
        function validarTokenMfa(token) {
            return $http({
                method: "POST",
                url: "/login/mfa/validate",
                data: { token: token },
            });
        }
        /**
         * Deve consumir a api para remover mfa
         */
        function removerMfa() {
            return $http({
                method: "DELETE",
                url: "login/mfa",
            });
        }
        return { gerarBase64Mfa: gerarBase64Mfa, validarTokenMfa: validarTokenMfa, removerMfa: removerMfa };
    },
]);
