angular.module("ramperv3").directive("numericMask", function () {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            function applyMask(value) {
                var cleanedInput = value.replace(/[^a-zA-Z0-9]/g, "");
                var groups = cleanedInput.match(/.{1,3}/g);
                var formattedValue = "";
                if (groups) {
                    formattedValue = groups.join(" ");
                }
                return formattedValue;
            }
            element.on("eInput", function (event) {
                var inputText = element.val();
                var maskedText = applyMask(inputText);
                element.val(maskedText);
                scope.$apply(function () {
                    scope[attrs.ngModel] = maskedText;
                });
            });
        },
    };
});
