angular.module("ramperv3").service("MovideskService", [
    "$http",
    "$rootScope",
    function ($http, $rootScope) {
        /**
         * Consumo do endpoint para criar/atualizar o contato no movidesk
         */
        function salvarDadosMovidesk() {
            if ($rootScope.ambiente == "prod") {
                return $http({
                    method: "POST",
                    url: "/login/movidesk",
                });
            }
        }
        return {
            salvarDadosMovidesk: salvarDadosMovidesk,
        };
    },
]);
