angular
    .module("ramperv3")
    .controller("ChatController", [
    "$rootScope",
    "$scope",
    "$http",
    "$filter",
    function ($rootScope, $scope, $http, $filter) {
        $ctrl = this;
        var _cookie = "login_ramperv3_1910_1989_2017";
        var _verificarCookie = lerCookie(_cookie);
        $scope.mostrarClassificar = false;
        $scope.experimentar = null;
        $scope.possuiAgendaConfigurada = null;
        var retry = true;
        var delay = 100;
        $scope.verificarAgendaConfigurada = function () {
            $http({
                method: "GET",
                url: "/agenda/config",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (response) {
                var configResponse = response.data;
                $scope.possuiAgendaConfigurada =
                    !configResponse.erro && configResponse;
            });
        };
        $scope.sidebarChatboxSelectedOption = null;
        $scope.sidebarChatboxMenuOptions = [
            { id: "contato", title: "Contato", icon: "user" },
            { id: "agenda", title: "Agenda Inteligente", icon: "calendar" },
            { id: "questionario", title: "Questionário", icon: "file-text" },
        ];
        $scope.fecharSidebarChatbox = function () {
            $scope.sidebarChatboxSelectedOption = null;
        };
        $scope.selectChatBoxMenuOption = function (option) {
            $scope.sidebarChatboxSelectedOption = option;
        };
        var addBlur = function () {
            $rootScope.loaderAtivo = true;
            $("#pagChat").addClass("blur");
            $("#menuPrincipal").addClass("blur");
        };
        var removeBlur = function () {
            $("#pagChat").removeClass("blur");
            $("#menuPrincipal").removeClass("blur");
            $rootScope.loaderAtivo = false;
        };
        $scope.iniciarSessaoChat = function () {
            $scope.experimentar = false;
            setTimeout(function () {
                Talk.ready
                    .then(function () {
                    var me = new Talk.User({
                        id: "vendedor_" + $rootScope.DadosCadastrais.objectIdLogin,
                        name: $rootScope.nomeUsuarioLogado,
                        email: $rootScope.Login.email,
                        role: "vendedor",
                        welcomeMessage: "Oi, tudo bem?\nObrigado por entrar em contato. Como posso te ajudar?",
                    });
                    window.talkSession = new Talk.Session({
                        // appId: 't928W8rK',//teste
                        appId: "g1sFkEsj",
                        me: me,
                    });
                    window.talkSession.unreads.on("change", notificacaoChat);
                    $rootScope.inbox = talkSession.createInbox();
                    $rootScope.inbox.mount(document.getElementById("talkjs-container"));
                    var notificaContatoAusente = function (mensagem) {
                        $http({
                            method: "POST",
                            url: "/chat/notificacao/ausencia/contato",
                            headers: {
                                "ramp3r-auth": "ramp3r-authorization",
                                "ramp3r-l": $rootScope.IDCrypto,
                            },
                            data: {
                                objectIdLogin: $rootScope.DadosCadastrais.objectIdLogin,
                                objectIdContato: $scope.contatoSelecionado._id,
                                idConversa: mensagem.conversation.id,
                                mensagem: mensagem.message.text,
                                objectIdDisparo: $scope.contatoSelecionado.objectIdDisparo,
                            },
                        }).then(function (resposta) {
                            mixpanel.track("Janela de conversação | Enviou mensagem no chat", {
                                Local_IP: $rootScope.ip,
                            });
                            //$rootScope.inbox.off("sendMessage", notificaContatoAusente)
                        });
                    };
                    $rootScope.inbox.on("conversationSelected", function (conversa) {
                        $rootScope.inbox.off("sendMessage", notificaContatoAusente);
                        $scope.contatoSelecionado = {
                            _id: conversa.other.id.split("contato_")[1],
                            objectIdDisparo: conversa.other.custom.objectIdDisparo,
                            nome: conversa.other.name,
                        };
                        $rootScope.inbox.on("sendMessage", notificaContatoAusente);
                        $scope.mostrarClassificar = true;
                        $scope.$apply();
                        getContato($scope.contatoSelecionado._id);
                    });
                    $scope.$apply();
                    mixpanel.track("Janela de conversação | Clicou na janela de conversação", {
                        Local_IP: $rootScope.ip,
                    });
                })
                    .catch(function (err) {
                    if (retry === true) {
                        retry = false;
                        delay = 1000;
                        $scope.iniciarSessaoChat();
                    }
                    else {
                        alert("algo deu errado, por favor aguarde alguns minutos e tente novamente");
                    }
                });
            }, 0 + delay);
        };
        if ($rootScope.permissoes.chat.screen) {
            $scope.iniciarSessaoChat();
        }
        else {
            addBlur();
            var tries = 0;
            var interval = setInterval(function () {
                tries++;
                if (!$rootScope.permissoes.chat.screen) {
                    if (tries <= 3) {
                        return;
                    }
                    mixpanel.track("Janela de conversação | Sem acesso", {
                        Local_IP: $rootScope.ip,
                    });
                    removeBlur();
                    $scope.experimentar = true;
                    $scope.$apply();
                }
                else {
                    $scope.iniciarSessaoChat();
                    removeBlur();
                }
                clearInterval(interval);
            }, 1000);
        }
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $rootScope.redirectChat();
        $rootScope.mostrarMenu = true;
        $rootScope.consultarStatusUsuario();
        $scope.detalhesContatos = null;
        $scope.$watch("detalhesContatos", function (contato) {
            $scope.$broadcast("prospectChanged", contato);
        });
        $scope.loadingProspect = false;
        $scope.$watch("loadingProspect", function (pending) {
            $scope.$broadcast("prospectLoadingEvent", pending);
        });
        $scope.contatoSelecionado = null;
        var _emailCookie = "";
        if (_verificarCookie != null && _verificarCookie != "") {
            _emailCookie = _verificarCookie.split("EMA_")[1].split("_")[1];
        }
        var myConfObj = {
            iframeMouseOver: false,
        };
        window.addEventListener("blur", function () {
            if (myConfObj.iframeMouseOver) {
                if ($scope.showClassificar) {
                    $scope.abrirClassificar();
                    $scope.$apply();
                }
            }
        });
        document
            .getElementById("talkjs-container")
            .addEventListener("mouseover", function () {
            myConfObj.iframeMouseOver = true;
        });
        document
            .getElementById("talkjs-container")
            .addEventListener("mouseout", function () {
            myConfObj.iframeMouseOver = false;
        });
        $scope.applicationInbound = "";
        $scope.applicationPreVendas = "";
        $scope.applicationCRM = "";
        $scope.verificarIntegracao = function () {
            $scope.applicationInbound = "";
            $scope.applicationPreVendas = "";
            $scope.applicationCRM = "";
            $http({
                method: "GET",
                url: "/integracoes/consultarIntegracoes",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    status: "A",
                },
            }).then(function (rVerificarSeTemIntegracao) {
                if (rVerificarSeTemIntegracao.data &&
                    rVerificarSeTemIntegracao.data.length > 0) {
                    rVerificarSeTemIntegracao.data.forEach(function (iIntegracao) {
                        if (iIntegracao.application == "R") {
                            $scope.integracaoInbound = iIntegracao;
                            $scope.applicationInbound = iIntegracao.nomeAplicao;
                        }
                        else if (iIntegracao.application == "E") {
                            $scope.integracaoPreVendas = iIntegracao;
                            $scope.applicationPreVendas = iIntegracao.nomeAplicao;
                        }
                        else {
                            $scope.integracaoCRM = iIntegracao;
                            $scope.applicationCRM = iIntegracao.nomeAplicao;
                        }
                    });
                }
            });
        };
        $scope.verificarIntegracao();
        $scope.abrirClassificar = function () {
            $scope.showClassificar = !$scope.showClassificar;
        };
        $scope.converterContato = function (objectIdContato, status) {
            // 'P' Interesse
            // 'S' Sem interesse
            // 'U' Neutro
            $http({
                method: "POST",
                url: "/contatos/converterContato",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    status: status,
                    todos: false,
                    objectIdContato: [objectIdContato],
                },
            }).then(function () {
                $scope.abrirClassificar();
                $scope.contatoSelecionado.status = status;
                if (status == "P") {
                    if ($scope.integracaoCRM) {
                        $scope.enviarIntegracaoGeral(objectIdContato, $scope.integracaoCRM, "C");
                    }
                    else if ($scope.integracaoPreVendas) {
                        $scope.enviarIntegracaoGeral(objectIdContato, $scope.integracaoPreVendas, "P");
                    }
                    mixpanel.track("Janela de conversação  |  Classificou como Com interesse", { Local_IP: $rootScope.ip });
                }
                if (status == "S") {
                    if ($scope.integracaoInbound) {
                        $scope.enviarIntegracaoGeral(objectIdContato, $scope.integracaoInbound, "I");
                    }
                    mixpanel.track("Janela de conversação  |  Classificou como Sem interesse", { Local_IP: $rootScope.ip });
                }
            });
        };
        $scope.enviarIntegracaoGeral = function (idContato, integracao, categoria) {
            $scope.enviarParaIntegracaoModalConfirmacao = true;
            $scope.categoriaIntegracao = categoria;
            $scope.tipoIntegracao = integracao.application;
            $scope.integracaoApikey = integracao.apiKey;
            $scope.contatoIds = [idContato];
            $scope.queryParams = {
                crm: integracao.application,
                apiKey: integracao.apiKey,
                objectIdContato: idContato,
            };
        };
        var getContato = function (objectIdContato) {
            $scope.loadingProspect = true;
            $http({
                method: "GET",
                url: "/contato/" + objectIdContato,
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            })
                .then(function (res) {
                $scope.loadingProspect = false;
                return res.data;
            })
                .then(function (contato) {
                if (contato.erro) {
                    $scope.detalhesContatos = null;
                    return;
                }
                $scope.detalhesContatos = contato;
                $scope.contatoSelecionado.nome =
                    contato.nome + " " + contato.sobrenome;
                $scope.contatoSelecionado.empresa = contato.empresa.empresa;
                $scope.contatoSelecionado.email = contato.email;
                $scope.contatoSelecionado.status = contato.status;
            })
                .catch(function (err) {
                $scope.loadingProspect = false;
                $scope.detalhesContatos = null;
            });
        };
        //função para carregar a mensagem da pergunta do questionário na caixa de texto do chat
        //implementar a linha abaixo na div com a classe questao-editavel para funcionar
        // ng-click="setMessageText(itemQuestao.questao)"
        $scope.setMessageText = function (message) {
            $rootScope.inbox.messageField.setText(message);
        };
        $scope.experimentarChat = function () {
            addBlur();
            $http({
                method: "POST",
                url: "/chat/trial",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                data: {
                    idTime: $rootScope.Login.times[0]._id,
                },
            })
                .then(function (res) {
                return res.data;
            })
                .then(function (resExperimentar) {
                if (resExperimentar.liberado) {
                    mixpanel.track("Conectar | Ativou Janela de conversação", {
                        Local_IP: $rootScope.ip,
                    });
                    $scope.iniciarSessaoChat();
                    $rootScope.possuiConversacao = true;
                }
                removeBlur();
            });
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
        $scope.verificarAgendaConfigurada();
    },
])
    .directive("contenteditable", [
    "$timeout",
    function ($timeout) {
        return {
            restrict: "A",
            require: "?ngModel",
            link: function (scope, element, attrs, ngModel) {
                // don't do anything unless this is actually bound to a model
                if (!ngModel) {
                    return;
                }
                // options
                var opts = {};
                angular.forEach([
                    "stripBr",
                    "noLineBreaks",
                    "selectNonEditable",
                    "moveCaretToEndOnChange",
                ], function (opt) {
                    var o = attrs[opt];
                    opts[opt] = o && o !== "false";
                });
                // view -> model
                element.bind("input", function (e) {
                    scope.$apply(function () {
                        var html, html2, rerender;
                        html = element.html();
                        rerender = false;
                        if (opts.stripBr) {
                            html = html.replace(/<br>$/, "");
                        }
                        if (opts.noLineBreaks) {
                            html2 = html
                                .replace(/<div>/g, "")
                                .replace(/<br>/g, "")
                                .replace(/<\/div>/g, "");
                            if (html2 !== html) {
                                rerender = true;
                                html = html2;
                            }
                        }
                        ngModel.$setViewValue(html);
                        if (rerender) {
                            ngModel.$render();
                        }
                        if (html === "") {
                            // the cursor disappears if the contents is empty
                            // so we need to refocus
                            $timeout(function () {
                                element[0].blur();
                                element[0].focus();
                            });
                        }
                    });
                });
                // model -> view
                var oldRender = ngModel.$render;
                ngModel.$render = function () {
                    var el, el2, range, sel;
                    if (oldRender) {
                        oldRender();
                    }
                    element.html(ngModel.$viewValue || "");
                    if (opts.moveCaretToEndOnChange) {
                        el = element[0];
                        range = document.createRange();
                        sel = window.getSelection();
                        if (el.childNodes.length > 0) {
                            el2 = el.childNodes[el.childNodes.length - 1];
                            range.setStartAfter(el2);
                        }
                        else {
                            range.setStartAfter(el);
                        }
                        range.collapse(true);
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                };
                if (opts.selectNonEditable) {
                    element.bind("click", function (e) {
                        var range, sel, target;
                        target = e.toElement;
                        if (target !== this &&
                            angular.element(target).attr("contenteditable") === "false") {
                            range = document.createRange();
                            sel = window.getSelection();
                            range.setStartBefore(target);
                            range.setEndAfter(target);
                            sel.removeAllRanges();
                            sel.addRange(range);
                        }
                    });
                }
            },
        };
    },
])
    .filter("reverse", function () {
    return function (items) {
        return items.slice().reverse();
    };
});
