"use strict";
angular.module("ramperv3.serviceWorker", []).service("serviceWorkerService", [
    "$q",
    "$http",
    function ($q, $http) {
        var serviceWorkerService = this;
        serviceWorkerService.checkServiceWorker = function () {
            return "serviceWorker" in navigator && "PushManager" in window;
        };
        serviceWorkerService.registerWorker = function () {
            return $q(function (resolve, reject) {
                if (serviceWorkerService.checkServiceWorker()) {
                    navigator.serviceWorker
                        .register("/service-worker.js")
                        .then(function (swReg) {
                        swReg.update();
                        return resolve(swReg);
                    })
                        .catch(function (err) {
                        return reject(err);
                    });
                }
            });
        };
        serviceWorkerService.subscribeUser = function (swRegistration, idUsuario) {
            if (Notification.permission === "denied") {
                return false;
            }
            Notification.requestPermission(function (permission) {
                if (permission !== "granted") {
                    return false;
                }
                $http({
                    method: "GET",
                    url: "/notificacao/config",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": idUsuario,
                    },
                })
                    .then(function (resposta) {
                    return resposta.data;
                })
                    .then(function (config) {
                    var applicationServerKey = config.key;
                    swRegistration.pushManager
                        .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: applicationServerKey,
                    })
                        .then(function (subscription) {
                        var endpoint = subscription.endpoint;
                        var key = subscription.getKey("p256dh");
                        var auth = subscription.getKey("auth");
                        var expirationTime = subscription.expirationTime || null;
                        var data = {
                            idUsuario: idUsuario,
                            subscription: {
                                endpoint: endpoint,
                                p256dh: key
                                    ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
                                    : null,
                                auth: auth
                                    ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth)))
                                    : null,
                                expirationTime: expirationTime,
                            },
                        };
                        serviceWorkerService.createSubscriptionOnServer(data);
                    })
                        .catch(function (err) {
                        console.log("Failed to subscribe the user: ", err);
                    });
                });
            });
        };
        serviceWorkerService.subscribeContato = function (swRegistration, idContato) {
            if (Notification.permission === "denied") {
                return false;
            }
            Notification.requestPermission(function (permission) {
                if (permission !== "granted") {
                    return false;
                }
                window.talkSession.setDesktopNotificationEnabled(true);
                $http({
                    method: "GET",
                    url: "/notificacao/config",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": "0881d3992f",
                    },
                })
                    .then(function (resposta) {
                    return resposta.data;
                })
                    .then(function (config) {
                    var applicationServerKey = config.key;
                    swRegistration.pushManager
                        .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: applicationServerKey,
                    })
                        .then(function (subscription) {
                        var endpoint = subscription.endpoint;
                        var key = subscription.getKey("p256dh");
                        var auth = subscription.getKey("auth");
                        var expirationTime = subscription.expirationTime || null;
                        var data = {
                            idContato: idContato,
                            subscription: {
                                endpoint: endpoint,
                                p256dh: key
                                    ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
                                    : null,
                                auth: auth
                                    ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth)))
                                    : null,
                                expirationTime: expirationTime,
                            },
                        };
                        serviceWorkerService.createContatoSubscriptionOnServer(idContato, data);
                    })
                        .catch(function (err) {
                        console.log("Failed to subscribe the user: ", err);
                    });
                });
            });
        };
        serviceWorkerService.createSubscriptionOnServer = function (dadosSubscription) {
            $http({
                method: "POST",
                url: "/notificacao/subscription",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": dadosSubscription.idUsuario,
                },
                data: {
                    subscription: dadosSubscription.subscription,
                },
            }).then(function (resposta) { });
        };
        serviceWorkerService.createContatoSubscriptionOnServer = function (idContato, dadosSubscription) {
            $http({
                method: "POST",
                url: "/notificacao/subscription/contato",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": "0881d3992f",
                },
                data: {
                    objectIdContato: idContato,
                    subscription: dadosSubscription.subscription,
                },
            }).then(function (resposta) { });
        };
    },
]);
