angular.module("ramperv3").directive("ngDisabledPermission", [
    "ngDisabledDirective",
    "PermissionamentoService",
    function (ngDisabledDirective, PermissionamentoService) {
        var ngDisabled = ngDisabledDirective[0];
        return {
            priority: ngDisabled.priority + 1,
            restrict: ngDisabled.restrict,
            link: function ($scope, _, $attr) {
                var permission = $scope.$eval($attr.ngDisabledPermission);
                var initialNgDisabled = $attr.ngDisabled
                    ? $scope.$eval($attr.ngDisabled)
                    : true;
                var hasAllowAccessToScreenAction = PermissionamentoService.allowAccessToScreenAction(permission.screen, permission.action);
                $attr.ngDisabled = function () {
                    return initialNgDisabled || !hasAllowAccessToScreenAction;
                };
                ngDisabled.link.apply(ngDisabled, arguments);
            },
        };
    },
]);
