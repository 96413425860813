var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
angular.module("ramperv3").controller("RespostasController", [
    "$rootScope",
    "$scope",
    "$http",
    "DateRangePicker",
    function ($rootScope, $scope, $http, DateRangePicker) {
        var _cookie = "login_ramperv3_1910_1989_2017";
        var _id = lerCookie(_cookie);
        if (_id != null && _id != "") {
            var _idCookie = _id.split("EMA_")[1].split("_")[0];
            var _tipoCookie = _id.split("EMA_")[1].split("_")[2];
            _emailCookie = _id.split("EMA_")[1].split("_")[1];
        }
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        $rootScope.consultarStatusUsuario();
        var _pagina = -10;
        var _campoOrdem = "dataOrd";
        var _ordem = -1;
        var _listaFiltro = "-";
        var _limite = 10;
        var _itemSelecionado = null;
        var _parametrosDinamicos = null;
        $rootScope.redirectRespostas();
        $scope.buscar = "";
        $scope.ordenacaoResp = "Crescente";
        $scope.nenhumaSelecionada = true;
        $scope.filtroConfiabilidade = "T";
        if ($rootScope.administrativoOuVisao)
            $scope.filtroUsuario = "-";
        else
            $scope.filtroUsuario = $rootScope.Login._id;
        $scope.respostaHTML = "";
        $rootScope.mostrarMenu = true;
        $scope.popupFiltrarRespostas = false;
        $scope.semRespostas = false;
        $scope.semRespostasFiltro = false;
        $scope.semRespostasBusca = false;
        $scope.filtroHabilitado = false;
        $scope.semRespostasAnalytics = false;
        var endDate = new Date();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        $scope.dataInicioFiltro = startDate;
        $scope.dataFinalFiltro = endDate;
        $scope.dataInicioFiltroResposta = startDate;
        $scope.dataFinalFiltroResposta = endDate;
        $scope.listaMensagens = [];
        $scope.mostrarAnalytics = false;
        $scope.mostrarAlterarResp = false;
        $scope.emptyGraficoAnalytics = false;
        var chartUm = null;
        var chartDois = null;
        var chartTres = null;
        var fazerConsultaGraficoSequencia = true;
        $("#idFundoPopup").hide();
        var graficoDiaSemana = [];
        var graficoHora = [];
        $scope.trackScreenAccessMixpanelEvent = function () {
            mixpanel.track("Respostas | Acessou a tela de respostas");
        };
        $scope.consultarRespostas = function () {
            $rootScope.loaderAtivo = true;
            $("#pagRespostas").addClass("blur");
            $("#menuPrincipal").addClass("blur");
            if (_pagina == -10)
                $scope.listaMensagens = [];
            _pagina = _pagina + 10;
            if (_parametrosDinamicos == null)
                _parametrosDinamicos = {
                    dataResposta: true,
                    ordem: _campoOrdem,
                    ordemAscDesc: _ordem,
                    skip: _pagina,
                    limit: _limite,
                    tipoDaChave: $rootScope.Tipo,
                };
            else {
                _parametrosDinamicos.skip = _pagina;
            }
            if ($scope.buscar != "")
                _parametrosDinamicos.buscar = $scope.buscar;
            setTimeout(function () {
                $scope.consultarTotais();
            }, 100);
            $http({
                method: "GET",
                url: "/dados/consultarDados",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: _parametrosDinamicos,
            }).then(function (rConsultaRespostas) {
                $rootScope.loaderAtivo = false;
                $("#pagRespostas").removeClass("blur");
                $("#menuPrincipal").removeClass("blur");
                if (rConsultaRespostas.data.length > 0) {
                    $scope.semRespostas = false;
                    rConsultaRespostas.data.forEach(function (item) {
                        item.dataEnvioFront = item.dataEnvio.split(" | ")[0];
                        item.classeLida = "";
                        item.contatoSemCadencia = false;
                        item.dataRespostaFront = fFormataData(item.dataOrd);
                        if (item.lidoNaoLido == "LIDA") {
                            item.corLida = "#f9f9f9";
                            item.boldOuNao = "normal";
                        }
                        else {
                            item.corLida = "#ffffff";
                            item.boldOuNao = "bold";
                        }
                        if (item.statusResposta == "P") {
                            item.resposta = "com interesse";
                            item.classeResposta = "ComInteresse";
                        }
                        else if (item.statusResposta == "N") {
                            item.resposta = "sem interesse";
                            item.classeResposta = "SemInteresse";
                        }
                        else if (item.statusResposta == "C") {
                            item.resposta = "neutra";
                            item.classeResposta = "Neutra";
                        }
                        else {
                            item.resposta = "sem classificação";
                            item.classeResposta = "";
                        }
                        $scope.listaMensagens.push(item);
                    });
                }
                else {
                    if ($scope.listaMensagens.length <= 0) {
                        $scope.esconderEmptyStates();
                        if ($scope.filtroHabilitado) {
                            $scope.semRespostasFiltro = true;
                            $scope.buscaOuFiltro = "Seu filtro";
                        }
                        else if ($scope.buscaHabilitada) {
                            $scope.semRespostasBusca = true;
                            $scope.buscaOuFiltro = "Sua busca";
                        }
                        else
                            $scope.semRespostas = true;
                    }
                    else if ($scope.listaMensagens.length > 0) {
                        $scope.esconderEmptyStates();
                    }
                }
                var stringData = "";
                try {
                    stringData =
                        new Date(rConsultaRespostas.data[0].dataHoraUltimaConexaoNoEmail)
                            .getDate()
                            .toString()
                            .padStart(2, "0") +
                            "/" +
                            (new Date(rConsultaRespostas.data[0].dataHoraUltimaConexaoNoEmail).getMonth() + 1)
                                .toString()
                                .padStart(2, "0") +
                            " às " +
                            new Date(rConsultaRespostas.data[0].dataHoraUltimaConexaoNoEmail)
                                .getHours()
                                .toString()
                                .padStart(2, "0") +
                            ":" +
                            new Date(rConsultaRespostas.data[0].dataHoraUltimaConexaoNoEmail)
                                .getMinutes()
                                .toString()
                                .padStart(2, "0");
                }
                catch (e) {
                    stringData = "";
                }
                $scope.dataHoraUltimaConexaoNoEmail = stringData;
            });
        };
        fFormataData = function (ISODate) {
            var data = new Date(ISODate);
            var dia = data.getDate();
            if (dia.toString().length == 1)
                dia = "0" + dia;
            var mes = data.getMonth() + 1;
            if (mes.toString().length == 1)
                mes = "0" + mes;
            var ano = data.getFullYear();
            var hora = data.getHours();
            if (hora.toString().length == 1)
                hora = "0" + hora;
            var minuto = data.getMinutes();
            if (minuto.toString().length == 1)
                minuto = "0" + minuto;
            return dia + "/" + mes + "/" + ano + " | " + hora + ":" + minuto;
        };
        $scope.consultarTotais = function () {
            $http({
                method: "GET",
                url: "/dados/consultarTotalRespostas",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: _parametrosDinamicos,
            }).then(function (rConsulta) {
                if (rConsulta.data.length > 0) {
                    rConsulta.data.forEach(function (total) {
                        $scope.total = total.respostas;
                    });
                }
                else {
                    $scope.total = "0";
                }
            });
        };
        $scope.alterarStatusResposta = function (pClassResposta) {
            $http({
                method: "GET",
                url: "/resposta/alterarStatusResposta",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    objectId: _itemSelecionado._id,
                    statusResposta: pClassResposta,
                },
            });
        };
        $scope.carregar = function (item) {
            mixpanel.track("Respostas | Abriu resposta dentro do Ramper");
            for (i = 0; i < $scope.listaMensagens.length; i++) {
                $scope.listaMensagens[i].classeLida = "";
            }
            $(document).ready(function () {
                $("#areaRespostaAberta").animate({ scrollTop: 0 }, 100);
                return false;
            });
            $scope.respostaLida = true;
            $scope.nenhumaSelecionada = false;
            _itemSelecionado = item;
            document.getElementById("areaMsgResposta").innerHTML =
                "<br/>" + _itemSelecionado.respostaHTML;
            var base = document.getElementsByTagName("base");
            for (i = 0; i < base.length; i++) {
                base[i].href = "";
            }
            $scope.respostaHTML = _itemSelecionado.respostaHTML;
            $scope.sequenciaSelecionada = _itemSelecionado.sequencia;
            $scope.totalSelecionada = _itemSelecionado.totalSequencia;
            $scope.assuntoSelecionada = _itemSelecionado.assunto;
            $scope.dataRespostaSelecionada = _itemSelecionado.dataRespostaFront;
            $scope.dataEnvioSelecionada = _itemSelecionado.dataEnvio;
            $scope.nomeContatoSelecionada = _itemSelecionado.nomeContato;
            $scope.nomeEmpresaSelecionada = _itemSelecionado.nomeEmpresa;
            $scope.nomeDisparoSelecionada = _itemSelecionado.nomeDisparo;
            $scope.emailContatoSelecionada = _itemSelecionado.emailContato;
            $scope.deviceSelecionada = _itemSelecionado.device;
            $scope.soSelecionada = _itemSelecionado.so;
            $scope.statusRespostaSelecionada = _itemSelecionado.statusResposta;
            $scope.nomeCompletoSelecionada = _itemSelecionado.nomeCompletoContato;
            _itemSelecionado.classeLida = "classeMsgLida";
            if (_itemSelecionado.lidoNaoLido == "NÃO LIDA") {
                $http({
                    method: "GET",
                    url: "/resposta/marcarLidoNaoLido",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    params: {
                        objectIdDisparoEmails: _itemSelecionado._id,
                        lido: true,
                    },
                }).then(function (rConsultaRespostas) {
                    _itemSelecionado.lidoNaoLido = "LIDA";
                    _itemSelecionado.corLida = "#f9f9f9";
                    _itemSelecionado.boldOuNao = "normal";
                });
            }
        };
        $scope.marcarRespNaoLida = function () {
            $http({
                method: "GET",
                url: "/resposta/marcarLidoNaoLido",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    objectIdDisparoEmails: _itemSelecionado._id,
                    lido: false,
                },
            }).then(function () {
                _itemSelecionado.lidoNaoLido = "NÃO LIDA";
                _itemSelecionado.corLida = "#ffffff";
                _itemSelecionado.boldOuNao = "bold";
                $scope.respostaLida = false;
            });
        };
        infiniteScrolling("#tbleRespostas", function () {
            var alreadyReturnedAllMessages = $scope.listaMensagens
                ? $scope.listaMensagens.length === $scope.total
                : false;
            if (!alreadyReturnedAllMessages) {
                $scope.consultarRespostas();
            }
        });
        $scope.abrirPopupFiltrar = function () {
            $("#filtroRespostas").show();
            $scope.popupFiltrarRespostas = true;
            $scope.consultarFiltros();
            $scope.consultarUsuaroFiltro();
        };
        $scope.fecharPopupFiltrar = function () {
            $scope.popupFiltrarRespostas = false;
        };
        $scope.clickMetadado = function () {
            var newNode = tinymce.activeEditor.getDoc().createElement("span");
            newNode.textContent = "/*" + $scope.metadadoSelecionado + "*/";
            var range = tinymce.activeEditor.selection.getRng();
            range.insertNode(newNode);
            $scope.metadadoSelecionado = undefined;
        };
        $scope.ativarClassificacaoResp = function (pDados) {
            var txtClassificou = "";
            _itemSelecionado.statusResposta = pDados;
            $scope.alterarStatusResposta(pDados);
            $scope.carregar(_itemSelecionado);
            if (pDados == "P") {
                txtClassificou = "com interesse";
                _itemSelecionado.resposta = "com interesse";
                _itemSelecionado.classeResposta = "ComInteresse";
                if ($scope.integracaoCRM) {
                    $scope.enviarIntegracaoGeral(_itemSelecionado.objectIdContato, $scope.integracaoCRM, "C");
                }
                else if ($scope.integracaoPreVendas) {
                    $scope.enviarIntegracaoGeral(_itemSelecionado.objectIdContato, $scope.integracaoPreVendas, "P");
                }
            }
            else if (pDados == "C") {
                txtClassificou = "como neutra";
                _itemSelecionado.resposta = "neutra";
                _itemSelecionado.classeResposta = "Neutra";
            }
            else if (pDados == "N") {
                txtClassificou = "sem interesse";
                _itemSelecionado.resposta = "sem interesse";
                _itemSelecionado.classeResposta = "SemInteresse";
                if ($scope.integracaoInbound) {
                    $scope.enviarIntegracaoGeral(_itemSelecionado.objectIdContato, $scope.integracaoInbound, "I");
                }
            }
            else {
                txtClassificou = "sem classificação";
                _itemSelecionado.resposta = "sem classificação";
                _itemSelecionado.classeResposta = "";
            }
            mixpanel.track("Respostas | Classificou " + txtClassificou, {
                Nome: $scope.nomeCompletoSelecionada,
                Email: $scope.emailContatoSelecionada,
                Empresa: $scope.nomeEmpresaSelecionada,
                Cadencia: $scope.nomeDisparoSelecionada,
                Sequencia: $scope.sequenciaSelecionada + " de " + $scope.totalSelecionada,
                Integração: $scope.integracaoSelecionada,
            });
            $scope.abrirClassificacaoResp();
        };
        $scope.enviarIntegracaoGeral = function (idContato, integracao, categoria) {
            $scope.enviarParaIntegracaoModalConfirmacao = true;
            $scope.categoriaIntegracao = categoria;
            $scope.tipoIntegracao = integracao.application;
            $scope.integracaoApikey = integracao.apiKey;
            $scope.contatoIds = [idContato];
            $scope.queryParams = {
                crm: integracao.application,
                apiKey: integracao.apiKey,
                objectIdContato: idContato,
            };
        };
        $("#sltDataEnvioTelaResp").daterangepicker(__assign(__assign({}, DateRangePicker.config), { ranges: {
                "1 Dia": [moment().subtract(1, "days"), moment()],
                "7 Dias": [moment().subtract(6, "days"), moment()],
                "15 Dias": [moment().subtract(14, "days"), moment()],
            } }));
        $("#sltDataEnvioTelaResp").on("apply.daterangepicker", function (ev, picker) {
            var _a = DateRangePicker.config.locale, format = _a.format, separator = _a.separator;
            $(this).val(picker.startDate.format(format) +
                separator +
                picker.endDate.format(format));
            $scope.dataInicioFiltro = picker.startDate.toDate();
            $scope.dataInicioFiltro.setHours(0);
            $scope.dataInicioFiltro.setMinutes(0);
            $scope.dataInicioFiltro.setSeconds(0);
            $scope.dataFinalFiltro = picker.endDate.toDate();
            $scope.dataFinalFiltro.setHours(23);
            $scope.dataFinalFiltro.setMinutes(59);
            $scope.dataFinalFiltro.setSeconds(59);
        });
        $("#sltDataEnvioTelaResp").on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
            picker.startDate = moment();
            picker.endDate = moment();
        });
        $("#sltDataRespondidosTelaResp").daterangepicker(__assign(__assign({}, DateRangePicker.config), { ranges: {
                "1 Dia": [moment().subtract(1, "days"), moment()],
                "7 Dias": [moment().subtract(6, "days"), moment()],
                "15 Dias": [moment().subtract(14, "days"), moment()],
            } }));
        $("#sltDataRespondidosTelaResp").on("apply.daterangepicker", function (ev, picker) {
            var _a = DateRangePicker.config.locale, format = _a.format, separator = _a.separator;
            $(this).val(picker.startDate.format(format) +
                separator +
                picker.endDate.format(format));
            $scope.dataInicioFiltroResposta = picker.startDate.toDate();
            $scope.dataInicioFiltroResposta.setHours(0);
            $scope.dataInicioFiltroResposta.setMinutes(0);
            $scope.dataInicioFiltroResposta.setSeconds(0);
            $scope.dataFinalFiltroResposta = picker.endDate.toDate();
            $scope.dataFinalFiltroResposta.setHours(23);
            $scope.dataFinalFiltroResposta.setMinutes(59);
            $scope.dataFinalFiltroResposta.setSeconds(59);
        });
        $("#sltDataRespondidosTelaResp").on("cancel.daterangepicker", function (ev, picker) {
            $(this).val("");
            picker.startDate = moment();
            picker.endDate = moment();
        });
        $scope.removerFiltros = function () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            $scope.dataInicioFiltro = startDate;
            $scope.dataFinalFiltro = endDate;
            $scope.dataInicioFiltroResposta = startDate;
            $scope.dataFinalFiltroResposta = endDate;
            $scope.cadenciaFiltro = null;
            $scope.nomeStatusRespFiltroSel = null;
            $scope.nomeCadFiltroSel = null;
            $scope.nomeUsuFiltroSel = null;
            document.getElementById("idCadRespostaFiltro").innerText = "Cadência";
            $scope.buscarCadenciasResp = "";
            $scope.usuarioFiltro = null;
            document.getElementById("idUsuariosRespostaFiltro").innerText =
                "Usuários";
            $scope.buscarUsuariosResp = "";
            $scope.statusRespostaFiltro = null;
            document.getElementById("idStatusRespostaFiltro").innerText =
                "Status da resposta";
            $("#sltDataEnvioTelaResp").val("");
            (_b = (_a = $("#sltDataEnvioTelaResp")) === null || _a === void 0 ? void 0 : _a.data("daterangepicker")) === null || _b === void 0 ? void 0 : _b.setStartDate(moment());
            (_d = (_c = $("#sltDataEnvioTelaResp")) === null || _c === void 0 ? void 0 : _c.data("daterangepicker")) === null || _d === void 0 ? void 0 : _d.setEndDate(moment());
            $("#sltDataRespondidosTelaResp").val("");
            (_f = (_e = $("#sltDataRespondidosTelaResp")) === null || _e === void 0 ? void 0 : _e.data("daterangepicker")) === null || _f === void 0 ? void 0 : _f.setStartDate(moment());
            (_h = (_g = $("#sltDataRespondidosTelaResp")) === null || _g === void 0 ? void 0 : _g.data("daterangepicker")) === null || _h === void 0 ? void 0 : _h.setEndDate(moment());
            _pagina = -10;
            _campoOrdem = "dataOrd";
            _ordem = -1;
            _limite = 10;
            _parametrosDinamicos = {
                dataResposta: true,
                ordem: _campoOrdem,
                ordemAscDesc: _ordem,
                limit: _limite,
                tipoDaChave: $rootScope.Tipo,
            };
            $scope.consultarUsuaroFiltro();
        };
        $scope.consultarFiltros = function () {
            $http({
                method: "GET",
                url: "/filtros/usuarios",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (response) {
                $scope.usuarios = [];
                response.data.forEach(function (time) {
                    time.loginsAtivos.forEach(function (usuario) {
                        $scope.usuarios.push(usuario);
                        if (usuario.fotoPerfil)
                            usuario.fotoPerfil =
                                "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                    usuario._id.toString() +
                                    "/" +
                                    usuario.fotoPerfil;
                        else
                            usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                    });
                });
            });
        };
        $scope.consultarUsuaroFiltro = function () {
            $scope.cadencias = [];
            var usuario = $scope.usuarioFiltro ? $scope.usuarioFiltro : "";
            $http({
                method: "GET",
                url: "/filtros/cadencias",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: $scope.usuarioFiltro && $scope.usuarioFiltro != ""
                        ? "U"
                        : $rootScope.Tipo,
                    objectIdLogin: usuario,
                },
            }).then(function (response) {
                response.data.forEach(function (cadencia) {
                    $scope.cadencias.push(cadencia);
                });
            });
        };
        $scope.consultarEFecharPopupFiltrar = function () {
            $scope.filtrarRespostas();
            $scope.montarNomeFiltros();
            fazerConsultaGraficoSequencia = true;
            $scope.trocarGraficos("d");
            $scope.consultarTotaisRespostas("t");
        };
        $scope.filtrarRespostas = function () {
            $scope.buscar = "";
            $scope.buscaHabilitada = false;
            $scope.popupFiltrarRespostas = false;
            _itemSelecionado = null;
            $scope.mostrarAlterarResp = false;
            document.getElementById("idTxtOrdenarResp").innerText = "Ordenar";
            $scope.nenhumaSelecionada = true;
            $(document).ready(function () {
                $("#tbleRespostas").animate({ scrollTop: 0 }, 800);
                return false;
            });
            if (document.getElementById("idStatusRespostaFiltro").innerText ==
                "Status da resposta" &&
                $scope.cadenciaFiltro == null &&
                $("#sltDataEnvioTelaResp")[0].value == "" &&
                $("#sltDataRespondidosTelaResp")[0].value == "" &&
                ($scope.usuarioFiltro == null || $scope.usuarioFiltro == undefined)) {
                $scope.filtroHabilitado = false;
                $("#idBtnFiltroResp")
                    .removeClass()
                    .addClass("btnVoltarPadroNovoFiltro");
                $("#idImgFiltroResp").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
            }
            else {
                $scope.filtroHabilitado = true;
                $("#idBtnFiltroResp")
                    .removeClass()
                    .addClass("btnVoltarPadroNovoFiltroHabilitado");
                $("#idImgFiltroResp").attr("src", "".concat($rootScope.bucket, "/filtro-wt-novo.svg"));
            }
            if (_parametrosDinamicos != null) {
                _pagina = -10;
                _campoOrdem = "dataOrd";
                _ordem = -1;
                _limite = 10;
                _parametrosDinamicos = {
                    objectId: null,
                    tipoDaChave: null,
                    dataInicial: null,
                    dataFinal: null,
                    dataRespostaInicial: null,
                    dataRespostaFinal: null,
                    dataResposta: true,
                    statusResposta: null,
                    sequencia: null,
                    ordem: _campoOrdem,
                    ordemAscDesc: _ordem,
                    skip: _pagina,
                    limit: _limite,
                };
                if ($scope.cadenciaFiltro && $scope.cadenciaFiltro != null) {
                    _parametrosDinamicos.tipoDaChave = "C";
                    _parametrosDinamicos.objectId = $scope.cadenciaFiltro;
                }
                else {
                    if ($scope.usuarioFiltro && $scope.usuarioFiltro != null) {
                        _parametrosDinamicos.tipoDaChave = "U";
                        _parametrosDinamicos.objectId = $scope.usuarioFiltro;
                    }
                    else
                        _parametrosDinamicos.tipoDaChave = $rootScope.Tipo;
                }
                if ($("#sltDataEnvioTelaResp")[0].value != "") {
                    _parametrosDinamicos.dataInicial = $scope.dataInicioFiltro;
                    _parametrosDinamicos.dataFinal = $scope.dataFinalFiltro;
                }
                if ($("#sltDataRespondidosTelaResp")[0].value != "") {
                    _parametrosDinamicos.dataRespostaInicial =
                        $scope.dataInicioFiltroResposta;
                    _parametrosDinamicos.dataRespostaFinal =
                        $scope.dataFinalFiltroResposta;
                }
                if ($scope.statusRespostaFiltro)
                    _parametrosDinamicos.statusResposta = $scope.statusRespostaFiltro;
                if ($scope.sequenciaFiltro)
                    _parametrosDinamicos.sequencia = parseInt($scope.sequenciaFiltro);
            }
            $scope.consultarRespostas();
        };
        $scope.atualizarRespostas = function () {
            $rootScope.loaderAtivo = true;
            $("#pagRespostas").addClass("blur");
            $("#menuPrincipal").addClass("blur");
            $scope.semRespostas = false;
            $http({
                method: "GET",
                url: "/configuracoesEmail/capturarRespostaPorObjectIdLogin/",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    objectIdLogin: $scope.usuarioFiltro && $scope.usuarioFiltro != ""
                        ? $scope.usuarioFiltro
                        : null,
                },
            }).then(function (response) { });
            setTimeout(function () {
                $scope.recarregarPagina();
            }, 11700);
        };
        $scope.verificarIntegracao = function () {
            $http({
                method: "GET",
                url: "/integracoes/consultarIntegracoes",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    status: "A",
                },
            }).then(function (rVerificarSeTemIntegracao) {
                if (rVerificarSeTemIntegracao.data &&
                    rVerificarSeTemIntegracao.data.length > 0) {
                    rVerificarSeTemIntegracao.data.forEach(function (iIntegracao) {
                        if (iIntegracao.application == "R") {
                            $scope.integracaoInbound = iIntegracao;
                        }
                        else if (iIntegracao.application == "E") {
                            $scope.integracaoPreVendas = iIntegracao;
                        }
                        else {
                            $scope.integracaoCRM = iIntegracao;
                        }
                    });
                }
            });
        };
        $scope.esconderEmptyStates = function () {
            $scope.semRespostasFiltro = false;
            $scope.semRespostasBusca = false;
            $scope.semRespostas = false;
        };
        $scope.recarregarPagina = function () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            _itemSelecionado = null;
            $scope.filtroHabilitado = false;
            $("#idBtnFiltroResp").removeClass().addClass("btnVoltarPadroNovoFiltro");
            $("#idImgFiltroResp").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
            $scope.nenhumaSelecionada = true;
            $scope.mostrarAlterarResp = false;
            document.getElementById("idTxtOrdenarResp").innerText = "Ordenar";
            $scope.buscar = "";
            $scope.nomeStatusRespFiltroSel = null;
            $scope.nomeCadFiltroSel = null;
            $scope.nomeUsuFiltroSel = null;
            $scope.usuarioFiltro = null;
            $("#sltDataEnvioTelaResp").val("");
            (_b = (_a = $("#sltDataEnvioTelaResp")) === null || _a === void 0 ? void 0 : _a.data("daterangepicker")) === null || _b === void 0 ? void 0 : _b.setStartDate(moment());
            (_d = (_c = $("#sltDataEnvioTelaResp")) === null || _c === void 0 ? void 0 : _c.data("daterangepicker")) === null || _d === void 0 ? void 0 : _d.setEndDate(moment());
            $("#sltDataRespondidosTelaResp").val("");
            (_f = (_e = $("#sltDataRespondidosTelaResp")) === null || _e === void 0 ? void 0 : _e.data("daterangepicker")) === null || _f === void 0 ? void 0 : _f.setStartDate(moment());
            (_h = (_g = $("#sltDataRespondidosTelaResp")) === null || _g === void 0 ? void 0 : _g.data("daterangepicker")) === null || _h === void 0 ? void 0 : _h.setEndDate(moment());
            $scope.txtNomeFiltros = "";
            $scope.removerFiltros();
            $scope.esconderEmptyStates();
            $scope.consultarRespostas();
            $scope.fecharAnalytics();
        };
        $scope.alterarOrdenacaoRespostas = function () {
            $scope.ordenarRespostas(_campoOrdem);
            $scope.ordenarRespAberto = false;
            $("#idOrdenarResp").hide();
        };
        $scope.abrirFiltroStatusResp = function () {
            $scope.filtroStatusRespAberto = !$scope.filtroStatusRespAberto;
            if ($scope.filtroStatusRespAberto)
                $("#idFiltroStatusResp").show();
            else
                $("#idFiltroStatusResp").hide();
        };
        $scope.filtrarResp = function (pDados) {
            if (pDados == "P")
                document.getElementById("idStatusRespostaFiltro").innerText =
                    "Com interesse";
            else if (pDados == "C")
                document.getElementById("idStatusRespostaFiltro").innerText = "Neutra";
            else if (pDados == "N")
                document.getElementById("idStatusRespostaFiltro").innerText =
                    "Sem interesse";
            else
                document.getElementById("idStatusRespostaFiltro").innerText =
                    "Sem classificação";
            $scope.abrirFiltroStatusResp();
            $scope.statusRespostaFiltro = pDados;
            $scope.nomeStatusRespFiltroSel = document.getElementById("idStatusRespostaFiltro").innerText;
        };
        $(document).mouseup(function (e) {
            var filtroResp = $("#idFiltroStatusResp");
            if (!filtroResp.is(e.target) && filtroResp.has(e.target).length === 0) {
                $scope.filtroStatusRespAberto = false;
                filtroResp.hide();
            }
        });
        $scope.abrirFiltroCadResp = function () {
            $scope.filtroCadRespAberto = !$scope.filtroCadRespAberto;
            if ($scope.filtroCadRespAberto) {
                $("#idFiltroCadResp").show();
                $("#idListaCadResp").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("buscarCadenciasResp").focus();
                }, 10);
            }
            else
                $("#idFiltroCadResp").hide();
        };
        $scope.selecionarCadResp = function (pDados) {
            if (pDados || pDados != null) {
                $scope.cadenciaFiltro = pDados._id;
                document.getElementById("idCadRespostaFiltro").innerText = pDados.nome;
            }
            else {
                $scope.cadenciaFiltro = null;
                document.getElementById("idCadRespostaFiltro").innerText =
                    "Sem cadência";
            }
            $scope.nomeCadFiltroSel = pDados.nome;
            $scope.abrirFiltroCadResp();
        };
        $(document).mouseup(function (e) {
            var filtroCad = $("#idFiltroCadResp");
            if (!filtroCad.is(e.target) && filtroCad.has(e.target).length === 0) {
                $scope.filtroUsuariosRespAberto = false;
                filtroCad.hide();
            }
        });
        $scope.abrirFiltroUsuariosResp = function () {
            $scope.filtroUsuariosRespAberto = !$scope.filtroUsuariosRespAberto;
            if ($scope.filtroUsuariosRespAberto) {
                $("#idFiltroUsuariosResp").show();
                $("#idListaUsuResp").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("buscarUsuariosResp").focus();
                }, 10);
            }
            else
                $("#idFiltroUsuariosResp").hide();
        };
        $scope.selecionarUsuarioResp = function (pDados) {
            $scope.usuarioFiltro = pDados._id;
            $scope.nomeUsuFiltroSel = pDados.nome;
            document.getElementById("idUsuariosRespostaFiltro").innerText =
                pDados.nome;
            $scope.filtroUsuariosRespAberto = false;
            $("#idFiltroUsuariosResp").hide();
            document.getElementById("idCadRespostaFiltro").innerText = "Cadência";
            $scope.nomeCadFiltroSel = "";
            $scope.cadenciaFiltro = null;
            $scope.consultarUsuaroFiltro();
        };
        $(document).mouseup(function (e) {
            var filtroUsuarioResp = $("#idFiltroUsuariosResp");
            var inputFiltroUsuario = $("#buscarUsuariosResp");
            if (!filtroUsuarioResp.is(e.target) &&
                filtroUsuarioResp.has(e.target).length === 0) {
                $scope.filtroUsuariosRespAberto = false;
                filtroUsuarioResp.hide();
            }
            if (inputFiltroUsuario.is(e.target)) {
                $scope.filtroUsuariosRespAberto = true;
            }
        });
        $scope.abrirOrdenarResp = function () {
            $scope.ordenarRespAberto = !$scope.ordenarRespAberto;
            if ($scope.ordenarRespAberto) {
                $("#idOrdenarResp").show();
            }
            else
                $("#idOrdenarResp").hide();
        };
        $scope.ordenarRespostas = function (pDados) {
            _campoOrdem = pDados;
            var txtOrdenacao = document.getElementById("idTxtOrdenarResp").innerText;
            var txtOrdenacaoNome = document.getElementById("txtOrdemNome").innerText;
            var txtOrdenacaoEnvio = document.getElementById("txtOrdemDataEnvio").innerText;
            var txtOrdenacaoResp = document.getElementById("txtOrdemDataResposta").innerText;
            $scope.mostrarAlterarResp = true;
            document.getElementById("txtOrdemNome").innerText = "A-Z";
            document.getElementById("txtOrdemDataEnvio").innerText = "Data de envio";
            document.getElementById("txtOrdemDataResposta").innerText =
                "Data da resposta";
            if (txtOrdenacao == "Ordenar") {
                _ordem = 1;
                if (pDados == "nomeContato") {
                    document.getElementById("idTxtOrdenarResp").innerText = "A-Z";
                    document.getElementById("txtOrdemNome").innerText = "Z-A";
                }
                else if (pDados == "dataEnvioOrdem") {
                    document.getElementById("idTxtOrdenarResp").innerText =
                        "Envios antigos primeiro";
                    document.getElementById("txtOrdemDataEnvio").innerText =
                        "Envios novos";
                }
                else if (pDados == "dataOrd") {
                    document.getElementById("idTxtOrdenarResp").innerText =
                        "Respostas antigas primeiro";
                    document.getElementById("txtOrdemDataResposta").innerText =
                        "Respostas novas";
                }
            }
            else {
                if (pDados == "nomeContato") {
                    if (txtOrdenacaoNome == "A-Z") {
                        document.getElementById("idTxtOrdenarResp").innerText = "A-Z";
                        document.getElementById("txtOrdemNome").innerText = "Z-A";
                        _ordem = 1;
                        $scope.ordenacaoResp = "Crescente";
                    }
                    else {
                        document.getElementById("idTxtOrdenarResp").innerText = "Z-A";
                        document.getElementById("txtOrdemNome").innerText = "A-Z";
                        _ordem = -1;
                        $scope.ordenacaoResp = "Decrescente";
                    }
                }
                else if (pDados == "dataEnvioOrdem") {
                    if (txtOrdenacaoEnvio == "Envios antigos" ||
                        txtOrdenacaoEnvio == "Data de envio") {
                        document.getElementById("idTxtOrdenarResp").innerText =
                            "Envios antigos primeiro";
                        document.getElementById("txtOrdemDataEnvio").innerText =
                            "Envios novos";
                        _ordem = 1;
                        $scope.ordenacaoResp = "Crescente";
                    }
                    else {
                        document.getElementById("idTxtOrdenarResp").innerText =
                            "Envios novos primeiro";
                        document.getElementById("txtOrdemDataEnvio").innerText =
                            "Envios antigos";
                        _ordem = -1;
                        $scope.ordenacaoResp = "Decrescente";
                    }
                }
                else {
                    if (txtOrdenacaoResp == "Respostas antigas" ||
                        txtOrdenacaoResp == "Data da resposta") {
                        document.getElementById("idTxtOrdenarResp").innerText =
                            "Respostas antigas primeiro";
                        document.getElementById("txtOrdemDataResposta").innerText =
                            "Respostas novas";
                        _ordem = 1;
                        $scope.ordenacaoResp = "Crescente";
                    }
                    else {
                        document.getElementById("idTxtOrdenarResp").innerText =
                            "Respostas novas primeiro";
                        document.getElementById("txtOrdemDataResposta").innerText =
                            "Respostas antigas";
                        _ordem = -1;
                        $scope.ordenacaoResp = "Decrescente";
                    }
                }
            }
            _pagina = -10;
            _parametrosDinamicos.ordem = _campoOrdem;
            _parametrosDinamicos.ordemAscDesc = _ordem;
            $scope.tituloOrdenacao =
                document.getElementById("idTxtOrdenarResp").innerText;
            $scope.abrirOrdenarResp();
            $scope.consultarRespostas();
        };
        $(document).mouseup(function (e) {
            var divOrdenar = $("#idOrdenarResp");
            if (!divOrdenar.is(e.target) && divOrdenar.has(e.target).length === 0) {
                $scope.ordenarRespAberto = false;
                divOrdenar.hide();
            }
        });
        $scope.abrirClassificacaoResp = function () {
            $scope.classificacaoRespAberto = !$scope.classificacaoRespAberto;
            if ($scope.classificacaoRespAberto) {
                $("#idDivClassificarResp").show();
            }
            else
                $("#idDivClassificarResp").hide();
        };
        $(document).mouseup(function (e) {
            var divClass = $("#idDivClassificarResp");
            if (!divClass.is(e.target) && divClass.has(e.target).length === 0) {
                $scope.classificacaoRespAberto = false;
                divClass.hide();
            }
        });
        $scope.abrirAnalytics = function () {
            $scope.mostrarAnalytics = !$scope.mostrarAnalytics;
            if ($scope.mostrarAnalytics) {
                $(".dvTabelaMensagens").css("height", "104%");
                $(".dvRespostaSelecionada").css("height", "81%");
                $(".tabelaRespotasGeral").css("padding-left", "10px");
                $("#divPrincipalResp").addClass("classeAnalyticsAberto");
                $scope.loaderGraficos = true;
                $scope.mostrarAnalytics = true;
                $scope.loaderIndicadores = true;
                $scope.emptyGraficoAnalytics = false;
                fazerConsultaGraficoSequencia = true;
                $scope.fazerConsultaAnalytics();
            }
            else {
                $(".dvTabelaMensagens").css("height", "100%");
                $(".dvRespostaSelecionada").css("height", "77%");
                $(".tabelaRespotasGeral").css("padding-left", "15px");
                $("#divPrincipalResp").removeClass("classeAnalyticsAberto");
            }
        };
        $scope.fecharAnalytics = function () {
            $(".dvTabelaMensagens").css("height", "100%");
            $(".dvRespostaSelecionada").css("height", "77%");
            $(".tabelaRespotasGeral").css("padding-left", "15px");
            $("#divPrincipalResp").removeClass("classeAnalyticsAberto");
            $scope.mostrarAnalytics = false;
        };
        $scope.mudarRespostasAnalytics = function (pDados) {
            removerCoresIndicadores();
            $scope.IndicadorGrafico = pDados;
            $scope.emptyGraficoAnalytics = false;
            if (pDados == "t") {
                $scope.nomeIndicadorAnalytics = "Todas";
            }
            else if (pDados == "p") {
                $scope.imgInteresse = "-selecionado";
                $scope.nomeIndicadorAnalytics = "Com interesse";
                if ($scope.qtdComInteresse < 1)
                    $scope.emptyGraficoAnalytics = true;
            }
            else if (pDados == "c") {
                $scope.imgNeutra = "-selecionado";
                $scope.nomeIndicadorAnalytics = "Neutra";
                if ($scope.qtdNeutra < 1)
                    $scope.emptyGraficoAnalytics = true;
            }
            else if (pDados == "n") {
                $scope.imgSemInteresse = "-selecionado";
                $scope.nomeIndicadorAnalytics = "Sem interesse";
                if ($scope.qtdSemInteresse < 1)
                    $scope.emptyGraficoAnalytics = true;
            }
            else {
                $scope.imgSemClassificacao = "-selecionado";
                $scope.nomeIndicadorAnalytics = "Sem classificação";
                if ($scope.qtdSemClassificacao < 1)
                    $scope.emptyGraficoAnalytics = true;
            }
            $scope.montarGraficoAnalyticsDiaSemana(pDados);
            $scope.montarGraficoAnalyticsHoraDia(pDados);
            if (!fazerConsultaGraficoSequencia) {
                $scope.montarGraficoSequencia(pDados);
            }
        };
        $scope.consultarTotaisRespostas = function (pDados) {
            $http({
                method: "GET",
                url: "/disparos/consultarRespostaTotal",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: _parametrosDinamicos.tipoDaChave,
                    objectId: _parametrosDinamicos.objectId,
                    statusResposta: _parametrosDinamicos.statusResposta,
                    dataInicio: _parametrosDinamicos.dataInicial,
                    dataFim: _parametrosDinamicos.dataFinal,
                    dataInicioResposta: _parametrosDinamicos.dataRespostaInicial,
                    dataFimResposta: _parametrosDinamicos.dataRespostaFinal,
                },
            }).then(function (rTotais) {
                $scope.qtdTotal = rTotais.data[0].quantidadeTotalRespostas;
                $scope.qtdComInteresse = rTotais.data[0].quantidadeTotalComInteresses;
                $scope.qtdNeutra = rTotais.data[0].quantidadeTotalNeutro;
                $scope.qtdSemInteresse = rTotais.data[0].quantidadeTotalSemInteresses;
                $scope.qtdSemClassificacao = rTotais.data[0].quantidadeTotalNulo;
            });
            $http({
                method: "GET",
                url: "/disparos/consultarGraficoAnalyticsRespostas",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: _parametrosDinamicos.tipoDaChave,
                    objectId: _parametrosDinamicos.objectId,
                    statusResposta: _parametrosDinamicos.statusResposta,
                    dataInicio: _parametrosDinamicos.dataInicial,
                    dataFim: _parametrosDinamicos.dataFinal,
                    dataInicioResposta: _parametrosDinamicos.dataRespostaInicial,
                    dataFimResposta: _parametrosDinamicos.dataRespostaFinal,
                    tipoConsulta: pDados,
                    origem: "r",
                },
            }).then(function (rTotais) {
                rTotais.data.hora.forEach(function (dados) {
                    dados._id.hora = new Date(dados.dataresposta[0]).getHours();
                });
                graficoDiaSemana = rTotais.data.semana;
                graficoHora = rTotais.data.hora;
                $scope.mudarRespostasAnalytics("t");
                $scope.loaderIndicadores = false;
                $scope.loaderGraficos = false;
            });
        };
        $scope.fazerConsultaAnalytics = function () {
            $scope.trocarGraficos("d");
            $scope.consultarTotaisRespostas("t");
        };
        removerCoresIndicadores = function () {
            $scope.imgInteresse = "";
            $scope.imgNeutra = "";
            $scope.imgSemInteresse = "";
            $scope.imgSemClassificacao = "";
        };
        $scope.abrirVincularContato = function () {
            $scope.consultarFiltros();
            $scope.consultarUsuaroFiltro();
            $("#idFundoPopup").fadeIn();
            $scope.popupVincularContato = true;
        };
        $scope.fecharVincularContato = function (pFechar) {
            $("#idFundoPopup").fadeOut();
            $scope.popupVincularContato = false;
            $scope.buscarCadenciaVincularContato = "";
            document.getElementById("idCadVincularContato").innerText =
                "Nome da cadência";
            $scope.buscarSequenciaVincularContato = "";
            document.getElementById("idSeqVincularContato").innerText =
                "Sequência da cadência";
            $scope.buscarContatoVincularContato = "";
            document.getElementById("idContatoVincularContato").innerText =
                "Contato da resposta";
        };
        $scope.montarGraficoAnalyticsDiaSemana = function (pDados) {
            var arrayUm = [0, 0, 0, 0, 0, 0, 0];
            if (pDados == "t") {
                graficoDiaSemana.forEach(function (item) {
                    if (item._id.dia_semana == 1)
                        arrayUm[0] = item.quantidadeRespostas;
                    else if (item._id.dia_semana == 2)
                        arrayUm[1] = item.quantidadeRespostas;
                    else if (item._id.dia_semana == 3)
                        arrayUm[2] = item.quantidadeRespostas;
                    else if (item._id.dia_semana == 4)
                        arrayUm[3] = item.quantidadeRespostas;
                    else if (item._id.dia_semana == 5)
                        arrayUm[4] = item.quantidadeRespostas;
                    else if (item._id.dia_semana == 6)
                        arrayUm[5] = item.quantidadeRespostas;
                    else if (item._id.dia_semana == 7)
                        arrayUm[6] = item.quantidadeRespostas;
                });
            }
            else if (pDados == "p") {
                graficoDiaSemana.forEach(function (item) {
                    if (item._id.dia_semana == 1)
                        arrayUm[0] = item.quantidadeInteresses;
                    else if (item._id.dia_semana == 2)
                        arrayUm[1] = item.quantidadeInteresses;
                    else if (item._id.dia_semana == 3)
                        arrayUm[2] = item.quantidadeInteresses;
                    else if (item._id.dia_semana == 4)
                        arrayUm[3] = item.quantidadeInteresses;
                    else if (item._id.dia_semana == 5)
                        arrayUm[4] = item.quantidadeInteresses;
                    else if (item._id.dia_semana == 6)
                        arrayUm[5] = item.quantidadeInteresses;
                    else if (item._id.dia_semana == 7)
                        arrayUm[6] = item.quantidadeInteresses;
                });
            }
            else if (pDados == "c") {
                graficoDiaSemana.forEach(function (item) {
                    if (item._id.dia_semana == 1)
                        arrayUm[0] = item.quantidadeNeutra;
                    else if (item._id.dia_semana == 2)
                        arrayUm[1] = item.quantidadeNeutra;
                    else if (item._id.dia_semana == 3)
                        arrayUm[2] = item.quantidadeNeutra;
                    else if (item._id.dia_semana == 4)
                        arrayUm[3] = item.quantidadeNeutra;
                    else if (item._id.dia_semana == 5)
                        arrayUm[4] = item.quantidadeNeutra;
                    else if (item._id.dia_semana == 6)
                        arrayUm[5] = item.quantidadeNeutra;
                    else if (item._id.dia_semana == 7)
                        arrayUm[6] = item.quantidadeNeutra;
                });
            }
            else if (pDados == "n") {
                graficoDiaSemana.forEach(function (item) {
                    if (item._id.dia_semana == 1)
                        arrayUm[0] = item.quantidadeSem;
                    else if (item._id.dia_semana == 2)
                        arrayUm[1] = item.quantidadeSem;
                    else if (item._id.dia_semana == 3)
                        arrayUm[2] = item.quantidadeSem;
                    else if (item._id.dia_semana == 4)
                        arrayUm[3] = item.quantidadeSem;
                    else if (item._id.dia_semana == 5)
                        arrayUm[4] = item.quantidadeSem;
                    else if (item._id.dia_semana == 6)
                        arrayUm[5] = item.quantidadeSem;
                    else if (item._id.dia_semana == 7)
                        arrayUm[6] = item.quantidadeSem;
                });
            }
            else if (pDados == "s") {
                graficoDiaSemana.forEach(function (item) {
                    if (item._id.dia_semana == 1)
                        arrayUm[0] = item.quantidadeNulo;
                    else if (item._id.dia_semana == 2)
                        arrayUm[1] = item.quantidadeNulo;
                    else if (item._id.dia_semana == 3)
                        arrayUm[2] = item.quantidadeNulo;
                    else if (item._id.dia_semana == 4)
                        arrayUm[3] = item.quantidadeNulo;
                    else if (item._id.dia_semana == 5)
                        arrayUm[4] = item.quantidadeNulo;
                    else if (item._id.dia_semana == 6)
                        arrayUm[5] = item.quantidadeNulo;
                    else if (item._id.dia_semana == 7)
                        arrayUm[6] = item.quantidadeNulo;
                });
            }
            var options = {
                chart: {
                    height: 200,
                    width: "100%",
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: "40px",
                    },
                },
                series: [
                    {
                        name: $scope.nomeIndicadorAnalytics,
                        data: arrayUm,
                    },
                ],
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: "#e8e8e8",
                    opacity: 0.5,
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
                    categories: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                    labels: {
                        style: {
                            fontSize: "10px",
                            colors: "#a0a0a0",
                        },
                    },
                },
                tooltip: {
                    marker: {
                        show: false,
                    },
                    followCursor: true,
                    x: {
                        show: false,
                    },
                    y: {
                        formatter: function (y) {
                            return formatarValor(y);
                        },
                    },
                },
                colors: ["#74c592"],
            };
            if (chartUm != null)
                chartUm.destroy();
            chartUm = new ApexCharts(document.querySelector("#chart_dia_semana"), options);
            chartUm.render();
        };
        $scope.montarGraficoAnalyticsHoraDia = function (pDados) {
            var arrayUm = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var arrayDois = [];
            graficoHora.forEach(function (item) {
                if (item._id.hora == 6)
                    arrayUm[0] = item;
                else if (item._id.hora == 7)
                    arrayUm[1] = item;
                else if (item._id.hora == 8)
                    arrayUm[2] = item;
                else if (item._id.hora == 9)
                    arrayUm[3] = item;
                else if (item._id.hora == 10)
                    arrayUm[4] = item;
                else if (item._id.hora == 11)
                    arrayUm[5] = item;
                else if (item._id.hora == 12)
                    arrayUm[6] = item;
                else if (item._id.hora == 13)
                    arrayUm[7] = item;
                else if (item._id.hora == 14)
                    arrayUm[8] = item;
                else if (item._id.hora == 15)
                    arrayUm[9] = item;
                else if (item._id.hora == 16)
                    arrayUm[10] = item;
                else if (item._id.hora == 17)
                    arrayUm[11] = item;
                else if (item._id.hora == 18)
                    arrayUm[12] = item;
                else if (item._id.hora == 19)
                    arrayUm[13] = item;
                else if (item._id.hora == 20)
                    arrayUm[14] = item;
                else if (item._id.hora == 21)
                    arrayUm[15] = item;
                else if (item._id.hora == 22)
                    arrayUm[16] = item;
            });
            if (pDados == "t") {
                arrayUm.forEach(function (item) {
                    if (item != 0)
                        arrayDois.push(item.quantidadeRespostas);
                    else
                        arrayDois.push(0);
                });
            }
            else if (pDados == "p") {
                arrayUm.forEach(function (item) {
                    if (item != 0)
                        arrayDois.push(item.quantidadeInteresses);
                    else
                        arrayDois.push(0);
                });
            }
            else if (pDados == "c") {
                arrayUm.forEach(function (item) {
                    if (item != 0)
                        arrayDois.push(item.quantidadeNeutra);
                    else
                        arrayDois.push(0);
                });
            }
            else if (pDados == "n") {
                arrayUm.forEach(function (item) {
                    if (item != 0)
                        arrayDois.push(item.quantidadeSem);
                    else
                        arrayDois.push(0);
                });
            }
            else if (pDados == "s") {
                arrayUm.forEach(function (item) {
                    if (item != 0)
                        arrayDois.push(item.quantidadeNulo);
                    else
                        arrayDois.push(0);
                });
            }
            var options = {
                chart: {
                    height: 200,
                    width: "108%",
                    type: "area",
                    toolbar: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: $scope.nomeIndicadorAnalytics,
                        data: arrayDois,
                    },
                ],
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100],
                    },
                },
                grid: {
                    borderColor: "#e8e8e8",
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
                    categories: [
                        6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
                    ],
                    labels: {
                        style: {
                            fontSize: "10px",
                            colors: "#a0a0a0",
                        },
                    },
                },
                stroke: {
                    curve: "straight",
                    width: 1,
                },
                tooltip: {
                    marker: {
                        show: false,
                    },
                    followCursor: true,
                    x: {
                        show: false,
                    },
                    y: {
                        formatter: function (y) {
                            return formatarValor(y);
                        },
                    },
                },
                colors: ["#74c592"],
            };
            if (chartDois != null)
                chartDois.destroy();
            chartDois = new ApexCharts(document.querySelector("#chart_horario"), options);
            chartDois.render();
        };
        $scope.abrirListaCadVincularCon = function () {
            $scope.filtroCadVincularCon = !$scope.filtroCadVincularCon;
            if ($scope.filtroCadVincularCon) {
                $("#idDivCadenciaVincularCon").show();
                $("#idListaCadVincularCon").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("buscarCadenciaVincularContato").focus();
                }, 10);
            }
            else
                $("#idDivCadenciaVincularCon").hide();
        };
        $scope.selecionarCadVincularContato = function (pDados) {
            document.getElementById("idCadVincularContato").innerText = pDados.nome;
            $scope.abrirListaCadVincularCon();
        };
        $(document).mouseup(function (e) {
            var filtroCad = $("#idDivCadenciaVincularCon");
            if (!filtroCad.is(e.target) && filtroCad.has(e.target).length === 0) {
                $scope.filtroCadVincularCon = false;
                filtroCad.hide();
            }
        });
        $scope.abrirListaSeqVincularCon = function () {
            $scope.filtroSeqVincularCon = !$scope.filtroSeqVincularCon;
            if ($scope.filtroSeqVincularCon) {
                $("#idDivSequenciaVincularCon").show();
                $("#idListaSeqVincularCon").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("buscarSequenciaVincularContato").focus();
                }, 10);
            }
            else
                $("#idDivSequenciaVincularCon").hide();
        };
        $scope.selecionarSeqVincularContato = function (pDados) {
            document.getElementById("idSeqVincularContato").innerText = pDados;
            $scope.abrirListaSeqVincularCon();
        };
        $(document).mouseup(function (e) {
            var filtroSeq = $("#idDivSequenciaVincularCon");
            if (!filtroSeq.is(e.target) && filtroSeq.has(e.target).length === 0) {
                $scope.filtroSeqVincularCon = false;
                filtroSeq.hide();
            }
        });
        $scope.abrirListaContatoVincularCon = function () {
            $scope.filtroContatoVincularCon = !$scope.filtroContatoVincularCon;
            if ($scope.filtroContatoVincularCon) {
                $("#idDivContatoVincularCon").show();
                $("#idListaSeqVincularCon").animate({ scrollTop: 0 }, 10);
                setTimeout(function () {
                    document.getElementById("buscarContatoVincularContato").focus();
                }, 10);
            }
            else
                $("#idDivContatoVincularCon").hide();
        };
        $scope.selecionarSeqVincularContato = function (pDados) {
            document.getElementById("idContatoVincularContato").innerText =
                pDados.nome;
            $scope.abrirListaContatoVincularCon();
        };
        $(document).mouseup(function (e) {
            var filtroCon = $("#idDivContatoVincularCon");
            if (!filtroCon.is(e.target) && filtroCon.has(e.target).length === 0) {
                $scope.filtroContatoVincularCon = false;
                filtroCon.hide();
            }
        });
        $scope.montarNomeFiltros = function () {
            var contagem = 0;
            $scope.txtNomeFiltros = "";
            if ($scope.nomeStatusRespFiltroSel != "" &&
                $scope.nomeStatusRespFiltroSel != null) {
                $scope.txtNomeFiltros =
                    "Filtrado por " + $scope.nomeStatusRespFiltroSel;
                contagem++;
            }
            if ($scope.nomeCadFiltroSel != "" && $scope.nomeCadFiltroSel != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros = "Filtrado por " + $scope.nomeCadFiltroSel)
                    : ($scope.txtNomeFiltros += " > " + $scope.nomeCadFiltroSel);
                contagem++;
            }
            if ($scope.nomeUsuFiltroSel != "" && $scope.nomeUsuFiltroSel != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros = "Filtrado por " + $scope.nomeUsuFiltroSel)
                    : ($scope.txtNomeFiltros += " > " + $scope.nomeUsuFiltroSel);
                contagem++;
            }
            if ($("#sltDataEnvioTelaResp")[0].value != "" &&
                $("#sltDataEnvioTelaResp")[0].value != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $("#sltDataEnvioTelaResp")[0].value)
                    : ($scope.txtNomeFiltros +=
                        " > " + $("#sltDataEnvioTelaResp")[0].value);
                contagem++;
            }
            if ($("#sltDataRespondidosTelaResp")[0].value != "" &&
                $("#sltDataRespondidosTelaResp")[0].value != null) {
                contagem == 0
                    ? ($scope.txtNomeFiltros =
                        "Filtrado por " + $("#sltDataRespondidosTelaResp")[0].value)
                    : ($scope.txtNomeFiltros +=
                        " > " + $("#sltDataRespondidosTelaResp")[0].value);
                contagem++;
            }
        };
        $scope.verificarIntegracao();
        $scope.consultarRespostas();
        $scope.enter = function (keyEvent) {
            if (keyEvent.which === 13) {
                _itemSelecionado = null;
                $("#idBtnFiltroResp")
                    .removeClass()
                    .addClass("btnVoltarPadroNovoFiltro");
                $("#idImgFiltroResp").attr("src", "".concat($rootScope.bucket, "/filtro.png"));
                $scope.mostrarAlterarResp = false;
                $scope.buscaHabilitada = true;
                $scope.filtroHabilitado = false;
                $scope.nenhumaSelecionada = true;
                document.getElementById("idTxtOrdenarResp").innerText = "Ordenar";
                $scope.removerFiltros();
                $scope.fecharAnalytics();
                $scope.consultarRespostas();
            }
        };
        $scope.trocarGraficos = function (pDados) {
            if (pDados == "d") {
                $scope.graficoDuplo = true;
                $scope.graficoUnico = false;
                $("#idDivPeriodo").addClass("divBtnMarcadoAnalytics");
                $("#idDivSequencia").removeClass("divBtnMarcadoAnalytics");
                $scope.textoGraficoAnalytics = "RESPOSTAS";
            }
            else {
                $scope.graficoDuplo = false;
                $scope.graficoUnico = true;
                $("#idDivPeriodo").removeClass("divBtnMarcadoAnalytics");
                $("#idDivSequencia").addClass("divBtnMarcadoAnalytics");
                $scope.textoGraficoAnalytics = "SEQUÊNCIAS";
                if (fazerConsultaGraficoSequencia)
                    $scope.consultarTotaisRespostasGraficoPorSequencia();
            }
        };
        $scope.consultarTotaisRespostasGraficoPorSequencia = function () {
            $scope.loaderGraficoUnicoAnalytics = true;
            $http({
                method: "GET",
                url: "/disparos/consultarRespostasPorSequenciaGrafico",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    tipoDaChave: _parametrosDinamicos.tipoDaChave,
                    objectId: _parametrosDinamicos.objectId,
                    statusResposta: _parametrosDinamicos.statusResposta,
                    dataInicio: _parametrosDinamicos.dataInicial,
                    dataFim: _parametrosDinamicos.dataFinal,
                    dataInicioResposta: _parametrosDinamicos.dataRespostaInicial,
                    dataFimResposta: _parametrosDinamicos.dataRespostaFinal,
                },
            }).then(function (rTotais) {
                $scope.loaderGraficoUnicoAnalytics = false;
                $scope.arrayGraficoSequencia =
                    rTotais.data.length > 0 ? rTotais.data : "";
                $scope.montarGraficoSequencia();
                fazerConsultaGraficoSequencia = false;
            });
        };
        $scope.montarGraficoSequencia = function (pDados) {
            var arraySequencia = [];
            var arrayUm = [];
            var descricaoUm = "";
            $scope.arrayGraficoSequencia.forEach(function (item) {
                arraySequencia.push(item._id + "º envio");
                arrayUm.push(item.qtdTotal);
                descricaoUm = "Todas";
            });
            var arrayDois = [];
            var descricaoDois = "";
            var corDois = "transparent";
            if (pDados == "p") {
                $scope.arrayGraficoSequencia.forEach(function (item) {
                    arrayDois.push(item.qtdInteresse);
                    descricaoDois = "Interesse";
                    corDois = "#75c18d";
                });
            }
            else if (pDados == "c") {
                $scope.arrayGraficoSequencia.forEach(function (item) {
                    arrayDois.push(item.qtdNeutra);
                    descricaoDois = "Neutra";
                    corDois = "#75c18d";
                });
            }
            else if (pDados == "n") {
                $scope.arrayGraficoSequencia.forEach(function (item) {
                    arrayDois.push(item.qtdSemInteresse);
                    descricaoDois = "Sem interesse";
                    corDois = "#75c18d";
                });
            }
            else if (pDados == "s") {
                $scope.arrayGraficoSequencia.forEach(function (item) {
                    arrayDois.push(item.qtdSemClassificacao);
                    descricaoDois = "Sem classificação";
                    corDois = "#75c18d";
                });
            }
            else if (pDados == "t") {
                $scope.arrayGraficoSequencia.forEach(function (item) {
                    arrayDois = [];
                    descricaoDois = "";
                    corDois = "transparent";
                });
            }
            var options = {
                chart: {
                    height: 230,
                    width: "102%",
                    offsetX: -22,
                    offsetY: 5,
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: "25px",
                    },
                },
                series: [
                    {
                        name: descricaoUm,
                        data: arrayUm,
                    },
                    {
                        name: descricaoDois,
                        data: arrayDois,
                    },
                ],
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    borderColor: "#e8e8e8",
                    opacity: 0.5,
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                xaxis: {
                    categories: arraySequencia,
                    labels: {
                        style: {
                            fontSize: "10px",
                            colors: "#a0a0a0",
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    horizontalAlign: "right",
                    offsetY: -10,
                    fontSize: "10px",
                    labels: {
                        colors: "#adadad",
                    },
                    markers: {
                        width: 8,
                        height: 8,
                    },
                },
                tooltip: {
                    marker: {
                        show: false,
                    },
                    followCursor: true,
                    x: {
                        show: false,
                    },
                    y: {
                        formatter: function (y) {
                            return formatarValor(y);
                        },
                    },
                },
                colors: ["#979797", corDois],
            };
            if (chartTres != null)
                chartTres.destroy();
            chartTres = new ApexCharts(document.querySelector("#chart_agrupado_sequencia"), options);
            chartTres.render();
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.trackScreenAccessMixpanelEvent();
        $scope.consultarCapturasHoje();
    },
]);
