angular.module("ramperv3").directive("ngShowPermission", [
    "ngShowDirective",
    "PermissionamentoService",
    function (ngShowDirective, PermissionamentoService) {
        var ngShow = ngShowDirective[0];
        return {
            priority: ngShow.priority + 1,
            restrict: ngShow.restrict,
            link: function ($scope, _, $attr) {
                var permission = $scope.$eval($attr.ngShowPermission);
                var initialNgShow = $attr.ngShow ? $scope.$eval($attr.ngShow) : true;
                var hasAllowAccessToScreenAction = PermissionamentoService.allowAccessToScreenAction(permission.screen, permission.action);
                $attr.ngShow = function () {
                    return initialNgShow && hasAllowAccessToScreenAction;
                };
                ngShow.link.apply(ngShow, arguments);
            },
        };
    },
]);
