"use strict";
angular.module("ramperv3").service("PermissionamentoService", [
    "$rootScope",
    "LoginService",
    "$http",
    "$q",
    function ($rootScope, LoginService, $http, $q) {
        var self = this;
        function isObjFalsy(obj) {
            if (!obj) {
                return true;
            }
            return Object.entries(obj).length <= 0;
        }
        this.setPermissionamento = function (permissoes) {
            if (isObjFalsy(permissoes)) {
                return LoginService.logout();
            }
            $rootScope.permissoes = {};
            $rootScope.permissoes.tipoUsuario = permissoes.userType;
            $rootScope.permissoes.produtos = permissoes.products;
            $rootScope.permissoes.cadencias = permissoes.permissions.cadence;
            $rootScope.permissoes.respostas = permissoes.permissions.response;
            $rootScope.permissoes.relatorios = permissoes.permissions.report;
            $rootScope.permissoes.times = permissoes.permissions.team;
            $rootScope.permissoes.segmentacoes = permissoes.permissions.segmentation;
            $rootScope.permissoes.contatos = permissoes.permissions.contacts;
            $rootScope.permissoes.templates = permissoes.permissions.template;
            $rootScope.permissoes.configuracoes = permissoes.permissions.settings;
            $rootScope.permissoes.edools = permissoes.permissions.edools;
            $rootScope.permissoes.integracoes = permissoes.permissions.integration;
            $rootScope.permissoes.ligacoes = permissoes.permissions.call;
            $rootScope.permissoes.chat = permissoes.permissions.chat;
            $rootScope.permissoes.creditoPagamentos =
                permissoes.permissions.creditPayments;
            $rootScope.permissoes.descobrirContatos =
                permissoes.permissions.captureContacts;
        };
        // TODO: remover assim que for desenvolvido o permissionamento definitivo
        this.fetchPermissionamentoTemporario = function () {
            return $http({
                method: "GET",
                url: "/permissoes/temp",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            })
                .then(function (response) {
                return response.data;
            })
                .catch(function () {
                return {
                    captureContacts: { screen: false },
                };
            });
        };
        this.fetchPermissionamento = function () {
            return $http({
                method: "GET",
                url: "/permissoes",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            })
                .then(function (response) {
                return self
                    .fetchPermissionamentoTemporario()
                    .then(function (res) {
                    self.overrideTemporaryPermission(response.data.user.permissions, res);
                })
                    .then(function () {
                    return self.setPermissionamento(response.data.user);
                });
                // TODO: voltar como era apos o permissionamento temporario acabar
                // self.setPermissionamento(response.data.user);
            })
                .catch(function () {
                return LoginService.logout();
            });
        };
        this.overrideTemporaryPermission = function (permissions, tempPermissions) {
            var temporaryPermissionKeys = ["captureContacts"];
            temporaryPermissionKeys.forEach(function (key) {
                var temp = tempPermissions[key];
                var perm = permissions[key];
                if (temp === null || temp === undefined) {
                    return;
                }
                if (perm === null || perm === undefined) {
                    permissions[key] = temp;
                    return;
                }
                Object.entries(perm).forEach(function (_a) {
                    var k = _a[0], v = _a[1];
                    if (k === "seeBanner" && permissions["captureContacts"]["capture"]) {
                        permissions[key][k] = false;
                        return;
                    }
                    if (v) {
                        return;
                    }
                    permissions[key][k] = temp[k];
                });
            });
        };
        this.allowAccessToScreen = function (property) {
            var userNotAuthenticated = "Not Authenticated";
            var userNotAllowed = "Not Allowed";
            if (isObjFalsy($rootScope.permissoes)) {
                return $q.reject(userNotAuthenticated);
            }
            if (isObjFalsy($rootScope.permissoes[property])) {
                return $q.reject(userNotAllowed);
            }
            if ($rootScope.permissoes[property].screen === true) {
                return $q.resolve(true);
            }
            return $q.reject(userNotAllowed);
        };
        this.allowAccessToScreenAction = function (screen, action) {
            if (isObjFalsy($rootScope.permissoes)) {
                return false;
            }
            if (isObjFalsy($rootScope.permissoes[screen])) {
                return false;
            }
            if ($rootScope.permissoes[screen][action] === true) {
                return true;
            }
            return false;
        };
    },
]);
