angular.module("ramperv3").controller("ResumoController", [
    "$rootScope",
    "$scope",
    "$http",
    function ($rootScope, $scope, $http) {
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        var _objeto;
        var _estatisticas;
        var _filtros;
        var _pagina = -11;
        var _ordem = 1;
        var _limite = 10;
        var endDate = new Date();
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        $rootScope.popupInfo = false;
        $scope.$on("getMetricas", function (event, args) {
            $rootScope.popupInfo = false;
            _objeto = args.objeto;
            _filtros = {
                tipoDaChave: args.tela,
                objectId: args.objectId,
            };
            $scope.consultarResumo(_filtros);
        });
        $scope.consultarResumo = function (pTipo) { };
        $scope.$on("getResumo", function (event, args) {
            $rootScope.popupInfo = false;
            _objeto = args.objeto;
            _filtros = {
                tipoDaChave: args.tela,
                objectId: args.objectId,
                objectIdLogin: args.objectIdLogin,
            };
            $scope.consultarResumos(_filtros);
        });
        $scope.consultarResumos = function (pFiltros) {
            $http({
                method: "GET",
                url: "/geral/resumos",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
                params: {
                    objectIdLogin: pFiltros.objectIdLogin,
                    objectIdDisparo: pFiltros.objectId,
                    dataFinal: new Date(),
                },
            }).then(function (response) {
                $rootScope.loaderAtivo = false;
                $("#dvBlur").removeClass("blur");
                $scope.totalEnviosResumo = response.data.dataHora.totalEnvios;
                if (response.data.dataHora.totalEnvios > 0) {
                    //Total Envio
                    $scope.tempoEconomizadoEnvios = $scope.totalEnviosResumo * 7;
                    $scope.tempoEconomizadoEnviosHoras = ($scope.tempoEconomizadoEnvios / 60).toFixed(0);
                    //Total Abertura
                    $scope.percTotalAberturasResumo =
                        response.data.dataHora.percentualAbertos;
                    //Data_Hora - Abertura
                    $scope.diaSemanaMaisAbertoResumo =
                        response.data.dataHora.maisAberto._id.dia_semana;
                    $scope.horaMaisAbertoResumo =
                        response.data.dataHora.maisAberto._id.hora_abertura;
                    $scope.percDataHoraMaisAbertoResumo =
                        response.data.dataHora.maisAberto.percentual;
                    $scope.quantidadeDiaSemanaMaisAbertoResumo =
                        response.data.dataHora.maisAberto.quantidadeAbertos;
                    //Regiao - Abertura
                    $scope.cidadeMaisAbertoResumo =
                        response.data.regiao.maisAberto._id.cidade;
                    $scope.percRegiaoMaisAbertoResumo =
                        response.data.regiao.maisAberto.percentual;
                    $scope.totalRegiaoMaisAbertoResumo =
                        response.data.regiao.maisAberto.quantidadeAbertos;
                    //Device - Abertura
                    $scope.dispositivoMaisAbertoResumo =
                        response.data.device.maisAberto._id.device;
                    $scope.sistemaOperacionalMaiorAberturaResumo =
                        response.data.device.maisAberto._id.sistema_operacional;
                    $scope.percDeviceMaisAbertoResumo =
                        response.data.device.maisAberto.percentual;
                    $scope.totalDeviceMaisAbertoResumo =
                        response.data.device.maisAberto.quantidadeAbertos;
                    //Total Resposta
                    $scope.percTotalRespostasResumo =
                        response.data.dataHora.percentualRespostas;
                    //Data_Hora - Resposta
                    $scope.diaSemanaMaisRespondidoResumo =
                        response.data.dataHora.maisRespondido._id.dia_semana_resposta;
                    $scope.horaMaisRespondidoResumo =
                        response.data.dataHora.maisRespondido._id.hora_abertura_resposta;
                    $scope.percDataHoraMaisRespondidoResumo =
                        response.data.dataHora.maisRespondido.percentual;
                    $scope.quantidadeDiaSemanaMaisRespondidoResumo =
                        response.data.dataHora.maisRespondido.quantidadeRespondidos;
                    //Regiao - Resposta
                    $scope.cidadeMaisRespondidoResumo =
                        response.data.regiao.maisRespondido._id.cidade;
                    $scope.percRegiaoMaisRepondidoResumo =
                        response.data.regiao.maisRespondido.percentual;
                    $scope.totalRegiaoMaisRespondidoResumo =
                        response.data.regiao.maisRespondido.quantidadeRespondidos;
                    //Device - Resposta
                    $scope.dispositivoMaisRespondidoResumo =
                        response.data.device.maisRespondido._id.device;
                    $scope.sistemaOperacionalMaisRespondidoResumo =
                        response.data.device.maisRespondido._id.sistema_operacional;
                    $scope.percDeviceMaisRespondidoResumo =
                        response.data.device.maisRespondido.percentual;
                    $scope.totalDeviceMaisRespondidoResumo =
                        response.data.device.maisRespondido.quantidadeRespondidos;
                }
            });
        };
        $rootScope.zerarVariaveisResumoCadencia = function () {
            $scope.totalEnviosResumo = 0;
            $scope.tempoEconomizadoEnvios = 0;
            $scope.tempoEconomizadoEnviosHoras = 0;
            $scope.percTotalAberturasResumo = 0;
            $scope.diaSemanaMaisAbertoResumo = 0;
            $scope.horaMaisAbertoResumo = 0;
            $scope.percDataHoraMaisAbertoResumo = 0;
            $scope.quantidadeDiaSemanaMaisAbertoResumo = 0;
            $scope.cidadeMaisAbertoResumo = 0;
            $scope.percRegiaoMaisAbertoResumo = 0;
            $scope.totalRegiaoMaisAbertoResumo = 0;
            $scope.dispositivoMaisAbertoResumo = 0;
            $scope.sistemaOperacionalMaiorAberturaResumo = 0;
            $scope.percDeviceMaisAbertoResumo = 0;
            $scope.totalDeviceMaisAbertoResumo = 0;
            $scope.percTotalRespostasResumo = 0;
            $scope.diaSemanaMaisRespondidoResumo = 0;
            $scope.horaMaisRespondidoResumo = 0;
            $scope.percDataHoraMaisRespondidoResumo = 0;
            $scope.quantidadeDiaSemanaMaisRespondidoResumo = 0;
            $scope.cidadeMaisRespondidoResumo = 0;
            $scope.percRegiaoMaisRepondidoResumo = 0;
            $scope.totalRegiaoMaisRespondidoResumo = 0;
            $scope.dispositivoMaisRespondidoResumo = 0;
            $scope.sistemaOperacionalMaisRespondidoResumo = 0;
            $scope.percDeviceMaisRespondidoResumo = 0;
            $scope.totalDeviceMaisRespondidoResumo = 0;
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
    },
]);
