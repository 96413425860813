angular.module("ramperv3").controller("NovasContasController", [
    "$rootScope",
    "$scope",
    "$http",
    "$location",
    "AgenciaService",
    "VoipService",
    "MovideskService",
    function ($rootScope, $scope, $http, $location, AgenciaService, VoipService, MovideskService) {
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        var _urlAcesso = document.URL.substr(7, 6);
        $rootScope.mostrarMenu = false;
        $rootScope.loaderAtivo = false;
        var qtdErros;
        $("#txtNomeNC").removeClass("inptComErro");
        $("#txtSobrenomeNC").removeClass("inptComErro");
        $("#txtEmpresaNC").removeClass("inptComErro");
        $("#txtEmailNC").removeClass("inptComErro");
        $("#txtSenhaNC").removeClass("inptComErro");
        $("#dvMensagemNomeNC").hide();
        $("#dvMensagemSobrenomeNC").hide();
        $("#dvMensagemEmpresaNC").hide();
        $("#dvMensagemEmailNC").hide();
        $("#dvMensagemSenhaNC").hide();
        $("#dvBreakSenha").hide();
        $("#dvMensagemSenhaRepetidaNC").hide();
        $("#dvMensagemSenhaComparacaoNC").hide();
        $scope.nomeNC = "";
        $scope.sobrenomeNC = "";
        $scope.empresaNC = "";
        $scope.emailNC = "";
        $scope.senhaNC = "";
        $scope.linkJaUtilizado = false;
        $scope.objectIdNovaConta = null;
        $scope.retornarAleatorio = function () {
            var bgArray = [
                "login.png",
                "login02.png",
                "login03.png",
                "login04.png",
                "login05.png",
            ];
            var bg = bgArray[Math.floor(Math.random() * bgArray.length)];
            var path = "".concat($rootScope.bucket, "/");
            $("#bgNovaConta").css("background-image", "url(" + path + bg + ")");
        };
        $scope.show = function () {
            var valor = document.URL.indexOf("/novaConta?");
            if (valor > 0) {
                $scope.objectIdNovaConta = document.URL.substr(valor + 11);
                $http({
                    method: "GET",
                    url: "/novasContas/consultarPermissaoNovasContas",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": "0881d3992f",
                    },
                    params: {
                        objectIdNovaConta: $scope.objectIdNovaConta,
                    },
                }).then(function (response) {
                    if (response.data) {
                        if (response.data.email) {
                            $scope.linkJaUtilizado = true;
                        }
                    }
                    else {
                        $scope.linkJaUtilizado = true;
                    }
                });
            }
            else {
                $scope.linkJaUtilizado = true;
            }
        };
        $scope.show();
        $scope.mostrarSenha = function (senha) {
            if (senha == "senha") {
                var x = document.getElementById("idSenhaNC");
                if (x.type === "password") {
                    x.type = "text";
                }
                else {
                    x.type = "password";
                }
            }
            if (senha == "senhaRepetida") {
                var x = document.getElementById("idSenhaRepetidaNC");
                if (x.type === "password") {
                    x.type = "text";
                }
                else {
                    x.type = "password";
                }
            }
        };
        $scope.criarConta = function () {
            qtdErros = 0;
            if ($scope.nomeNC == null || $scope.nomeNC == "" || !$scope.nomeNC) {
                $("#txtNomeNC").addClass("inptComErro");
                $("#dvMensagemNomeNC").fadeIn();
                qtdErros++;
            }
            if ($scope.sobrenomeNC == null ||
                $scope.sobrenomeNC == "" ||
                !$scope.sobrenomeNC) {
                $("#txtSobrenomeNC").addClass("inptComErro");
                $("#dvMensagemSobrenomeNC").fadeIn();
                qtdErros++;
            }
            if ($scope.empresaNC == null ||
                $scope.empresaNC == "" ||
                !$scope.empresaNC) {
                $("#txtEmpresaNC").addClass("inptComErro");
                $("#dvMensagemEmpresaNC").fadeIn();
                qtdErros++;
            }
            if ($scope.emailNC == null || $scope.emailNC == "" || !$scope.emailNC) {
                $("#dvMensagemEmailNC").text("Informe seu e-mail para criar sua conta.");
                $("#txtEmailNC").addClass("inptComErro");
                $("#dvMensagemEmailNC").fadeIn();
                qtdErros++;
            }
            if ($scope.emailNC && !$scope.validarEmail($scope.emailNC)) {
                $("#dvMensagemEmailNC").text("Este e-mail não é válido.");
                $("#txtEmailNC").addClass("inptComErro");
                $("#dvMensagemEmailNC").fadeIn();
                qtdErros++;
            }
            if (!validatePassword($scope.senhaNC)) {
                $("#txtSenhaNC").addClass("inptComErro");
                $("#dvMensagemSenhaNC").fadeIn();
                $("#dvBreakSenha").fadeIn();
                qtdErros++;
            }
            if ($scope.senhaNC != $scope.senhaRepetidaNC) {
                $("#dvMensagemSenhaRepetidaNC").fadeIn();
                $("#dvMensagemSenhaComparacaoNC").fadeIn();
                $("#txtSenhaNC").addClass("inptComErro");
                $("#txtSenhaRepetidaNC").addClass("inptComErro");
                qtdErros++;
            }
            if (qtdErros == 0) {
                $http({
                    method: "post",
                    url: "/login/salvarNovoUsuario",
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": "0881d3992f",
                    },
                    data: JSON.stringify({
                        nome: $scope.nomeNC,
                        sobrenome: $scope.sobrenomeNC,
                        email: $scope.emailNC,
                        senha: $scope.senhaNC,
                        empresa: $scope.empresaNC,
                        objectIdNovaConta: $scope.objectIdNovaConta,
                    }),
                }).then(function (resSalvarNovoUsuario) {
                    if (!resSalvarNovoUsuario.data.valido) {
                        $("#dvMensagemEmailNC").text(resSalvarNovoUsuario.data.mensagemErro);
                        $("#txtEmailNC").addClass("inptComErro");
                        $("#dvMensagemEmailNC").fadeIn();
                    }
                    else {
                        $http({
                            method: "post",
                            url: "/novasContas/cadastrarNovasContas",
                            headers: {
                                "ramp3r-auth": "ramp3r-authorization",
                                "ramp3r-l": "0881d3992f",
                            },
                            data: JSON.stringify({
                                objectIdNovaConta: $scope.objectIdNovaConta,
                                nome: $scope.nomeNC,
                                sobrenome: $scope.sobrenomeNC,
                                email: $scope.emailNC,
                                empresa: $scope.empresaNC,
                            }),
                        }).then(function (rNovasContas) {
                            $http({
                                method: "POST",
                                url: "/login/validarEmailSenha",
                                headers: {
                                    "ramp3r-auth": "ramp3r-authorization",
                                    "ramp3r-l": "0881d3992f",
                                },
                                data: JSON.stringify({
                                    email: $scope.emailNC,
                                    senha: $scope.senhaNC,
                                    lembrarDeMim: false,
                                }),
                            }).then(function (response) {
                                MovideskService.salvarDadosMovidesk();
                                window.movideskChatWidget.show();
                                var _login = response.data.login;
                                $("#logoRamperLogin").removeClass("loginUsuarioRamper");
                                $scope.usuarioRamperLogin = false;
                                var _dados = response.data.dados;
                                $rootScope.usuarioRamper = false;
                                $rootScope.programaIndicacoes = false;
                                $rootScope.Login = _login.login;
                                $rootScope.IDCrypto = _login._idcrypto;
                                $rootScope.Tipo = _login.login.tipo;
                                $rootScope.ConfiguracoesEmail = _dados.aliasconfiguracoesemails;
                                $rootScope.TimeUsuario = _dados.aliastimeusuarios;
                                $rootScope.Plano = response.data.login.login.times[0].plano;
                                $rootScope.codigoSuperlogica = response.data.login.login
                                    .times[0].codigoEmpresaSuperlogica
                                    ? response.data.login.login.times[0].codigoEmpresaSuperlogica
                                    : "";
                                try {
                                    $rootScope.usuarioInadimplente =
                                        _login.login.inadimplente.status;
                                }
                                catch (e) {
                                    $rootScope.usuarioInadimplente = false;
                                }
                                try {
                                    $rootScope.diasUsuarioEstaInadimplente =
                                        $rootScope.Login.inadimplente.diasInadimplente;
                                }
                                catch (e) {
                                    $rootScope.diasUsuarioEstaInadimplente = "";
                                }
                                $http({
                                    method: "GET",
                                    url: "/agencias/consultarCsResponsavel",
                                    headers: {
                                        "ramp3r-auth": "ramp3r-authorization",
                                        "ramp3r-l": $rootScope.IDCrypto,
                                    },
                                    params: {
                                        objectIdTime: $rootScope.TimeUsuario
                                            ? $rootScope.TimeUsuario._id
                                            : null,
                                    },
                                }).then(function (rConsultarCsResponsavel) {
                                    $http({
                                        method: "GET",
                                        url: "/logins/consultarLoginPorId",
                                        headers: {
                                            "ramp3r-auth": "ramp3r-authorization",
                                            "ramp3r-l": $rootScope.IDCrypto,
                                        },
                                        params: {
                                            objectIdLogin: rConsultarCsResponsavel.data
                                                ? rConsultarCsResponsavel.data.objectIdLogin
                                                : null,
                                        },
                                    }).then(function (rConsultarLogin) {
                                        $rootScope.CsResponsavel = rConsultarLogin.data
                                            ? rConsultarLogin.data.email
                                            : "";
                                        window.wootricSettings = {
                                            email: $rootScope.Login.email,
                                            properties: {
                                                cs: $rootScope.CsResponsavel,
                                                superlogica: $rootScope.codigoSuperlogica,
                                                plano: $rootScope.Plano ? $rootScope.Plano : "",
                                            },
                                            account_token: "NPS-463ccbc8",
                                        };
                                        window.wootric("run");
                                    });
                                });
                                $scope.consultarCreditos($rootScope.IDCrypto, _login, _dados);
                                if ($rootScope.Login.fotoPerfil &&
                                    $rootScope.Login.fotoPerfil != null) {
                                    $http({
                                        method: "GET",
                                        url: "/integracoes/consultarIntegracoes",
                                        headers: {
                                            "ramp3r-auth": "ramp3r-authorization",
                                            "ramp3r-l": $rootScope.IDCrypto,
                                        },
                                    }).then(function (rConsultaIntegracoes) {
                                        if (rConsultaIntegracoes.data) {
                                            rConsultaIntegracoes.data.forEach(function (rIntegracoes) {
                                                if (rIntegracoes.application == "E")
                                                    _integracaoPreVendaLogin = rIntegracoes.nomeAplicao;
                                                if (rIntegracoes.application == "R")
                                                    _integracaoInboundLogin = rIntegracoes.nomeAplicao;
                                                if (rIntegracoes.application != "R" &&
                                                    rIntegracoes.application != "E")
                                                    _integracaoCRMLogin = rIntegracoes.nomeAplicao;
                                            });
                                        }
                                    });
                                    $http({
                                        method: "GET",
                                        url: "/aws/downloaderAWS",
                                        headers: {
                                            "ramp3r-auth": "ramp3r-authorization",
                                            "ramp3r-l": $rootScope.IDCrypto,
                                        },
                                        params: {
                                            tipo: "fotos",
                                            nome: $rootScope.Login.fotoPerfil,
                                        },
                                    }).then(function (response) {
                                        $rootScope.fotoPerfilUrl = response.data;
                                    });
                                }
                                else if ($scope.objectIdNovaConta &&
                                    $scope.objectIdNovaConta != "") {
                                    $http({
                                        method: "GET",
                                        url: "/integracoes/consultarIntegracoes",
                                        headers: {
                                            "ramp3r-auth": "ramp3r-authorization",
                                            "ramp3r-l": $rootScope.IDCrypto,
                                        },
                                    }).then(function (rConsultaIntegracoes) {
                                        _integracaoPreVendaLogin = "";
                                        _integracaoInboundLogin = "";
                                        _integracaoCRMLogin = "";
                                        if (rConsultaIntegracoes.data) {
                                            rConsultaIntegracoes.data.forEach(function (rIntegracoes) {
                                                if (rIntegracoes.application == "E")
                                                    _integracaoPreVendaLogin = rIntegracoes.nomeAplicao;
                                                if (rIntegracoes.application == "R")
                                                    _integracaoInboundLogin = rIntegracoes.nomeAplicao;
                                                if (rIntegracoes.application != "R" &&
                                                    rIntegracoes.application != "E")
                                                    _integracaoCRMLogin = rIntegracoes.nomeAplicao;
                                            });
                                        }
                                    });
                                }
                                if ($rootScope.showBrowserLogins.length == 0) {
                                    var _cookieAgencia = "loginAgencia_ramperv3_2018";
                                    var _id = lerCookie(_cookieAgencia);
                                    var usuario = null;
                                    if (_id != null && _id != "") {
                                        usuario = {
                                            iDCrypto: _id.split("EMA_")[1].split("_")[0],
                                            objectIdLogin: _id.split("EMA_")[1].split("_")[1],
                                            email: _id.split("EMA_")[1].split("_")[2],
                                            fotoPerfil: _id.split("EMA_")[1].split("_")[3],
                                            nome: _id.split("EMA_")[1].split("_")[4],
                                            empresa: _id.split("EMA_")[1].split("_")[5],
                                        };
                                        if (usuario.fotoPerfil && usuario.fotoPerfil != "") {
                                            usuario.fotoPerfil =
                                                "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") +
                                                    usuario.objectIdLogin.toString() +
                                                    "/" +
                                                    usuario.fotoPerfil;
                                        }
                                        else {
                                            usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                                        }
                                        if ($rootScope.Login.email !== usuario.email)
                                            $rootScope.showBrowserLogins.push(usuario);
                                    }
                                    AgenciaService.consultarAgenciasLogins(usuario);
                                }
                                _dados.aliasconfiguracoesemails = null;
                                _dados.aliastimeusuarios = null;
                                $rootScope.DadosCadastrais = _dados;
                                $rootScope.nomeUsuarioLogado =
                                    $rootScope.DadosCadastrais.nome +
                                        " " +
                                        $rootScope.DadosCadastrais.sobrenome;
                                $rootScope.empresaLogado = $rootScope.DadosCadastrais.empresa;
                                $rootScope.emailUsuario = _login.login.email.toString();
                                VoipService.getAccountAndExtension();
                                $rootScope.empresaUsuario = _dados.empresa;
                                $rootScope.administrativoOuVisao = $rootScope.Tipo != "U";
                                $location.path("/inicio");
                                var _cookie = "login_ramperv3_1910_1989_2017";
                                apagarCookie(_cookie);
                                gravarCookie(_cookie, "EMA_" +
                                    _login._idcrypto +
                                    "_" +
                                    _login.login.email.toString() +
                                    "_" +
                                    _login.login.tipo.toString(), 1);
                            });
                        });
                    }
                });
            }
        };
        $scope.validarEmail = function (email) {
            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(String(email).toLowerCase());
        };
        $scope.consultarCreditos = function (pIDCrypto, pLogin, pDados) {
            $rootScope.creditosInicio = [];
            $http({
                method: "GET",
                url: "/credito/consultarCreditos",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": pIDCrypto,
                },
            }).then(function (rConsultarCreditos) {
                $rootScope.creditosInicio = rConsultarCreditos.data[0];
                if (_urlAcesso != "localh") {
                    mixpanel.identify(pIDCrypto);
                    mixpanel.people.set({
                        $email: pLogin.login.email,
                        $name: pDados.nome,
                    });
                    mixpanel.track("Nova conta | Criou nova conta", {
                        Local_IP: $rootScope.ip,
                    });
                }
            });
        };
        $scope.atualizarInfos = function (pDados) {
            if (pDados == "nome") {
                $("#txtNomeNC").removeClass("inptComErro");
                $("#dvMensagemNomeNC").fadeOut();
            }
            if (pDados == "sobrenome") {
                $("#txtSobrenomeNC").removeClass("inptComErro");
                $("#dvMensagemSobrenomeNC").fadeOut();
            }
            if (pDados == "empresa") {
                $("#txtEmpresaNC").removeClass("inptComErro");
                $("#dvMensagemEmpresaNC").fadeOut();
            }
            if (pDados == "email") {
                $("#txtEmailNC").removeClass("inptComErro");
                $("#dvMensagemEmailNC").fadeOut();
            }
            if (pDados == "senha") {
                $("#txtSenhaNC").removeClass("inptComErro");
                $("#dvMensagemSenhaNC").fadeOut();
                $("#dvBreakSenha").fadeOut();
                if ($scope.senhaNC == $scope.senhaRepetidaNC) {
                    $("#dvMensagemSenhaRepetidaNC").fadeOut();
                    $("#dvMensagemSenhaComparacaoNC").fadeOut();
                    $("#txtSenhaNC").removeClass("inptComErro");
                    $("#txtSenhaRepetidaNC").removeClass("inptComErro");
                }
            }
            if (pDados == "senhaRepetida") {
                if ($scope.senhaNC == $scope.senhaRepetidaNC) {
                    $("#dvMensagemSenhaRepetidaNC").fadeOut();
                    $("#dvMensagemSenhaComparacaoNC").fadeOut();
                    $("#txtSenhaNC").removeClass("inptComErro");
                    $("#txtSenhaRepetidaNC").removeClass("inptComErro");
                }
            }
        };
        $scope.removerDivsErro = function () {
            $("#txtNomeNC").removeClass("inptComErro");
            $("#txtSobrenomeNC").removeClass("inptComErro");
            $("#txtEmpresaNC").removeClass("inptComErro");
            $("#txtEmailNC").removeClass("inptComErro");
            $("#txtSenhaNC").removeClass("inptComErro");
            $("#dvMensagemNomeNC").hide();
            $("#dvMensagemSobrenomeNC").hide();
            $("#dvMensagemEmpresaNC").hide();
            $("#dvMensagemEmailNC").hide();
            $("#dvMensagemSenhaNC").hide();
            $("#dvBreakSenha").hide();
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: "GET",
                url: "/login/consultarContatosMixpanel",
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                },
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
    },
]);
