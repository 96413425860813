angular.module("ramperv3").controller("MonsterDuplaAutenticacaoController", [
    "$rootScope",
    "$scope",
    "$location",
    "MonsterToasterService",
    "MonsterDuplaAutenticacaoService",
    "MonsterLoginService",
    function ($rootScope, $scope, $location, MonsterToasterService, MonsterDuplaAutenticacaoService, MonsterLoginService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $scope
         */
        $scope.numeroDeTentativasIncorretas = 0;
        $scope.mfa = { token: "" };
        /**
         * Deve consumir o serviço para realizar a validação da dupla autenticação
         */
        $scope.validarTokenMfa = function () {
            var token = $scope.mfa.token.replace(/[^0-9]/g, "");
            $rootScope.monsterShowLoading = true;
            MonsterDuplaAutenticacaoService.validarTokenMfa(token).then(function () {
                MonsterLoginService.loginRamper($rootScope.responseLogin);
                $rootScope.responseLogin = null;
                mixpanel.track("DUPLA AUTENTICAÇÃO | Realizou o login com dupla autenticação", { Local_IP: $rootScope.ip });
            }, function () {
                $scope.numeroDeTentativasIncorretas += 1;
                $rootScope.monsterShowLoading = false;
                if ($scope.numeroDeTentativasIncorretas >= 3) {
                    MonsterToasterService.showErrorToaster("Código de autenticação incorreto", "Você excedeu o número de tentativas. Faça o login novamente.", 6000, 450);
                    $rootScope.responseLogin = null;
                    $location.path("/v2/login");
                    mixpanel.track("DUPLA AUTENTICAÇÃO | Realizou 3 tentativas com o código de autenticação invalido", { Local_IP: $rootScope.ip });
                }
                else {
                    MonsterToasterService.showErrorToaster("", "Código de autenticação incorreto. Tente novamente.", 6000, 450);
                }
            });
        };
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            $scope.numeroDeTentativasIncorretas = 0;
            $rootScope.responseLogin = null;
        });
    },
]);
