angular.module("ramperv3").controller("MonsterRedefinirSenhaController", [
    "$rootScope",
    "$scope",
    "MonsterToasterService",
    "MonsterRedefinirSenhaService",
    function ($rootScope, $scope, MonsterToasterService, MonsterRedefinirSenhaService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.mostrarMenu = true;
        $rootScope.loaderAtivo = false;
        /**
         * Estado inicial da página $scope
         */
        $scope.loginPorSso = $rootScope.Login.sso ? false : true;
        $scope.abrirMenuSeguranca = true;
        $scope.user = { senha: "", novaSenha: "", senhaConfirmacao: "" };
        /**
         * Deve converter uma string para pattern
         */
        $scope.stringToPattern = function (input) { return input ? input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ''; };
        /**
         * Deve atualizar a senha do usuário
         */
        $scope.onRedefinirSenha = function () {
            var user = $scope.user;
            $rootScope.monsterShowLoading = true;
            MonsterRedefinirSenhaService.redefinirSenha(user).then(function () {
                MonsterToasterService.showSuccessToaster("", "Senha redefinida com sucesso!", 6000, 350);
                $rootScope.monsterShowLoading = false;
                $scope.onLimpar();
                mixpanel.track("Redefinir Senha | Realizou a alteração de senha", {
                    Local_IP: $rootScope.ip,
                });
            }, function (error) {
                var message = error.status === 401
                    ? "Senha atual incorreta. Tente novamente."
                    : "Houve um erro ao redefinir senha. Tente novamente.";
                MonsterToasterService.showErrorToaster("", message, 6000, 350);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve restar o formulário;
         */
        $scope.onLimpar = function () {
            $scope.user = { senha: "", novaSenha: "", senhaConfirmacao: "" };
            var form = $scope.formRedefinirSenha;
            angular.forEach(form.$$controls, function (control) {
                var fieldElement = angular.element(control.$$element);
                fieldElement.attr("dirty", "false");
            });
        };
        /**
         * Deve abrir e fechar o menu de segurança
         */
        $scope.toggleMenuSeguranca = function () {
            $scope.abrirMenuSeguranca = !$scope.abrirMenuSeguranca;
        };
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            MonsterToasterService.removeToaster();
        });
    },
]);
