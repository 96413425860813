angular.module("ramperv3").service("MonsterPerfilService", [
    "$http",
    "$rootScope",
    function ($http, $rootScope) {
        /**
         * Deve consumir a api para obter a imagem de perfil do usuário
         */
        function obterFotoPerfil() {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            if (!$rootScope.Login.fotoPerfil) {
                return { data: null };
            }
            var params = {
                tipo: "fotos",
                nome: $rootScope.Login.fotoPerfil,
            };
            return $http({
                method: "GET",
                url: "/aws/downloaderAWS",
                headers: headers,
                params: params,
            });
        }
        /**
         * Deve consumir a api para realizar a alteração dos dados do usuário
         * @param {nome, sobrenome, empresa, email} data dados do usuário
         */
        function updatePerfil(data) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            return $http({
                method: "POST",
                url: "/time/usuario/editar",
                headers: headers,
                data: data,
            });
        }
        /**
         * Deve consumir a api para salvar a nova foto de perfil do usuário
         * @param nome um nome randômico para a imagem
         * @param arquivo nova imagem do usuário
         * @param formato formato da nova imagem
         */
        function salvarArquivo(nome, arquivo, formato) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
                "Content-Type": undefined,
            };
            var formData = new FormData();
            formData.append("inptFoto", arquivo);
            return $http({
                method: "POST",
                url: "/aws/uploaderFotoAWS/" + nome + "/" + formato,
                headers: headers,
                data: formData,
                transformRequest: angular.identity,
            });
        }
        /**
         * Deve consumir a api para salvar o nome da foto de perfil do usuário
         * @param nome um nome randômico para a imagem
         * @param formato formato da nova imagem
         */
        function salvarNomeArquivo(nome, formato) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
            };
            $http({
                method: "GET",
                url: "/login/updateFotoPerfil",
                headers: headers,
                params: {
                    objectIdLogin: $rootScope.IDCrypto,
                    fotoPerfil: nome,
                    extensao: formato,
                },
            });
        }
        return { obterFotoPerfil: obterFotoPerfil, updatePerfil: updatePerfil, salvarArquivo: salvarArquivo, salvarNomeArquivo: salvarNomeArquivo };
    },
]);
