angular.module("ramperv3").service("MonsterDuplaAutenticacaoService", [
    "$http",
    function ($http) {
        /**
         * Deve consumir a api para validar o token
         * @param token credenciais para validar dupla autenticação
         */
        function validarTokenMfa(token) {
            return $http({
                method: "POST",
                url: "/login/mfa/validate",
                data: { token: token },
            });
        }
        return { validarTokenMfa: validarTokenMfa };
    },
]);
