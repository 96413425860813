angular.module("ramperv3").directive("ngIfPermission", [
    "ngIfDirective",
    "PermissionamentoService",
    function (ngIfDirective, PermissionamentoService) {
        var ngIf = ngIfDirective[0];
        return {
            transclude: ngIf.transclude,
            priority: ngIf.priority + 1,
            terminal: ngIf.terminal,
            restrict: ngIf.restrict,
            link: function ($scope, _, $attr) {
                var permission = $scope.$eval($attr.ngIfPermission);
                var initialNgIf = $attr.ngIf ? $scope.$eval($attr.ngIf) : true;
                var hasAllowAccessToScreenAction = PermissionamentoService.allowAccessToScreenAction(permission.screen, permission.action);
                $attr.ngIf = function () {
                    return initialNgIf && hasAllowAccessToScreenAction;
                };
                ngIf.link.apply(ngIf, arguments);
            },
        };
    },
]);
